<template>
    <v-form @submit.prevent="submitForm" ref="form">
        <v-btn class="mt-5" color="#374840" @click="this.$router.go(-1)">Retour</v-btn>
        <v-btn class="mt-5" type="submit">{{ submit }}</v-btn>

        <v-card class="mt-5">
            <v-tabs
                v-model="tab"
                color="#374840"
                show-arrows
            >
                <v-tab value="ETC">Général</v-tab>
                <v-tab value="CON">Contact</v-tab>
                <v-tab value="DIV">Divers</v-tab>
                <v-tab value="ADR">Adresse</v-tab>
            </v-tabs>

            <v-card-text>
                <v-window v-model="tab">
                    <v-window-item value="ETC">
                        <v-text-field
                            variant="solo"
                            label="Nom"
                            v-model="name"
                            :rules="config.rules.required"
                        ></v-text-field>

                        <v-text-field
                            variant="solo"
                            label="Date de création"
                            type="date"
                            v-model="dateOfCreation"
                            :rules="config.rules.required"
                        ></v-text-field>
                    </v-window-item>

                    <v-window-item value="CON">
                        <v-text-field
                            variant="solo"
                            label="Mail"
                            v-model="eMail"
                            :rules="config.rules.required"
                        ></v-text-field>

                        <v-text-field
                            variant="solo"
                            label="Numéro de téléphone"
                            v-model="phoneNumber"
                            :rules="[
                                ...config.rules.required,
                                ...config.rules.isAPhoneNumber,
                            ]"
                        ></v-text-field>

                        <v-textarea
                            variant="solo"
                            label="Disponibilité"
                            v-model="disponibility"
                        ></v-textarea>
                    </v-window-item>

                    <v-window-item value="DIV">
                        <v-text-field
                            variant="solo"
                            label="Activité"
                            v-model="activity"
                        ></v-text-field>

                        <v-file-input
                            :rules="config.rules.fileSize"
                            variant="solo"
                            label="Photo de l'atelier"
                            prepend-icon="mdi-account-box-outline"
                            ref="fileInput"
                            accept=".png, .jpeg, .jpg"
                            @change="handleFileUpload"
                        ></v-file-input>

                        <v-img
                            v-if="workshopPicture"
                            :src="workshopPicture"
                            :width="100"
                            aspect-ratio="1/1"
                            alt="Photo de l'atelier"
                        ></v-img>
                        <v-btn
                            v-if="workshopPicture"
                            @click="workshopPicture = null"
                        >Supprimer la photo
                        </v-btn
                        >
                    </v-window-item>

                    <v-window-item value="ADR">
                        <v-text-field
                            type="number"
                            variant="solo"
                            label="Numéro de rue"
                            v-model="address.streetNumber"
                            :rules="[
                                ...config.rules.required,
                                ...config.rules.isANumber,
                            ]"
                        ></v-text-field>

                        <v-text-field
                            variant="solo"
                            label="Nom de la rue"
                            v-model="address.streetName"
                            :rules="config.rules.required"
                        ></v-text-field>

                        <v-text-field
                            variant="solo"
                            label="Code postal"
                            v-model="address.postalCode"
                            :rules="[
                                ...config.rules.required,
                                ...config.rules.isANumber,
                            ]"
                        ></v-text-field>

                        <v-text-field
                            variant="solo"
                            label="Ville"
                            v-model="address.city"
                            :rules="config.rules.required"
                        ></v-text-field>

                        <v-text-field
                            variant="solo"
                            label="Pays"
                            v-model="address.country"
                            :rules="config.rules.required"
                        ></v-text-field>

                        <v-textarea
                            variant="solo"
                            label="Accessibilité"
                            v-model="accessibility"
                            :rules="config.rules.required"
                        ></v-textarea>
                    </v-window-item>
                </v-window>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
import {config} from "@/config";

export default {
    name: "WorkshopForm",
    inject: ["getUser", "createAlert"],
    props: {
        submit: String,
        defaultName: String,
        defaultDateOfCreation: String,
        defaultWorkshopPicture: String,
        defaultEMail: String,
        defaultPhoneNumber: String,
        defaultActivity: String,
        defaultAccessibility: String,
        defaultDisponibility: String,
        defaultAddressStreetNumber: String,
        defaultAddressStreetName: String,
        defaultAddressPostalCode: String,
        defaultAddressCity: String,
        defaultAddressCountry: String,
        defaultAddressId: String,
    },
    data() {
        return {
            config,
            tab: null,
            name: "",
            dateOfCreation: "",
            workshopPicture: null,
            eMail: "",
            phoneNumber: "",
            activity: "",
            accessibility: "",
            disponibility: "",
            address: {
                streetNumber: "",
                streetName: "",
                postalCode: "",
                city: "",
                country: "",
            },
        };
    },
    watch: {
        defaultName: function (newVal) {
            this.name = newVal ? newVal : "";
        },
        defaultDateOfCreation: function (newVal) {
            this.dateOfCreation = newVal ? this.formatDate(newVal) : "";
        },
        defaultWorkshopPicture: function (newVal) {
            this.workshopPicture = newVal ? newVal : "";
        },
        defaultEMail: function (newVal) {
            this.eMail = newVal ? newVal : "";
        },
        defaultPhoneNumber: function (newVal) {
            this.phoneNumber = newVal ? newVal : "";
        },
        defaultActivity: function (newVal) {
            this.activity = newVal ? newVal : "";
        },
        defaultAccessibility: function (newVal) {
            this.accessibility = newVal ? newVal : "";
        },
        defaultDisponibility: function (newVal) {
            this.disponibility = newVal ? newVal : "";
        },
        defaultAddressStreetNumber: function (newVal) {
            this.address.streetNumber = newVal ? newVal : "";
        },
        defaultAddressStreetName: function (newVal) {
            this.address.streetName = newVal ? newVal : "";
        },
        defaultAddressPostalCode: function (newVal) {
            this.address.postalCode = newVal ? newVal : "";
        },
        defaultAddressCity: function (newVal) {
            this.address.city = newVal ? newVal : "";
        },
        defaultAddressCountry: function (newVal) {
            this.address.country = newVal ? newVal : "";
        },
    },
    methods: {
        async handleFileUpload() {
            const fileInput = this.$refs.fileInput;
            const file = fileInput.files[0];

            if (file) {
                const base64Data = await this.readFileAsBase64(file);
                this.workshopPicture = `data:${file.type};base64,${base64Data}`;
            }
        },
        async readFileAsBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result.split(",")[1]);
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        },
        formatDate(dateString) {
            const date = new Date(dateString);

            return date.toISOString().split("T")[0];
        },
        async submitForm() {
            if ((await this.$refs.form.validate()).valid) {
                this.$emit("formSubmitted", {
                    name: this.name,
                    dateOfCreation: this.dateOfCreation,
                    workshopPicture: this.workshopPicture,
                    eMail: this.eMail,
                    phoneNumber: this.phoneNumber,
                    activity: this.activity,
                    accessibility: this.accessibility,
                    disponibility: this.disponibility,
                    address: {
                        streetNumber: this.address.streetNumber,
                        streetName: this.address.streetName,
                        postalCode: this.address.postalCode,
                        city: this.address.city,
                        country: this.address.country,
                    },
                });
            }
        },
    },
};
</script>
