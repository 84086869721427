<template>
    <v-form @submit.prevent="submitForm" ref="form">
        <v-btn class="mt-5" color="#374840" @click="this.$router.go(-1)">Retour</v-btn>
        <v-btn class="mt-5" type="submit">{{ submit }}</v-btn>

        <v-card class="mt-5">
            <v-tabs
                v-model="tab"
                color="#374840"
                show-arrows
            >
                <v-tab value="MAIN">Informations principales</v-tab>
                <v-tab v-if="selectedFormations.length > 0" value="FORM">
                    Formations
                </v-tab>
            </v-tabs>

            <v-card-text>
                <v-window v-model="tab">
                    <v-window-item value="MAIN">
                        <v-text-field
                            variant="solo"
                            label="Référentiel"
                            v-model="ref"
                            :rules="config.rules.required"
                        ></v-text-field>

                        <v-text-field
                            variant="solo"
                            label="Nom"
                            v-model="name"
                            :rules="config.rules.required"
                        ></v-text-field>

                        <v-select
                            variant="solo"
                            v-model="selectedFormations"
                            :items="formations"
                            item-title="name"
                            item-value="id"
                            label="Choix des formations"
                            multiple
                            chips
                            :rules="config.rules.required"
                        ></v-select>
                    </v-window-item>

                    <v-window-item
                        v-if="selectedFormations.length > 0"
                        value="FORM"
                    >
                        <v-card class="mt-5">
                            <v-tabs
                                v-model="tabFormation"
                                color="#374840"
                                show-arrows
                            >
                                <v-tab
                                    v-for="(
                                        formation, key
                                    ) in selectedFormations"
                                    :key="key"
                                    :value="key"
                                >{{ getFormationName(formation) }}
                                </v-tab>
                            </v-tabs>

                            <v-card-text>
                                <v-window v-model="tabFormation">
                                    <v-window-item
                                        v-for="(
                                            formation, key
                                        ) in finalFormations"
                                        :key="key"
                                        :value="key"
                                    >
                                        <h3>{{ getFormationName(formation.formationId) }}</h3>

                                        <v-text-field
                                            variant="solo"
                                            label="Description"
                                            v-model="formation.description"
                                            :rules="config.rules.required"
                                        ></v-text-field>
                                        <v-text-field
                                            variant="solo"
                                            label="Durée"
                                            v-model="formation.duration"
                                            :rules="[
                                                ...config.rules.required,
                                                ...config.rules.isANumber,
                                            ]"
                                        ></v-text-field>
                                    </v-window-item>
                                </v-window>
                            </v-card-text>
                        </v-card>
                    </v-window-item>
                </v-window>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
import {config} from "@/config";

export default {
    name: "FormationForm",
    inject: ["getUser", "createAlert"],
    props: {
        submit: String,
        defaultRef: String,
        defaultName: String,
        defaultSelectedFormations: Array,
        defaultFinalFormations: Array,
    },
    data() {
        return {
            config,
            tab: null,
            tabFormation: null,
            ref: "",
            name: "",
            formations: [],
            finalFormations: [],
            selectedFormations: [],
        };
    },
    watch: {
        defaultRef: function (newVal) {
            this.ref = newVal ? newVal : "";
        },
        defaultName: function (newVal) {
            this.name = newVal ? newVal : "";
        },
        defaultSelectedFormations: function (newVal) {
            this.selectedFormations = Array.isArray(newVal) ? newVal : [];
        },
        defaultFinalFormations: function (newVal) {
            this.finalFormations = Array.isArray(newVal) ? newVal : [];
        },
        selectedFormations: function (
            newSelectedFormations,
            oldSelectedFormations
        ) {
            const removedFormations = oldSelectedFormations.filter(
                (id) => !newSelectedFormations.includes(id)
            );

            const addedFormations = newSelectedFormations.filter(
                (id) => !oldSelectedFormations.includes(id)
            );

            addedFormations.forEach((id) => {
                if (
                    !this.finalFormations.some(
                        (item) => item.formationId === id
                    )
                ) {
                    this.finalFormations.push({
                        formationId: id,
                        duration: null,
                        description: null,
                    });
                }
            });

            removedFormations.forEach((id) => {
                if (
                    this.finalFormations.some((item) => item.formationId === id)
                ) {
                    this.finalFormations = this.finalFormations.filter(
                        (item) => item.formationId !== id
                    );
                }
            });
        },
    },
    methods: {
        getFormationName(formationId) {
            const formation = this.formations.find((f) => f.id === formationId);
            return formation
                ? formation.name
                : `Formation inconnue (${formationId})`;
        },
        async submitForm() {
            if ((await this.$refs.form.validate()).valid) {
                let error = false;
                this.finalFormations.forEach((e) => {
                    if (typeof e.duration !== "number" && e.duration != null) {
                        e.duration = e.duration.replace(",", ".");
                        e.duration = parseFloat(e.duration);
                    }

                    if (isNaN(e.duration)) {
                        error = true;
                    }

                });

                if (error) {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Les durées entrées ne sont pas conformes.",
                        },
                    ]);

                    return;
                }

                this.$emit("formSubmitted", {
                    ref: this.ref,
                    name: this.name,
                    formations: this.finalFormations,
                });
            }
        },
    },
    beforeMount() {
        fetch(`${config.url}/team/formation`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.formations = data;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
