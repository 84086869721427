<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <div class="d-flex justify-space-between mt-5 mb-5">
                <v-btn
                    color="#374840"
                    @click="this.$router.go(-1)"
                >Retour
                </v-btn>

                <div>
                    <v-btn class="mr-2" color="#374840" @click="clear">Effacer</v-btn>
                    <v-btn color="#374840" @click="save">Valider</v-btn>
                </div>
            </div>

            <h2>Signature</h2>

            <v-row class="mt-5">
                <v-col class="d-flex justify-center">
                    <canvas ref="canvas" width="1000" height="500" style="border: 2px solid black;"></canvas>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import {inject, onMounted, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import SideBar from "@/components/SideBar.vue";
import {config} from "@/config";

export default {
    name: "TeamEventSignature",
    components: {SideBar},
    setup: function () {
        const route = useRoute();
        const canvas = ref(null);
        const getUser = inject("getUser");
        const createAlert = inject("createAlert");
        const router = useRouter();

        let ctx;
        let isDrawing = false;
        let x = 0;
        let y = 0;
        let hasDrawn = false; // Variable pour vérifier si un dessin a été effectué
        let eventId = "";
        let studentId = "";

        const startDrawing = (e) => {
            e.preventDefault();
            isDrawing = true;
            [x, y] = [e.offsetX, e.offsetY];
            hasDrawn = true; // Indique qu'un dessin a commencé
        };

        const draw = (e) => {
            e.preventDefault();
            if (!isDrawing) return;

            if (e.touches && e.touches.length > 0) {
                const touch = e.touches[0];
                x = touch.pageX - canvas.value.offsetLeft;
                y = touch.pageY - canvas.value.offsetTop;
            } else {
                x = e.offsetX;
                y = e.offsetY;
            }

            ctx.lineWidth = 2;
            ctx.lineCap = 'round';
            ctx.strokeStyle = 'black';
            ctx.lineTo(x, y);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(x, y);
        };

        const endDrawing = (e) => {
            e.preventDefault();
            isDrawing = false;
            ctx.beginPath();
        };

        const clear = () => {
            ctx.clearRect(0, 0, canvas.value.width, canvas.value.height);
            hasDrawn = false; // Réinitialisez pour indiquer que le canvas est de nouveau vierge
        };

        const save = () => {
            const imageData = ctx.getImageData(0, 0, canvas.value.width, canvas.value.height);
            const pixels = imageData.data;

            let minX = canvas.value.width;
            let minY = canvas.value.height;
            let maxX = 0;
            let maxY = 0;

            for (let y = 0; y < canvas.value.height; y++) {
                for (let x = 0; x < canvas.value.width; x++) {
                    const index = (y * canvas.value.width + x) * 4;
                    const alpha = pixels[index + 3];
                    if (alpha > 0) {
                        if (x < minX) minX = x;
                        if (x > maxX) maxX = x;
                        if (y < minY) minY = y;
                        if (y > maxY) maxY = y;
                    }
                }
            }

            const newCanvas = document.createElement('canvas');
            newCanvas.width = maxX - minX + 1;
            newCanvas.height = maxY - minY + 1;
            const newCtx = newCanvas.getContext('2d');

            newCtx.drawImage(canvas.value, minX, minY, maxX - minX + 1, maxY - minY + 1, 0, 0, maxX - minX + 1, maxY - minY + 1);

            if (!hasDrawn) {
                createAlert([{
                    type: "error",
                    title: "Une signature est obligatoire.",
                }]);
                return;
            }

            const data = newCanvas.toDataURL();

            fetch(`${config.url}/team/signature/event/${eventId}/student/${studentId}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + getUser().token,
                },
                method: "POST",
                body: JSON.stringify({data: data}),
            })
                .then(async (res) => {
                    if (res.status === 201) {
                        router.push(`/team/calendar/${eventId}/edit`);

                        createAlert([
                            {
                                type: "success",
                                title: "Signature ajoutée",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        };

        onMounted(() => {
            ctx = canvas.value.getContext('2d');

            canvas.value.addEventListener('touchstart', startDrawing);
            canvas.value.addEventListener('touchmove', draw);
            canvas.value.addEventListener('touchend', endDrawing);
            canvas.value.addEventListener('touchcancel', endDrawing);

            canvas.value.addEventListener('mousedown', startDrawing);
            canvas.value.addEventListener('mousemove', draw);
            canvas.value.addEventListener('mouseup', endDrawing);
            canvas.value.addEventListener('mouseout', endDrawing);

            // Récupérer les paramètres de l'URL
            eventId = route.params.eventId;
            studentId = route.params.studentId;
        });

        return {canvas, save, clear};
    },
};
</script>
