<template>
    <v-form @submit.prevent="submitForm" ref="form">

        <v-btn class="mt-5" color="#374840" @click="this.$router.go(-1)">Retour</v-btn>
        <v-btn class="mt-5" type="submit">{{ submit }}</v-btn>

        <v-card class="mt-5">
            <v-tabs
                v-model="tab"
                color="#374840"
                show-arrows
            >
                <v-tab value="MAIN">Informations principales</v-tab>
            </v-tabs>

            <v-card-text>
                <v-window v-model="tab">
                    <v-window-item value="MAIN">

                        <v-textarea
                            variant="solo"
                            label="Message"
                            v-model="message"
                            :rules="config.rules.required"
                        ></v-textarea>

                        <v-select
                            variant="solo"
                            v-model="teamUser"
                            :items="teamUsers"
                            item-title="name"
                            item-value="id"
                            label="Membre de l'équipe"
                            :rules="config.rules.required"
                        ></v-select>
                    </v-window-item>
                </v-window>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
import {config} from "@/config";

export default {
    name: "NotificationForm",
    inject: ["getUser", "createAlert"],
    props: {
        submit: String,
    },
    data() {
        return {
            config,
            message: "",
            teamUser: null,
            teamUsers: [],
            tab: null,
        };
    },
    methods: {
        async submitForm() {
            if ((await this.$refs.form.validate()).valid) {
                const teamUserId = this.teamUser && this.teamUser.id !== undefined ? this.teamUser.id : this.teamUser;

                this.$emit("formSubmitted", {
                    message: this.message,
                    teamUser: teamUserId
                });
            }
        },
    },
    beforeMount() {
        fetch(`${config.url}/team/event/informations`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.teamUsers = data.teams;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
