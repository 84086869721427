<template>
    <v-form v-if="isCompleted" @submit.prevent="submitForm" ref="form">
        <v-btn v-if="userView === 'student' && !readOnly" class="mt-2" type="submit">
            {{ submit }}
        </v-btn>

        <v-card class="mt-5">
            <v-tabs
                v-model="tab"
                color="#374840"
                show-arrows
            >
                <v-tab value="ORG">L'organisation</v-tab>
                <v-tab value="FORMATION">La formation</v-tab>
                <v-tab value="FORMATEUR">Le formateur</v-tab>
                <v-tab value="GLOB">Appréciation générale</v-tab>
            </v-tabs>

            <v-card-text>
                <v-window v-model="tab">
                    <v-window-item value="ORG">
                        <v-row>
                            <v-col cols="12" md="12" lg="6">
                                <label>Mon accès aux informations avant le stage (programmes, objectifs, ...)</label>
                                <v-slider
                                    class="pl-1"
                                    v-model="quiz.informationAccess"
                                    :min="0"
                                    :max="5"
                                    :step="1"
                                    thumb-label
                                    :ticks="[0, 1, 2, 3, 4, 5]"
                                    show-ticks="always"
                                    tick-size="4"
                                    :readonly="readOnly"
                                    :rules="[...config.rules.required, ...config.rules.numberGreaterThanOne]"
                                ></v-slider>
                            </v-col>
                            <v-col cols="12" md="12" lg="6">
                                <label>La qualité de l'accueil</label>
                                <v-slider
                                    class="pl-1"
                                    v-model="quiz.workQuality"
                                    :min="0"
                                    :max="5"
                                    :step="1"
                                    thumb-label
                                    :ticks="[0, 1, 2, 3, 4, 5]"
                                    show-ticks="always"
                                    tick-size="4"
                                    :readonly="readOnly"
                                    :rules="[...config.rules.required, ...config.rules.numberGreaterThanOne]"
                                ></v-slider>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="12" lg="12">
                                <v-textarea
                                    variant="solo"
                                    label="Vos commentaires"
                                    counter
                                    rows="2"
                                    clearable
                                    v-model="quiz.opinionOrganisation"
                                    :readonly="readOnly"
                                    :rules="config.rules.limitSizeTo256"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-window-item>

                    <v-window-item value="FORMATION">
                        <v-row>
                            <v-select
                                variant="solo"
                                v-model="quiz.hourQuantity"
                                label="Le volume horraire de la formation est "
                                :readonly="readOnly"
                                :items="['Trop court', 'Adapté', 'Trop long']"
                                :rules="config.rules.required"
                            ></v-select>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12" lg="6">
                                <label>Les supports d'apprentissage sont clairs</label>
                                <v-slider
                                    class="pl-1"
                                    v-model="quiz.learningSupport"
                                    :min="0"
                                    :max="5"
                                    :step="1"
                                    thumb-label
                                    :ticks="[0, 1, 2, 3, 4, 5]"
                                    show-ticks="always"
                                    tick-size="4"
                                    :readonly="readOnly"
                                    :rules="[...config.rules.required, ...config.rules.numberGreaterThanOne]"
                                ></v-slider>
                            </v-col>
                            <v-col cols="12" md="12" lg="6">
                                <label>La formation a répondu à mes objectifs</label>
                                <v-slider
                                    class="pl-1"
                                    v-model="quiz.trainingTarget"
                                    :min="0"
                                    :max="5"
                                    :step="1"
                                    thumb-label
                                    :ticks="[0, 1, 2, 3, 4, 5]"
                                    show-ticks="always"
                                    tick-size="4"
                                    :readonly="readOnly"
                                    :rules="[...config.rules.required, ...config.rules.numberGreaterThanOne]"
                                ></v-slider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12" lg="6">
                                <label>Je vais mettre en application les éléments appris</label>
                                <v-slider
                                    class="pl-1"
                                    v-model="quiz.practiceLearning"
                                    :min="0"
                                    :max="5"
                                    :step="1"
                                    thumb-label
                                    :ticks="[0, 1, 2, 3, 4, 5]"
                                    show-ticks="always"
                                    tick-size="4"
                                    :readonly="readOnly"
                                    :rules="[...config.rules.required, ...config.rules.numberGreaterThanOne]"
                                ></v-slider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12" lg="12">
                                <v-textarea
                                    variant="solo"
                                    label="Vos commentaires"
                                    counter
                                    rows="2"
                                    clearable
                                    v-model="quiz.opinionTraining"
                                    :readonly="readOnly"
                                    :rules="config.rules.limitSizeTo256"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-window-item>

                    <v-window-item value="FORMATEUR">
                        <v-row>
                            <v-col cols="12" md="12" lg="6">
                                <label>Sa capacité d'écoute</label>
                                <v-slider
                                    class="pl-1"
                                    v-model="quiz.learningCapacity"
                                    :min="0"
                                    :max="5"
                                    :step="1"
                                    thumb-label
                                    :ticks="[0, 1, 2, 3, 4, 5]"
                                    show-ticks="always"
                                    tick-size="4"
                                    :readonly="readOnly"
                                    :rules="[...config.rules.required, ...config.rules.numberGreaterThanOne]"
                                ></v-slider>
                            </v-col>
                            <v-col cols="12" md="12" lg="6">
                                <label>Sa connaissance du sujet</label>
                                <v-slider
                                    class="pl-1"
                                    v-model="quiz.subjectKnowledge"
                                    :min="0"
                                    :max="5"
                                    :step="1"
                                    thumb-label
                                    :ticks="[0, 1, 2, 3, 4, 5]"
                                    show-ticks="always"
                                    tick-size="4"
                                    :readonly="readOnly"
                                    :rules="[...config.rules.required, ...config.rules.numberGreaterThanOne]"
                                ></v-slider>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="12" lg="6">
                                <label>Le respect du programme</label>
                                <v-slider
                                    class="pl-1"
                                    v-model="quiz.programRespect"
                                    :min="0"
                                    :max="5"
                                    :step="1"
                                    thumb-label
                                    :ticks="[0, 1, 2, 3, 4, 5]"
                                    show-ticks="always"
                                    tick-size="4"
                                    :readonly="readOnly"
                                    :rules="[...config.rules.required, ...config.rules.numberGreaterThanOne]"
                                ></v-slider>
                            </v-col>
                            <v-col cols="12" md="12" lg="6">
                                <label>Son approche pédagogique</label>
                                <v-slider
                                    class="pl-1"
                                    v-model="quiz.pedagogicalApproach"
                                    :min="0"
                                    :max="5"
                                    :step="1"
                                    thumb-label
                                    :ticks="[0, 1, 2, 3, 4, 5]"
                                    show-ticks="always"
                                    tick-size="4"
                                    :readonly="readOnly"
                                    :rules="[...config.rules.required, ...config.rules.numberGreaterThanOne]"
                                ></v-slider>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="12" lg="12">
                                <v-textarea
                                    variant="solo"
                                    label="Vos commentaires"
                                    counter
                                    rows="2"
                                    clearable
                                    v-model="quiz.opinionLearner"
                                    :readonly="readOnly"
                                    :rules="config.rules.limitSizeTo256"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-window-item>

                    <v-window-item value="GLOB">
                        <v-row>
                            <v-col cols="12" md="12" lg="6">
                                <label>L'utilité de la formation</label>
                                <v-slider
                                    class="pl-1"
                                    v-model="quiz.trainingUsability"
                                    :min="0"
                                    :max="5"
                                    :step="1"
                                    thumb-label
                                    :ticks="[0, 1, 2, 3, 4, 5]"
                                    show-ticks="always"
                                    tick-size="4"
                                    :readonly="readOnly"
                                    :rules="[...config.rules.required, ...config.rules.numberGreaterThanOne]"
                                ></v-slider>
                            </v-col>
                            <v-col cols="12" md="12" lg="6">
                                <label>Satisfaction flobale</label>
                                <v-slider
                                    class="pl-1"
                                    v-model="quiz.satisfactionGlobal"
                                    :min="0"
                                    :max="5"
                                    :step="1"
                                    thumb-label
                                    :ticks="[0, 1, 2, 3, 4, 5]"
                                    show-ticks="always"
                                    tick-size="4"
                                    :readonly="readOnly"
                                    :rules="[...config.rules.required, ...config.rules.numberGreaterThanOne]"
                                ></v-slider>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="12" lg="12">
                                <label>Sur une échelle de 0 à 10, recommanderiez-vous cette formation ?</label>
                                <v-slider
                                    class="pl-1"
                                    v-model="quiz.trainingRecommandation"
                                    :min="0"
                                    :max="10"
                                    :step="1"
                                    thumb-label
                                    :ticks="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                                    show-ticks="always"
                                    tick-size="4"
                                    :readonly="readOnly"
                                    :rules="[...config.rules.required, ...config.rules.numberGreaterThanOne]"
                                ></v-slider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12" lg="12">
                                <v-textarea
                                    variant="solo"
                                    label="Quelles améliorations pouvons- nous apporter à cette formation ?"
                                    v-model="quiz.opinionGlobal"
                                    counter
                                    rows="2"
                                    clearable
                                    :readonly="readOnly"
                                    :rules="config.rules.limitSizeTo256"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-window-item>
                </v-window>
            </v-card-text>
        </v-card>
    </v-form>
    <span v-else> L'apprenant n'a pas encore complété le questionnaire. </span>
</template>

<script>
import {config} from "@/config";

export default {
    name: "QuizForm",
    inject: ["getUser", "createAlert"],
    props: {
        submit: String,
        defaultReadOnly: Boolean,
        defaultUserView: String,
    },
    data() {
        return {
            config,
            tab: null,
            readOnly: this.defaultReadOnly,
            userView: this.defaultUserView,
            isCompleted: false,
            quiz: {
                informationAccess: 0,
                workQuality: 0,
                opinionOrganisation: "",
                hourQuantity: "Adapté",
                learningSupport: 0,
                trainingTarget: 0,
                practiceLearning: 0,
                opinionTraining: "",
                learningCapacity: 0,
                subjectKnowledge: 0,
                programRespect: 0,
                pedagogicalApproach: 0,
                opinionLearner: "",
                trainingUsability: 0,
                satisfactionGlobal: 0,
                trainingRecommandation: 0,
                opinionGlobal: "",
            },
        };
    },
    methods: {
        async submitForm() {
            if ((await this.$refs.form.validate()).valid) {
                this.quiz.formationId = this.$route.params.formationId;
                this.$emit("formSubmitted", {
                    quiz: this.quiz,
                });
            } else {
                this.createAlert([
                    {
                        type: "error",
                        title: "Vos répondes sont invalides.",
                    },
                ]);
            }
        },
    },
    beforeMount() {
        this.userId = this.$route.params.userId;
        this.formationId = this.$route.params.formationId;

        fetch(`${config.url}/${this.userView}/quiz${this.userView === "team" ? "/" + this.userId : ""}/${this.formationId}`,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "GET",
            }
        )
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 204) {
                    this.isCompleted = this.userView !== "team";
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (!data) return;
                if (data.quiz) {
                    this.quiz = data.quiz;
                    this.isCompleted = true;
                    if (this.userView === "student") {
                        this.readOnly =
                            data.correctDate ? this.readOnly : true;
                    } else {
                        this.$emit("quizFormComplet", this.isCompleted);
                        this.readOnly = true;
                    }
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
