<template>
    <div>
        <v-container fluid>
            <SideBar :profil="'team'"/>

            <h2>Création d'un parcours</h2>

            <CursusForm :submit="'Valider'" @formSubmitted="createCursus"/>
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import CursusForm from "@/components/team/training/CursusForm";
import {config} from "@/config";

export default {
    name: "TeamCursusAdd",
    inject: ["getUser", "createAlert"],
    components: {
        SideBar,
        CursusForm,
    },
    methods: {
        createCursus: function (form) {
            fetch(`${config.url}/team/cursus`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "POST",
                body: JSON.stringify({
                    name: form.name,
                    formations: form.selectedFormations,
                }),
            })
                .then(async (res) => {
                    if (res.status === 201) {
                        this.$router.push("/team/training/cursus");

                        this.createAlert([
                            {
                                type: "success",
                                title: "Cursus créé",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
};
</script>
