<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <h2 v-if="profil === 'team'">Modification d'un événement</h2>

            <EventForm
                :submit="'Enregistrer'"
                :defaultName="name"
                :defaultStartDate="startDate"
                :defaultEndDate="endDate"
                :defaultFormation="formation"
                :defaultTeamUser="teamUser"
                :defaultSelectedStudentUsers="selectedStudentUsers"
                :defaultEventType="eventType"
                :defaultAllDay="!!allDay"
                :readOnly="profil!=='team'"
                :eventId="id"
                @formSubmitted="updateEvent"
            />
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import EventForm from "@/components/team/calendar/EventForm";
import {config} from "@/config";

export default {
    name: "TeamEventEdit",
    inject: ["getUser", "createAlert"],
    components: {
        SideBar,
        EventForm,
    },
    data() {
        return {
            id: "",
            name: "",
            startDate: "",
            endDate: "",
            formation: null,
            teamUser: null,
            selectedStudentUsers: [],
            eventType: null,
            allDay: "",
            profil: "trainer",
        };
    },
    methods: {
        formatDate: function (date) {
            const formattedDate = new Date(date);

            const year = formattedDate.getFullYear();
            const month = (formattedDate.getMonth() + 1)
                .toString()
                .padStart(2, "0");
            const day = formattedDate.getDate().toString().padStart(2, "0");

            let returnDate = `${year}-${month}-${day}`;

            if (!this.allDay) {
                const hours = formattedDate
                    .getHours()
                    .toString()
                    .padStart(2, "0");
                const minutes = formattedDate
                    .getMinutes()
                    .toString()
                    .padStart(2, "0");

                return `${returnDate}T${hours}:${minutes}`;
            }

            return returnDate;
        },
        updateEvent: function (form) {
            fetch(`${config.url}/team/event/${this.id}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "PATCH",
                body: JSON.stringify(form),
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        this.createAlert([
                            {
                                type: "success",
                                title: "Évenement mis à jour",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
    beforeMount() {
        this.id = this.$route.params.id;

        fetch(`${config.url}/team/event/${this.id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 404 || res.status === 403) {
                    this.$router.push("/404");
                } else if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    const event = data.event;
                    this.name = event.title;
                    this.allDay = event.allDay;
                    this.startDate = this.formatDate(event.start);
                    this.endDate = this.formatDate(event.end);
                    this.formation = event.extendedProps.formation;
                    this.teamUser = event.extendedProps.teamUser;
                    this.selectedStudentUsers =
                        event.extendedProps.selectedStudentUsers;
                    this.eventType = event.extendedProps.eventType.id;

                    this.profil = data.profil;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
