<template>
    <div>
        <SideBar :profil="'student'"/>

        <v-container fluid>
            <h2>Détails : {{ formation }}</h2>

            <div class="d-flex justify-space-between">
                <v-btn color="#374840" @click="this.$router.go(-1)" class="mt-5"
                >Retour
                </v-btn
                >
            </div>

            <v-card class="mt-5">
                <v-tabs
                    v-model="tab"
                    color="#374840"
                    show-arrows
                >
                    <v-tab value="SES">Sessions</v-tab>
                    <v-tab value="NOT">Notations</v-tab>
                    <v-tab value="QUE">Questionnaire</v-tab>
                </v-tabs>

                <v-card-text>
                    <v-window v-model="tab">
                        <v-window-item value="SES">
                            <v-card
                                v-for="event in events"
                                :key="event.id"
                                class="mb-5 mt-2 modern-card"
                            >
                                <v-card-title>
                                    <div class="card-title">{{ event.title }}</div>
                                </v-card-title>
                                <v-card-subtitle>
                                    {{ formatDate(event.start) + ' - ' + formatDate(event.end) }}
                                </v-card-subtitle>
                                <v-card-text>
                                    {{ event.eventTypeName + ' - ' + event.formationName }}
                                </v-card-text>
                            </v-card>
                        </v-window-item>
                        <v-window-item value="NOT">
                            <v-btn
                                v-if="scores"
                                color="#374840"
                                :to="
                                    '/student/training/formation/' +
                                    formationId +
                                    '/score-start'
                                "
                            >Note début
                            </v-btn>
                            <v-btn
                                v-if="scores"
                                color="#374840"
                                :to="
                                    '/student/training/formation/' +
                                    formationId +
                                    '/score-end'
                                "
                            >Note fin
                            </v-btn>

                            <v-table v-if="scores">
                                <thead>
                                <tr>
                                    <th rowspan="2" class="text-left">
                                        Compétence
                                    </th>
                                    <th colspan="2" class="text-center">
                                        Auto évaluation
                                    </th>
                                </tr>
                                <tr>
                                    <th class="text-center">Initiale</th>
                                    <th class="text-center">Finale</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                    v-for="(skill, key) in scores.skills"
                                    :key="`${key}`"
                                    style="vertical-align: middle"
                                >
                                    <td>
                                        {{ skill.ref }} {{ skill.name }}
                                    </td>
                                    <td class="text-center">
                                        {{ scores.studentStart[key] }}
                                    </td>
                                    <td class="text-center">
                                        {{ scores.studentEnd[key] }}
                                    </td>
                                </tr>
                                </tbody>
                            </v-table>
                        </v-window-item>
                        <v-window-item value="QUE">
                            <QuizForm
                                :submit="'Enregistrer'"
                                :defaultReadOnly="false"
                                :defaultUserView="'student'"
                                @formSubmitted="updateQuiz"
                            />
                        </v-window-item>
                    </v-window>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import QuizForm from "@/components/team/training/QuizForm";
import {config} from "@/config";

export default {
    name: "StudentTrainingInformation",
    inject: ["getUser", "createAlert"],
    components: {
        SideBar,
        QuizForm,
    },
    data() {
        return {
            tab: null,
            formationId: "",
            formation: "",
            events: [],
            scores: null,
        };
    },
    methods: {
        formatDate(date) {
            const newDate = new Date(date);

            const options = {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
            };

            return new Intl.DateTimeFormat("fr-FR", options).format(newDate);
        },
        updateQuiz(form) {
            fetch(`${config.url}/student/quiz/${form.quiz.formationId}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "PATCH",
                body: JSON.stringify(form),
            })
                .then(async (res) => {
                    if (res.status === 201) {
                        this.createAlert([
                            {
                                type: "success",
                                title: "Votre avis a été pris en compte.",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
    beforeMount() {
        this.formationId = this.$route.params.formationId;

        fetch(`${config.url}/student/planning/formation/${this.formationId}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.events = data.events;
                    this.formation = data.formation;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });

        fetch(
            `${config.url}/student/score/training/formation/${this.formationId}`,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "GET",
            }
        )
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.scores = data;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>

<style scoped>
.modern-card {
    border: none;
    border-radius: 12px;
    background: #F7F1E3;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s, transform 0.3s;
}

.modern-card:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    transform: translateY(-6px);
}
</style>
