<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <h2>Détails : {{ user }}</h2>

            <div class="d-flex justify-space-between">
                <v-btn color="#374840" @click="this.$router.go(-1)" class="mt-5">Retour</v-btn>

                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn
                            color="#374840"
                            v-bind="props"
                            class="mt-5"
                        >
                            Actions
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item>
                            <v-list-item-title @click="downloadSignatures()">Télécharger les présences
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <v-card class="mt-5">
                <v-card-title>Détails : {{ eventType }}</v-card-title>
                <v-card-text>
                    <p>Temps effectués : {{ durations ? durations.eventsDuration + 'h' : '' }}</p>
                    <p class="mt-2">Temps absents : {{ durations ? durations.eventsNotDuration + 'h' : '' }}</p>
                </v-card-text>
            </v-card>

            <v-card class="mt-5">
                <v-tabs
                    v-model="tab"
                    color="#374840"
                    show-arrows
                >
                    <v-tab value="SES">Sessions</v-tab>
                </v-tabs>

                <v-card-text>
                    <v-window v-model="tab">
                        <v-window-item value="SES">
                            <v-card
                                :to="'/team/calendar/' + event.id + '/edit'"
                                v-for="event in events"
                                :key="event.id"
                                class="mb-5 mt-2 modern-card"
                            >
                                <v-row>
                                    <v-col cols="12" md="12" :lg="event.signatureStart ? '11' : '12'">
                                        <v-card-title class="card-title">{{ event.title }}</v-card-title>
                                        <v-card-subtitle class="card-subtitle">
                                            {{ formatDate(event.start) }} - {{ formatDate(event.end) }}
                                        </v-card-subtitle>
                                        <v-card-text class="card-text">
                                            {{ event.eventTypeName }}
                                        </v-card-text>
                                    </v-col>
                                    <v-col v-if="event.signatureStart" cols="12" md="12" lg="1"
                                           class="d-flex justify-center align-center">
                                        <v-icon color="green">mdi-check-bold</v-icon>
                                    </v-col>
                                </v-row>
                            </v-card>

                        </v-window-item>
                    </v-window>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import {config} from "@/config";

export default {
    name: "TeamUserTrainingEventType",
    inject: ["getUser", "createAlert", "checkAuthorization"],
    components: {
        SideBar,
    },
    data() {
        return {
            tab: null,
            userId: "",
            user: "",
            eventTypeId: "",
            eventType: "",
            events: [],
            durations: null,
        };
    },
    methods: {
        formatDate(date) {
            const newDate = new Date(date);

            const options = {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
            };

            return new Intl.DateTimeFormat("fr-FR", options).format(newDate);
        },
        downloadSignatures() {
            fetch(`${config.url}/team/signature/event-type/${this.eventTypeId}/student/${this.userId}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "GET",
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        const pdfBlob = await res.blob();
                        const pdfUrl = URL.createObjectURL(pdfBlob);

                        const downloadLink = document.createElement("a");
                        downloadLink.href = pdfUrl;
                        downloadLink.download = "LAD-signatures.pdf";
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);

                        this.createAlert([
                            {
                                type: "success",
                                title: "Le document a été téléchargé.",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
    beforeMount() {
        this.userId = this.$route.params.userId;
        this.eventTypeId = this.$route.params.eventTypeId;

        fetch(
            `${config.url}/team/event/user/${this.userId}/event-type/${this.eventTypeId}`,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "GET",
            }
        )
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.events = data.events;
                    this.durations = data.durations;
                    this.user = data.user;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>

<style scoped>
.modern-card {
    border: none;
    border-radius: 12px;
    background: #F7F1E3;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s, transform 0.3s;
}

.modern-card:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    transform: translateY(-6px);
}
</style>