<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <v-btn class="mr-2" color="#374840" @click="this.$router.go(-1)">Retour</v-btn>
            <v-btn
                icon="mdi-plus" size="large"
                v-if="checkAuthorization('team:eventType:create')"
                to="/team/event-type/add">
            </v-btn>

            <v-card
                class="mt-1"
                title="Liste des types d'événements"
                flat
            >
                <template v-slot:text>
                    <v-text-field
                        v-model="search"
                        label="Recherche"
                        prepend-inner-icon="mdi-magnify"
                        variant="solo"
                        hide-details
                        single-line
                    ></v-text-field>
                </template>

                <v-data-table
                    :headers="headers"
                    :items="allEventType"
                    :search="search"
                    :items-per-page-text="'Éléments par page:'"
                    :no-data-text="'Aucune donnée disponible'"
                    multi-sort
                    :items-per-page-options="[10, 25, 50]"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.id }}</td>
                            <td>{{ item.name }}</td>
                            <td
                                :style="
                                'background-color:' + item.backgroundColor
                            "
                            >
                                {{ item.backgroundColor }}
                            </td>
                            <td class="text-left">
                                <v-btn
                                    icon="mdi-pencil" size="large" variant="text"
                                    v-if="checkAuthorization('team:eventType:update')"
                                    :to="'/team/event-type/' + item.id + '/edit'"
                                ></v-btn>
                                <v-btn
                                    icon="mdi-delete" size="large" variant="text"
                                    v-if="checkAuthorization('team:eventType:delete')"
                                    color="red"
                                    @click="openDialog(item.id)"
                                ></v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>

            <Confirm
                v-show="confirm.open"
                :open="confirm.open"
                :text="confirm.text"
                @confirmDialog="deleteEventType"
                @closeDialog="closeDialog"
            />
        </v-container>
    </div>
</template>
<script>
import SideBar from "@/components/SideBar";
import {config} from "@/config";
import Confirm from "@/components/Confirm.vue";

export default {
    name: "TeamEventTypeList",
    inject: ["getUser", "createAlert", "checkAuthorization"],
    components: {
        Confirm,
        SideBar,
    },
    data() {
        return {
            confirm: {
                open: false,
                text: "",
                selected: "",
            },
            search: '',
            headers: [
                {
                    align: 'start', key: 'id', title: 'Id',
                },
                {key: 'name', title: 'Nom'},
                {key: 'backgroundColor', title: 'Couleur'},
                {key: 'actions', title: 'Actions', sortable: false},
            ],
            allEventType: [],
        }
    },
    methods: {
        openDialog(id) {
            this.confirm.selected = id;
            this.confirm.text =
                "Êtes-vous sûr de vouloir supprimer ce type d'événement ?";
            this.confirm.open = true;
        },
        closeDialog() {
            this.confirm.open = false;
            this.confirm.selected = "";
            this.confirm.text = "";
        },
        deleteEventType: function () {
            const key = this.allEventType.findIndex(
                (doc) => doc.id === this.confirm.selected
            );

            const id = this.confirm.selected;

            this.closeDialog();

            fetch(`${config.url}/team/event-type/${id}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "DELETE",
            })
                .then((res) => {
                    if (res.status === 204) {
                        this.allEventType.splice(key, 1);

                        this.createAlert([
                            {
                                type: "success",
                                title: "Type d'événement supprimé.",
                            },
                        ]);
                    } else {
                        this.createAlert([
                            {
                                type: "error",
                                title: "Une erreur est survenue.",
                            },
                        ]);
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
    beforeMount() {
        fetch(`${config.url}/team/event-type`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 403) {
                    this.$router.push("/404");
                } else if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.allEventType = data;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
