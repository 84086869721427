<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <v-btn
                icon="mdi-plus" size="large"
                v-if="checkAuthorization('team:notification:create')"
                to="/team/notification/add"
            >
            </v-btn>

            <v-card
                class="mt-1"
                title="Liste des notifications"
                flat
            >
                <v-data-table
                    :headers="headers"
                    :items="notifications"
                    :items-per-page-text="'Éléments par page:'"
                    :no-data-text="'Aucune donnée disponible'"
                    multi-sort
                    :items-per-page-options="[10, 25, 50]"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td
                                :style="{ color: item.isread ? 'green' : 'red' }"
                                @click="openDialog(item.message)"
                            >
                                {{
                                    item.isread ? 'Lu' : 'Non lu'
                                }}
                            </td>
                            <td @click="openDialog(item.message)">
                                {{
                                    item.message.slice(0, 50)
                                }}
                                <span v-if="item.message.length > 50" class="highlight-text" style="cursor:pointer;">
                                  ... (Cliquez pour afficher la suite)
                                </span>
                            </td>
                            <td @click="openDialog(item.message)">{{ item.createdAt }}</td>
                            <td class="text-left d-flex">
                                <v-btn
                                    icon
                                    size="large"
                                    variant="text"
                                    @click="updateNotification(item.id, item.isread)"
                                >
                                    <v-icon>
                                        {{ item.isread ? 'mdi-bell-badge' : 'mdi-bell' }}
                                    </v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>

            <Dialog
                v-show="confirm.open"
                :open="confirm.open"
                :text="confirm.message"
                @closeDialog="closeDialog"
            />
        </v-container>
    </div>
</template>
<script>
import SideBar from "@/components/SideBar";
import {config} from "@/config";
import Dialog from "@/components/team/notification/Dialog.vue";

export default {
    name: "TeamNotificationList",
    inject: ["getUser", "createAlert", "checkAuthorization"],
    components: {
        Dialog,
        SideBar,
    },
    data() {
        return {
            confirm: {
                open: false,
                message: "",
            },
            headers: [
                {key: 'status', title: 'Status'},
                {key: 'message', title: 'Message'},
                {key: 'createdAt', title: 'Date de création'},
                {key: 'actions', title: 'Actions', sortable: false},
            ],
            notifications: [],
        }
    },
    beforeMount() {
        fetch(`${config.url}/team/notification`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 403) {
                    this.$router.push("/404");
                } else if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                this.notifications = data;
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
    methods: {
        openDialog(message) {
            this.confirm.message = message;
            this.confirm.open = true;
        },
        closeDialog() {
            this.confirm.open = false;
            this.confirm.message = "";
        },
        updateNotification: function (id, isread) {
            fetch(`${config.url}/team/notification/${id}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "PATCH",
                body: JSON.stringify({isread: !isread}),
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        const notification = this.notifications.find(obj => obj.id === id);
                        if (notification) {
                            notification.isread = !isread;
                        }

                        if (isread) {
                            this.getUser().notifications++;
                        } else {
                            this.getUser().notifications--;
                        }

                        this.createAlert([
                            {
                                type: "success",
                                text: isread
                                    ? "Notification marquée comme non lu."
                                    : "Notification marquée comme lu."
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    }
};
</script>

<style scoped>
.highlight-text {
    color: blue;
    font-weight: bold;
}
</style>