<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <v-card
                title="Liste des mails"
                flat
            >
                <template v-slot:text>
                    <v-text-field
                        v-model="search"
                        label="Recherche"
                        prepend-inner-icon="mdi-magnify"
                        variant="solo"
                        hide-details
                        single-line
                    ></v-text-field>
                </template>

                <v-data-table
                    :headers="headers"
                    :items="mails"
                    :search="search"
                    :items-per-page-text="'Éléments par page:'"
                    :no-data-text="'Aucune donnée disponible'"
                    multi-sort
                    :items-per-page-options="[10, 25, 50]"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.id }}</td>
                            <td>{{ item.name }}</td>
                            <td v-if="item.archive">Archivé</td>
                            <td v-else>Non archivé</td>
                            <td class="text-left">
                                <v-btn
                                    icon="mdi-pencil" size="large" variant="text"
                                    v-if="checkAuthorization('team:mail:update')"
                                    :to="'/team/mails/' + item.id + '/edit'"
                                ></v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-container>
    </div>
</template>
<script>
import SideBar from "@/components/SideBar";
import {config} from "@/config";

export default {
    name: "TeamMailList",
    inject: ["getUser", "createAlert", "checkAuthorization"],
    components: {
        SideBar,
    },
    data() {
        return {
            search: '',
            headers: [
                {
                    align: 'start', key: 'id', title: 'Id',
                },
                {key: 'name', title: 'Nom'},
                {key: 'archive', title: 'Archivage'},
                {key: 'actions', title: 'Actions', sortable: false},
            ],
            mails: [],
        }
    },
    beforeMount() {
        fetch(`${config.url}/team/mail`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 403) {
                    this.$router.push("/404");
                } else if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.mails = data;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
