<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <h2>Modification d'un atelier</h2>

            <WorkshopForm
                :submit="'Enregistrer'"
                :defaultName="name"
                :defaultDateOfCreation="dateOfCreation"
                :defaultWorkshopPicture="workshopPicture"
                :defaultEMail="eMail"
                :defaultPhoneNumber="phoneNumber"
                :defaultActivity="activity"
                :defaultAccessibility="accessibility"
                :defaultDisponibility="disponibility"
                :defaultAddressStreetNumber="address.streetNumber"
                :defaultAddressStreetName="address.streetName"
                :defaultAddressPostalCode="address.postalCode"
                :defaultAddressCity="address.city"
                :defaultAddressCountry="address.country"
                @formSubmitted="updateWorkshop"
            />
        </v-container>
    </div>
</template>

<script>
import SideBar from "../../../components/SideBar";
import WorkshopForm from "@/components/team/workshop/WorkshopForm";
import {config} from "@/config";

export default {
    name: "TeamEditUser",
    inject: ["getUser", "createAlert"],
    components: {
        SideBar,
        WorkshopForm,
    },
    data() {
        return {
            id: "",
            name: "",
            dateOfCreation: "",
            workshopPicture: null,
            eMail: "",
            phoneNumber: "",
            activity: "",
            accessibility: "",
            disponibility: "",
            address: {
                streetNumber: "",
                streetName: "",
                postalCode: "",
                city: "",
                country: "",
            },
        };
    },
    methods: {
        updateWorkshop: function (form) {
            fetch(`${config.url}/team/workshop/${this.id}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "PATCH",
                body: JSON.stringify(form),
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        this.createAlert([
                            {
                                type: "success",
                                title: "Atelier mis à jour",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
    beforeMount() {
        this.id = this.$route.params.id;

        fetch(`${config.url}/team/workshop/${this.id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 404 || res.status === 403) {
                    this.$router.push("/404");
                } else if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.name = data.name;
                    this.dateOfCreation = data.dateOfCreation;
                    this.workshopPicture = data.workshopPicture;
                    this.eMail = data.eMail;
                    this.phoneNumber = data.phoneNumber;
                    this.activity = data.activity;
                    this.accessibility = data.accessibility;
                    this.disponibility = data.disponibility;
                    this.address = data.address;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
