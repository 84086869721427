<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <v-btn class="mt-5" color="#374840" @click="this.$router.go(-1)"
            >Retour
            </v-btn
            >

            <v-table>
                <thead>
                <tr>
                    <th class="text-left">Shortcode</th>
                    <th class="text-left">Description</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(shortcode, key) in shortcodes"
                    :key="`${key}`"
                    style="vertical-align: middle"
                >
                    <td>{{ shortcode.name }}</td>
                    <td>{{ shortcode.description }}</td>
                </tr>
                </tbody>
            </v-table>
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import {config} from "@/config";

export default {
    name: "TeamShortcodeDetail",
    inject: ["getUser", "createAlert"],
    components: {
        SideBar,
    },
    data() {
        return {
            shortcodes: [],
        };
    },
    beforeMount() {
        fetch(`${config.url}/team/shortcode/details`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.shortcodes = data;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
