<template>
    <v-container fluid>
        <v-card class="centered-section pa-4 mt-5 rounded-card">
            <h2 class="mb-3">Changement de mot de passe</h2>

            <v-form @submit.prevent="sendRequestPassword" ref="form" class="d-flex flex-column align-center w-100">
                <v-text-field
                    class="w-100 mb-3"
                    variant="solo"
                    label="Mot de passe"
                    :type="showPassword1 ? 'text' : 'password'"
                    :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                    v-model="values.password"
                    :rules="config.rules.required"
                    @click:append="showPassword1 = !showPassword1"
                ></v-text-field>

                <v-text-field
                    class="w-100 mb-3"
                    variant="solo"
                    label="Confirmation du mot de passe"
                    :type="showPassword2 ? 'text' : 'password'"
                    :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                    v-model="values.repeatPassword"
                    :rules="config.rules.required"
                    @click:append="showPassword2 = !showPassword2"
                ></v-text-field>

                <v-btn class="green-btn" type="submit">Soumettre</v-btn>
            </v-form>
        </v-card>
    </v-container>
</template>

<style scoped>
.centered-section {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 600px) {
    .centered-section {
        width: 90%;
    }
}

.rounded-card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>

<script>
import {config} from "@/config";

export default {
    name: "ResetPassword",
    inject: ["createAlert"],
    data() {
        return {
            config,
            showPassword1: false,
            showPassword2: false,
            values: {
                password: "",
                repeatPassword: "",
                token: "",
            },
        };
    },
    methods: {
        sendRequestPassword: async function () {
            if ((await this.$refs.form.validate()).valid) {
                fetch(`${config.url}/auth/resetPassword`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    method: "POST",
                    body: JSON.stringify(this.values),
                })
                    .then(async (res) => {
                        if (res.status === 200) {
                            this.$router.push("/auth/login");

                            this.createAlert([
                                {
                                    type: "success",
                                    title: "Mot de passe modifié",
                                },
                            ]);
                        } else {
                            const errors = await res.json();
                            if (errors && errors.errors) {
                                this.createAlert(
                                    errors.errors.map((error) => {
                                        return {
                                            type: "error",
                                            title: error.msg,
                                        };
                                    })
                                );
                            }
                        }
                    })
                    .catch(() => {
                        this.createAlert([
                            {
                                type: "error",
                                title: "Une erreur est survenue",
                            },
                        ]);
                    });
            }
        },
    },
    beforeMount() {
        this.values.token = this.$route.query.token;

        if (!this.$route.query.token) {
            this.$router.push("/404");
        }
    },
};
</script>
