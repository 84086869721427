<template>
    <v-navigation-drawer
        v-if="!$vuetify.display.mobile"
        app
        floating
        permanent
        class="border right-border"
    >
        <template v-slot:prepend>
            <v-list-item
                to="/profile"
                lines="two"
                :prepend-avatar="getUser().profilPicture"
                :title="getUser().name"
                :subtitle="getUser().profile"
            ></v-list-item>
        </template>

        <v-divider></v-divider>

        <v-list density="compact" nav>
            <v-list-item
                v-for="link in filteredLinks(true)"
                :key="link.text"
                link
            >
                <v-list-item
                    v-if="!link.name"
                    :prepend-icon="link.icon"
                    :title="link.text"
                    :value="link.text"
                    :to="link.to"
                ></v-list-item>

                <v-list-group v-else :value="link.name">
                    <template v-slot:activator="{ props }">
                        <v-list-item
                            v-bind="props"
                            :prepend-icon="link.icon"
                            :title="link.name"
                        ></v-list-item>
                    </template>

                    <v-list-item
                        v-for="subLink in subfilteredLinks(link.links)"
                        :key="subLink.text"
                        :prepend-icon="subLink.icon"
                        :title="subLink.text"
                        :value="subLink.text"
                        :to="subLink.to"
                    ></v-list-item>
                </v-list-group>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>

    <v-bottom-navigation v-else>
        <v-btn
            v-for="link in filteredLinks(false)"
            :key="link.text"
            :to="link.to"
        >
            <v-icon>{{ link.icon }}</v-icon>
            <span>{{ link.title }}</span>
        </v-btn>
    </v-bottom-navigation>
</template>

<script>
export default {
    inject: ["getUser", "checkAuthorization"],
    props: {
        profil: String,
    },
    data() {
        return {
            group: null,
            links: [
                {
                    profil: "admin",
                    text: "Tickets",
                    to: "/admin/ticket",
                    icon: "mdi-chat-question",
                    title: "Tickets",
                    displayMobile: true,
                    display: this.checkAuthorization("admin:*"),
                },
                {
                    profil: "admin",
                    text: "Administrateurs",
                    to: "/admin/users",
                    icon: "mdi-account-group",
                    title: "Utilisateurs",
                    displayMobile: true,
                    display: this.checkAuthorization("admin:*"),
                },
                {
                    name: "Dashboard",
                    profil: "team",
                    icon: "mdi-view-dashboard",
                    displayMobile: false,
                    display: null,
                    links: [
                        {
                            text: "Statistiques",
                            to: "/team",
                            icon: "mdi-finance",
                            display: this.checkAuthorization("team:stat:read"),
                        },
                        {
                            text: "Questionnaires",
                            to: "/team/quiz",
                            icon: "mdi-help-box-multiple",
                            display: this.checkAuthorization("team:quiz:read"),
                        }
                    ]
                },
                {
                    profil: "team",
                    text: "Agenda",
                    to: "/team/calendar",
                    icon: "mdi-calendar",
                    title: "Agenda",
                    displayMobile: true,
                    display: this.checkAuthorization("team:event:read"),
                },
                {
                    profil: "team",
                    text: "Utilisateurs",
                    to: "/team/users",
                    icon: "mdi-account-group",
                    title: "Utilisateurs",
                    displayMobile: true,
                    display: this.checkAuthorization("team:user:read"),
                },
                {
                    profil: "team",
                    text: "Ateliers",
                    to: "/team/workshop",
                    icon: "mdi-office-building",
                    displayMobile: false,
                    display: this.checkAuthorization("team:workshop:read"),
                },
                {
                    name: "Apprentissage",
                    profil: "team",
                    icon: "mdi-school",
                    displayMobile: false,
                    display: null,
                    links: [
                        {
                            text: "Parcours",
                            to: "/team/training/cursus",
                            icon: "mdi-school",
                            display:
                                this.checkAuthorization("team:cursus:read"),
                        },
                        {
                            text: "Formations",
                            to: "/team/training/formation",
                            icon: "mdi-school",
                            display: this.checkAuthorization(
                                "team:formation:read"
                            ),
                        },
                        {
                            text: "Compétences",
                            to: "/team/training/skill",
                            icon: "mdi-school",
                            display: this.checkAuthorization("team:skill:read"),
                        },
                    ],
                },
                {
                    profil: "team",
                    text: "Tickets",
                    to: "/team/ticket",
                    icon: "mdi-chat-question",
                    displayMobile: false,
                    display: this.checkAuthorization("team:ticket:read") && !this.checkAuthorization("admin:*"),
                },
                {
                    name: "Paramètrage",
                    profil: "team",
                    icon: "mdi-cog",
                    displayMobile: false,
                    display: null,
                    links: [
                        {
                            text: "Mails",
                            to: "/team/mails/",
                            icon: "mdi-mail",
                            display: this.checkAuthorization("team:mail:read"),
                        },
                        {
                            text: "Documents",
                            to: "/team/docs/",
                            icon: "mdi-file",
                            display: this.checkAuthorization("team:doc:read"),
                        },
                        {
                            text: "Permissions",
                            to: "/team/permission",
                            icon: "mdi-lock",
                            display: this.checkAuthorization(
                                "team:permission:read"
                            ),
                        },
                        {
                            text: "Variables",
                            to: "/team/varsys/",
                            icon: "mdi-variable",
                            display:
                                this.checkAuthorization("team:varsys:read"),
                        },
                    ],
                },
                {
                    profil: "team",
                    text: "Liens",
                    to: "/team/links",
                    icon: "mdi-dots-horizontal",
                    title: "Autres",
                    displayMobile: true,
                    display: this.checkAuthorization("team"),
                },
                // {
                //     profil: "student",
                //     text: "Accueil",
                //     to: "/student/planning",
                //     icon: "mdi-account",
                //     displayMobile: true,
                //     display: this.checkAuthorization("student:*"),
                // },
                {
                    profil: "student",
                    text: "Planning",
                    to: "/student/planning",
                    icon: "mdi-calendar-range",
                    title: "Agenda",
                    displayMobile: true,
                    display: this.checkAuthorization("student:*"),
                },
                {
                    profil: "student",
                    text: "Formations",
                    to: "/student/training",
                    icon: "mdi-school",
                    title: "Formations",
                    displayMobile: true,
                    display: this.checkAuthorization("student:*"),
                },
                {
                    profil: "student",
                    text: "Tutoriels",
                    to: "/student/tutorial",
                    icon: "mdi-help-circle",
                    title: "Tutoriels",
                    displayMobile: true,
                    display: this.checkAuthorization("student:*"),
                },
                {
                    profil: "student",
                    text: "Contact",
                    to: "/student/contact",
                    icon: "mdi-mail",
                    title: "Contact",
                    displayMobile: true,
                    display: this.checkAuthorization("student:*"),
                },
            ],
        };
    },
    methods: {
        filteredLinks(isLargeScreen) {
            if (isLargeScreen) {
                return this.links.filter(
                    (link) =>
                        link.profil === this.profil &&
                        link.text !== "Liens" &&
                        (link.display ||
                            (link.links &&
                                link.links.some((subLink) => subLink.display)))
                );
            }

            return this.links.filter(
                (link) =>
                    link.profil === this.profil &&
                    link.displayMobile &&
                    link.display
            );
        },
        subfilteredLinks(links) {
            return links.filter((link) => link.display);
        },
    },
};
</script>

<style scoped>
.right-border {
    border-right: 1px solid #ccc;
}
</style>
