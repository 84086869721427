<template>
    <SideBar :profil="'admin'"/>
</template>

<script>
import SideBar from "@/components/SideBar.vue";

export default {
    name: "HomeAdmin",
    components: {
        SideBar,
    },
};
</script>
