<template>
    <v-container fluid>
        <v-card class="centered-section pa-4 mt-5 rounded-card">
            <h2 class="mb-3">Se connecter</h2>

            <v-form @submit.prevent="login" ref="form" class="d-flex flex-column align-center w-100">
                <v-text-field
                    class="w-100 mb-3"
                    variant="solo"
                    label="Email"
                    v-model="values.email"
                    :rules="[...config.rules.required, ...config.rules.isAEmail]"
                ></v-text-field>

                <v-text-field
                    class="w-100 mb-3"
                    variant="solo"
                    label="Mot de passe"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="values.password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="config.rules.required"
                    @click:append="togglePasswordVisibility"
                ></v-text-field>

                <v-btn class="mb-3 green-btn" type="submit">Connexion</v-btn>

                <router-link
                    to="/auth/forgot-password"
                    class="forgot-password-link"
                >
                    Mot de passe oublié ?
                </router-link>
            </v-form>
        </v-card>
    </v-container>
</template>

<style scoped>
.centered-section {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 600px) {
    .centered-section {
        width: 90%;
    }
}

.rounded-card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.forgot-password-link {
    color: #374840;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    transition: color 0.3s ease-in-out;
}

.forgot-password-link:hover {
    color: #4a5d5a;
}
</style>

<script>
import {config} from "@/config";

export default {
    name: "AuthLogin",
    inject: ["setAuth", "createAlert"],
    data() {
        return {
            config,
            values: {
                email: "",
                password: "",
            },
            showPassword: false,
        };
    },
    methods: {
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        login: async function () {
            if ((await this.$refs.form.validate()).valid) {
                fetch(`${config.url}/auth/login`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    method: "POST",
                    body: JSON.stringify(this.values),
                })
                    .then(async (res) => {
                        if (res.status === 200) {
                            const data = await res.json();

                            this.setAuth(data);

                            this.createAlert([
                                {
                                    type: "success",
                                    title: "Connexion réussie.",
                                },
                            ]);
                        } else {
                            const errors = await res.json();
                            if (errors && errors.errors) {
                                this.createAlert(
                                    errors.errors.map((error) => {
                                        return {
                                            type: "error",
                                            title: error.msg,
                                        };
                                    })
                                );
                            }
                        }
                    })
                    .catch(() => {
                        this.createAlert([
                            {
                                type: "error",
                                title: "Une erreur est survenue",
                            },
                        ]);
                    });
            }
        },
    },
};
</script>
