<template>
    <div>
        <SideBar :profil="'student'"/>

        <v-container fluid>
            <v-btn class="mt-2" color="#374840" @click="this.$router.go(-1)">Retour</v-btn>

            <h2 class="mt-2">Notation par formation</h2>

            <v-row class="mt-2">
                <v-col cols="12" md="12" lg="12">
                    <v-card>
                        <v-list
                            class="d-flex flex-column"
                        >
                            <v-list-item>
                                <template v-slot:prepend>
                                    <v-avatar color="red">
                                        <v-icon color="white">mdi-numeric-1</v-icon>
                                    </v-avatar>
                                </template>

                                <v-list-item-content>
                                    <v-list-item-title>Rendez-vous dans l'onglet Formation</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="mt-2">
                                <template v-slot:prepend>
                                    <v-avatar color="red">
                                        <v-icon color="white">mdi-numeric-2</v-icon>
                                    </v-avatar>
                                </template>

                                <v-list-item-content>
                                    <v-list-item-title>Choisissez le parcours de formation</v-list-item-title>
                                    <v-list-item-subtitle>Exemple : Sac & bagage, Petite maroquinerie et
                                        accessoires...
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="mt-2">
                                <template v-slot:prepend>
                                    <v-avatar color="red">
                                        <v-icon color="white">mdi-numeric-3</v-icon>
                                    </v-avatar>
                                </template>

                                <v-list-item-content>
                                    <v-list-item-title>Choisissez la formation que vous souhaitez évaluer
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Exemple : Ceinture enchapée, Savoir faire 1
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <img v-if="$vuetify.display.mobile"
                                 class="mt-2 w-75 align-self-center"
                                 src="../../../assets/tutorials/mobile/quiz-1.png"
                                 alt="tutorial-quiz-1"/>
                            <img v-else
                                 class="mt-2 w-75 align-self-center"
                                 src="../../../assets/tutorials/dekstop/quiz-1.png"
                                 alt="tutorial-quiz-1"/>

                            <v-list-item class="mt-5">
                                <template v-slot:prepend>
                                    <v-avatar color="red">
                                        <v-icon color="white">mdi-numeric-4</v-icon>
                                    </v-avatar>
                                </template>

                                <v-list-item-content>
                                    <v-list-item-title>Sélectionnez le sous-onglet Notations</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="mt-2">
                                <template v-slot:prepend>
                                    <v-avatar color="red">
                                        <v-icon color="white">mdi-numeric-5</v-icon>
                                    </v-avatar>
                                </template>

                                <v-list-item-content>
                                    <v-list-item-title>Le tableau affiché vous récapitule vos notes</v-list-item-title>
                                    <v-list-item-subtitle>Il s'agit uniquement de votre auto évaluation
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="mt-2">
                                <template v-slot:prepend>
                                    <v-avatar color="red">
                                        <v-icon color="white">mdi-numeric-6</v-icon>
                                    </v-avatar>
                                </template>

                                <v-list-item-content>
                                    <v-list-item-title>Cliquez sur le bouton "Note début" ou "Note fin"
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <img v-if="$vuetify.display.mobile"
                                 class="mt-2 w-75 align-self-center"
                                 src="../../../assets/tutorials/mobile/score-1.png"
                                 alt="tutorial-quiz-1"/>
                            <img v-else
                                 class="mt-2 w-75 align-self-center"
                                 src="../../../assets/tutorials/dekstop/score-1.png"
                                 alt="tutorial-quiz-1"/>

                            <v-list-item class="mt-2">
                                <template v-slot:prepend>
                                    <v-avatar color="red">
                                        <v-icon color="white">mdi-numeric-7</v-icon>
                                    </v-avatar>
                                </template>

                                <v-list-item-content>
                                    <v-list-item-title>Renseignez vos notes
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Vous avez la possibilité de vous attribuer 5 à toutes vos
                                        notes
                                        en cliquant sur le bouton "Note maximum"
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="mt-2">
                                <template v-slot:prepend>
                                    <v-avatar color="red">
                                        <v-icon color="white">mdi-numeric-8</v-icon>
                                    </v-avatar>
                                </template>

                                <v-list-item-content>
                                    <v-list-item-title>Valider votre saisie</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <img v-if="$vuetify.display.mobile"
                                 class="mt-2 w-75 align-self-center"
                                 src="../../../assets/tutorials/mobile/score-2.png"
                                 alt="tutorial-quiz-2"/>
                            <img v-else
                                 class="mt-2 w-75 align-self-center"
                                 src="../../../assets/tutorials/dekstop/score-2.png"
                                 alt="tutorial-quiz-2"/>

                            <v-list-item class="mt-2">
                                <template v-slot:prepend>
                                    <v-avatar color="red">
                                        <v-icon color="white">mdi-numeric-9</v-icon>
                                    </v-avatar>
                                </template>

                                <v-list-item-content>
                                    <v-list-item-title>Une alerte verte apparaîtra pour vous confirmer l'enregistrement
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Si l'alerte est rouge, corrigez l'erreur et cliquez de nouveau
                                        sur le bouton "Valider"
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar.vue";

export default {
    name: "StudentTutorialScore",
    components: {
        SideBar,
    },
};
</script>

<style scoped>
.v-list-item-title {
    white-space: normal !important;
}

.v-list-item-subtitle {
    -webkit-line-clamp: unset !important;
}
</style>
