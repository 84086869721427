<template>
    <v-container fluid class="text-center">
        <v-row justify="center" align="center" class="my-10">
            <v-col cols="12" md="8">
                <v-img
                    src="@/assets/404-image.jpg"
                    alt="Page Not Found"
                    class="mb-5"
                />
                <h1 class="display-4 font-weight-bold mb-4">404</h1>
                <h2 class="headline mb-6">La page que vous cherchez n'existe pas</h2>
                <v-btn to="/" color="primary" large>Retour à la page d'accueil</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "PageNotFound",
};
</script>
