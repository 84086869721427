<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <h2>Suivi : {{ user }}</h2>

            <v-btn color="#374840" @click="this.$router.go(-1)" class="mt-5"
            >Retour
            </v-btn
            >

            <v-row align="start" justify="center" class="mt-5">
                <v-col
                    v-for="(item, index) in cursusFormations"
                    :key="index"
                    cols="12"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <v-card class="mx-auto">
                        <v-card-title>{{ item.name }}</v-card-title>

                        <v-card-actions>
                            <v-btn
                                color="#374840"
                                variant="text"
                                @click="showDetails(item)"
                            >Voir les formations
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn
                                :icon="
                                    selectedItem && selectedItem.id === item.id
                                        ? 'mdi-chevron-up'
                                        : 'mdi-chevron-down'
                                "
                                @click="showDetails(item)"
                            ></v-btn>
                        </v-card-actions>

                        <v-expand-transition>
                            <div
                                v-show="
                                    selectedItem && selectedItem.id === item.id
                                "
                            >
                                <v-divider></v-divider>

                                <v-card-text>
                                    <v-card class="mx-auto">
                                        <v-list>
                                            <v-list-item
                                                v-for="(
                                                    formation, formationIndex
                                                ) in item.formations"
                                                :key="formationIndex"
                                            >
                                                <v-list-item
                                                    :to="
                                                        '/team/users/' +
                                                        id +
                                                        '/training/formation/' +
                                                        formation.id
                                                    "
                                                    :title="formation.name"
                                                    :value="formation.name"
                                                ></v-list-item>
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-card-text>
                            </div>
                        </v-expand-transition>
                    </v-card>
                </v-col>
            </v-row>

            <v-row align="start" justify="center" class="mt-5" v-if="eventTypes.length > 0">
                <v-col
                    cols="12"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <v-card class="mx-auto">
                        <v-card-title>Suivi hors formations</v-card-title>

                        <v-card-actions>
                            <v-btn
                                color="#374840"
                                variant="text"
                                @click="showEventTypeDetails"
                            >Voir le détail
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn
                                :icon="
                                    selectedEvenType
                                        ? 'mdi-chevron-up'
                                        : 'mdi-chevron-down'
                                "
                                @click="showEventTypeDetails"
                            ></v-btn>
                        </v-card-actions>

                        <v-expand-transition>
                            <div v-show="selectedEvenType">
                                <v-divider></v-divider>

                                <v-card-text>
                                    <v-card class="mx-auto">
                                        <v-list>
                                            <v-list-item
                                                v-for="(
                                                    eventType, eventTypeIndex
                                                ) in eventTypes"
                                                :key="eventTypeIndex"
                                            >
                                                <v-list-item
                                                    :to="
                                                        '/team/users/' +
                                                        id +
                                                        '/training/event-type/' +
                                                        eventType.id
                                                    "
                                                    :title="eventType.name"
                                                    :value="eventType.name"
                                                ></v-list-item>
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-card-text>
                            </div>
                        </v-expand-transition>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import {config} from "@/config";

export default {
    name: "TeamUserTrainingIndex",
    inject: ["getUser", "createAlert"],
    components: {
        SideBar,
    },
    data() {
        return {
            cursusFormations: [],
            eventTypes: [],
            selectedItem: null,
            selectedEvenType: null,
            id: "",
            user: "",
        };
    },
    methods: {
        showDetails(item) {
            this.selectedItem = this.selectedItem === item ? null : item;
            this.selectedEvenType = null;
        },
        showEventTypeDetails() {
            this.selectedEvenType = !this.selectedEvenType;
            this.selectedItem = null;
        },
    },
    beforeMount() {
        this.id = this.$route.params.id;

        fetch(`${config.url}/team/cursus/formations/user/${this.id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.cursusFormations = data.cursusFormations;
                    this.user = data.user;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });

        fetch(`${config.url}/team/event-type/user/${this.id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.eventTypes = data;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
