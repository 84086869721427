<template>
    <SideBar :profil="'team'"/>

    <v-container fluid>
        <h2>Statistiques</h2>

        <v-form @submit.prevent="submit" ref="form">
            <v-row class="mt-5">
                <v-col cols="12" md="12" lg="4">
                    <v-select
                        variant="solo"
                        label="Statistique"
                        :items="filteredStats"
                        item-title="name"
                        item-value="id"
                        v-model="stat"
                        :rules="config.rules.required"
                        @update:modelValue="setDatas"
                    ></v-select>
                </v-col>

                <v-col cols="12" md="12" lg="4">
                    <v-text-field
                        variant="solo"
                        label="Date de début"
                        type="date"
                        v-model="startDate"
                        @change="setDate(true)"
                        :readonly="!(getProps && getProps.date)"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="12" lg="4">
                    <v-text-field
                        variant="solo"
                        label="Date de fin"
                        type="date"
                        v-model="endDate"
                        @change="setDate(false)"
                        :readonly="!(getProps && getProps.date)"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="12" lg="4">
                    <v-select
                        variant="solo"
                        label="Atelier"
                        :items="workshops"
                        item-title="name"
                        item-value="id"
                        v-model="workshop"
                        :readonly="!(getProps && getProps.workshop)"
                    ></v-select>
                </v-col>

                <v-col cols="12" md="12" lg="4">
                    <v-combobox
                        variant="solo"
                        label="Type d'événement"
                        :items="eventTypes"
                        item-title="name"
                        item-value="id"
                        v-model="eventType"
                        :readonly="!(getProps && getProps.formation)"
                    ></v-combobox>
                </v-col>

                <v-col cols="12" md="12" lg="4">
                    <v-combobox
                        variant="solo"
                        v-model="formation"
                        :items="formations"
                        item-title="name"
                        item-value="id"
                        label="Formation"
                        :readonly="!(getProps && getProps.formation) || !isFormation()"
                    ></v-combobox>
                </v-col>
            </v-row>

            <v-btn class="mt-5" type="submit">Valider</v-btn>
        </v-form>

        <v-row class="mt-5">
            <v-col cols="12">
                <v-card
                    v-if="result && !result.isTab"
                    hover
                >
                    <v-card-item>
                        <v-card-title>
                            Résultat de la recherche
                        </v-card-title>
                        <v-card-subtitle>
                            {{ stats.find(item => item.id === stat)?.name }}
                        </v-card-subtitle>
                    </v-card-item>

                    <v-card-text>
                        {{ result.value }}
                    </v-card-text>
                </v-card>

                <v-card
                    v-else-if="result && result.isTab"
                    class="mt-1"
                    title="Résultats de la recherche"
                    flat
                >
                    <v-data-table
                        :headers="result.headers"
                        :items="result.value"
                        :items-per-page-text="'Éléments par page:'"
                        :no-data-text="'Aucune donnée disponible'"
                        multi-sort
                        :items-per-page-options="[10, 25, 50]"
                    >
                        <template v-slot:item="{ item }">
                            <tr>
                                <td v-for="(value, key) in item" :key="key">{{ value }}</td>
                                <td class="text-left d-flex">
                                    <v-btn v-for="(action, key) in result.actions" :key="key"
                                           :icon="action.icon" size="large" variant="text"
                                           @click="performAction(item, action.text)"
                                    ></v-btn>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {config} from "@/config";
import SideBar from "@/components/SideBar.vue";

export default {
    name: "HomeTeam",
    inject: ["getUser", "createAlert", "checkAuthorization"],
    components: {
        SideBar,
    },
    data() {
        return {
            config,
            stat: null,
            result: null,
            startDate: null,
            endDate: null,
            workshops: [],
            eventTypes: [],
            formations: [],
            workshop: null,
            eventType: null,
            formation: null,
            stats: [
                {
                    id: 1,
                    name: 'Nombre de sessions par formation et/ou atelier sur une période',
                    date: true,
                    workshop: true,
                    formation: true,
                    display: this.getUser().profile !== 'Formateur'
                },
                {
                    id: 2,
                    name: 'Nombre d\'apprendant par formation et/ou atelier sur une période',
                    date: true,
                    workshop: true,
                    formation: true,
                    display: this.getUser().profile !== 'Formateur'
                },
                {
                    id: 3,
                    name: 'Questionnaires non complétés',
                    date: false,
                    workshop: false,
                    formation: false,
                    display: this.getUser().profile !== 'Formateur'
                },
                {
                    id: 4,
                    name: 'Notation non complétées par le formateur',
                    date: false,
                    workshop: false,
                    formation: false,
                    display: true
                },
                {
                    id: 5,
                    name: "Notation non complétées par l'apprenant",
                    date: false,
                    workshop: false,
                    formation: false,
                    display: this.getUser().profile !== 'Formateur'
                },
            ]
        };
    },
    computed: {
        getProps() {
            if (this.stat) {
                return this.stats.find(s => s.id === this.stat);
            }

            return null;
        },
        filteredStats() {
            return this.stats.filter(stat => stat.display);
        },
    },
    methods: {
        performAction(item, action) {
            switch (action) {
                case 'missingQuizzes': {
                    this.sendMail('quiz', item.userId, item.formationId);
                    break;
                }
                case 'missingTeamScores': {
                    const score = item.missingScoreType === "Note intermédiaire" ? "intermediate" : "end";
                    const url = `/team/users/${item.userId}/training/formation/${item.formationId}/score-${score}`;
                    window.open(url, '_blank');
                    break;
                }
                case 'missingStudentScores': {
                    const score = item.missingScoreType === "Note début" ? "start" : "end";
                    const url = `/team/users/${item.userId}/training/formation/${item.formationId}/student-score-${score}`;
                    window.open(url, '_blank');
                    break;
                }
                case 'mailMissingStudentScores': {
                    this.sendMail('score', item.userId, item.formationId);
                    break;
                }
                default: {
                    console.error('Action non définie pour', action);
                }
            }
        },

        sendMail(type, userId, formationId) {
            fetch(`${config.url}/team/formation/${formationId}/student/${userId}?type=${type}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "GET",
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        this.createAlert([
                            {
                                type: "success",
                                title: "Mail envoyé.",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
        setDatas() {
            if (!this.getProps.date) {
                this.startDate = null;
                this.endDate = null;
            }

            if (!this.getProps.workshop) {
                this.workshop = null;
            }

            if (!this.getProps.formation) {
                this.eventType = null;
                this.formation = null;
            }
        },
        setDate(isStart) {
            if (isStart && (!this.endDate || this.endDate < this.startDate)) {
                this.endDate = this.startDate;
            }
        },
        isFormation() {
            const eventTypeID = this.eventType && this.eventType.id !== undefined ? this.eventType.id : this.eventType;
            const isFormation = !!this.eventTypes.find(
                (item) =>
                    (item.id === eventTypeID || item.name === eventTypeID) &&
                    item.isFormation
            );

            if (!isFormation && eventTypeID && this.eventTypes.length > 0) {
                this.formation = null;
            }

            return isFormation;
        },
        async submit() {
            if ((await this.$refs.form.validate()).valid) {
                const eventTypeId = this.eventType && this.eventType.id !== undefined ? this.eventType.id : this.eventType;
                const formationId = this.formation && this.formation.id !== undefined ? this.formation.id : this.formation;
                const params = `?startDate=${this.startDate}&endDate=${this.endDate}&workshop=${this.workshop}&eventType=${eventTypeId}&formation=${formationId}`;

                fetch(`${config.url}/team/stats/stat/${this.stat}${params}`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + this.getUser().token,
                    },
                    method: "GET",
                })
                    .then(async (res) => {
                        if (res.status === 200) {
                            return res.json();
                        } else {
                            const errors = await res.json();
                            if (errors && errors.errors) {
                                this.createAlert(
                                    errors.errors.map((error) => {
                                        return {
                                            type: "error",
                                            title: error.msg,
                                        };
                                    })
                                );
                            }
                        }
                    })
                    .then((data) => {
                        if (data) {
                            this.result = data;
                        }
                    })
                    .catch(() => {
                        this.createAlert([
                            {
                                type: "error",
                                title: "Une erreur est survenue.",
                            },
                        ]);
                    });
            }
        }
    },
    beforeMount() {
        fetch(`${config.url}/team/stats/informations`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.workshops = data.workshops;
                    this.eventTypes = data.eventTypes;
                    this.formations = data.formations;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
