<template>
    <div>
        <v-container fluid>
            <SideBar :profil="'team'"/>

            <h2>Modification d'un type d'événement</h2>

            <EventTypeForm
                :submit="'Valider'"
                :defaultName="name"
                :defaultBackgroundColor="backgroundColor"
                :defaultIsFormation="!!isFormation"
                @formSubmitted="updateEventType"
            />
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import EventTypeForm from "@/components/team/event/EventTypeForm";
import {config} from "@/config";

export default {
    name: "TeamEventTypeEdit",
    inject: ["getUser", "createAlert"],
    components: {
        SideBar,
        EventTypeForm,
    },
    data() {
        return {
            id: "",
            name: "",
            backgroundColor: "",
            isFormation: "",
        };
    },
    methods: {
        updateEventType: function (form) {
            fetch(`${config.url}/team/event-type/${this.id}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "PATCH",
                body: JSON.stringify({
                    name: form.name,
                    backgroundColor: form.backgroundColor,
                    isFormation: form.isFormation,
                }),
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        this.createAlert([
                            {
                                type: "success",
                                title: "Type d'événement mis à jour",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
    beforeMount() {
        this.id = this.$route.params.id;

        fetch(`${config.url}/team/event-type/${this.id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 404 || res.status === 403) {
                    this.$router.push("/404");
                } else if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.name = data.name;
                    this.backgroundColor = data.backgroundColor;
                    this.isFormation = data.isFormation;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
