<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <h2>Création d'un événement</h2>

            <EventForm :submit="'Valider'" @formSubmitted="createEvent"/>
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import EventForm from "@/components/team/calendar/EventForm";
import {config} from "@/config";

export default {
    name: "TeamEventAdd",
    inject: ["getUser", "createAlert"],
    components: {
        SideBar,
        EventForm,
    },
    methods: {
        createEvent: function (form) {
            fetch(`${config.url}/team/event`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "POST",
                body: JSON.stringify(form),
            })
                .then(async (res) => {
                    if (res.status === 201) {
                        this.$router.push("/team/calendar");

                        this.createAlert([
                            {
                                type: "success",
                                title: "Évenement créé",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
};
</script>
