import {checkAuthorization, isAuthenticated} from "@/router/middlewares";

import HomeAdmin from "@/views/admin/Home";

import AdminTicketList from "@/views/admin/ticket/List";
import AdminTicketEdit from "@/views/admin/ticket/Edit";

import AdminListUser from "@/views/admin/user/List";
import AdminEditUser from "@/views/admin/user/Edit";

const adminRoutes = [
    {
        path: "/admin",
        component: HomeAdmin,
        beforeEnter: [isAuthenticated, checkAuthorization("admin:*")],
    },
    {
        path: "/admin/ticket/:id/edit",
        component: AdminTicketEdit,
        beforeEnter: [isAuthenticated, checkAuthorization("admin:*")],
    },
    {
        path: "/admin/ticket",
        component: AdminTicketList,
        beforeEnter: [isAuthenticated, checkAuthorization("admin:*")],
    },
    {
        path: "/admin/users",
        component: AdminListUser,
        beforeEnter: [isAuthenticated, checkAuthorization("admin:*")],
    },
    {
        path: "/admin/users/:id/edit",
        component: AdminEditUser,
        beforeEnter: [isAuthenticated, checkAuthorization("admin:*")],
    },
];

export default adminRoutes;
