<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <h2>
                Modification d'un ticket
            </h2>

            <TicketForm
                :submit="'Enregistrer'"
                :defaultSujet="sujet"
                :defaultDescription="description"
                :defaultStatus="status"
                :defaultType="type"
                :defaultComments="comments"
                :defaultPjs="pjs"
                :readOnly="true"
                :admin="false"
                @formSubmitted="updateTicket"
            />
        </v-container>
    </div>
</template>

<script>
import SideBar from "../../../components/SideBar";
import TicketForm from "@/components/team/ticket/TicketForm";
import {config} from "@/config";

export default {
    name: "TeamEditTicket",
    inject: ["getUser", "createAlert"],
    components: {
        SideBar,
        TicketForm,
    },
    data() {
        return {
            id: "",
            sujet: "",
            description: "",
            status: "",
            type: "",
            comments: [],
            pjs: [],
        };
    },
    methods: {
        updateTicket: function (form) {
            fetch(`${config.url}/team/ticket/${this.id}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "PATCH",
                body: JSON.stringify(form),
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        const datas = await res.json();
                        this.comments = datas.comments;
                        this.pjs = datas.pjs;

                        this.createAlert([
                            {
                                type: "success",
                                title: "Ticket mis à jour",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
    beforeMount() {
        this.id = this.$route.params.id;

        fetch(`${config.url}/team/ticket/${this.id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 404 || res.status === 403) {
                    this.$router.push("/404");
                } else if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.sujet = data.subject;
                    this.description = data.description;
                    this.status = data.status;
                    this.type = data.typeTicket;
                    this.comments = data.comments;
                    this.pjs = data.pjs;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
