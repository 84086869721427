<template>
    <v-form @submit.prevent="submitForm" ref="form">
        <v-btn class="mt-5" color="#374840" @click="this.$router.go(-1)">Retour</v-btn>

        <v-btn v-if="!readOnly" class="mt-5" type="submit">{{ submit }}</v-btn>

        <v-card class="mt-5">
            <v-tabs
                v-model="tab"
                color="#374840"
                show-arrows
            >
                <v-tab value="MAIN">Informations principales</v-tab>
                <v-tab v-if="eventId" value="USR">Présences</v-tab>
            </v-tabs>

            <v-card-text>
                <v-window v-model="tab">
                    <v-window-item value="MAIN">
                        <v-text-field
                            variant="solo"
                            label="Nom de l'événement"
                            v-model="name"
                            :rules="config.rules.required"
                            :readonly="readOnly"
                        ></v-text-field>

                        <v-row>
                            <v-col cols="12" sm="2">
                                <v-checkbox
                                    variant="solo"
                                    label="Journée entière"
                                    v-model="allDay"
                                    :readonly="readOnly"
                                    @change="setDate"
                                ></v-checkbox>
                            </v-col>

                            <v-col cols="12" :sm="allDay ? '10' : '5'">
                                <v-text-field
                                    variant="solo"
                                    label="Date de début"
                                    :type="allDay ? 'date' : 'datetime-local'"
                                    v-model="startDate"
                                    :rules="config.rules.required"
                                    :readonly="readOnly"
                                ></v-text-field>
                            </v-col>

                            <v-col v-if="!allDay" cols="12" sm="5">
                                <v-text-field
                                    variant="solo"
                                    label="Date de fin"
                                    type="datetime-local"
                                    v-model="endDate"
                                    :rules="config.rules.required"
                                    :readonly="readOnly"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row v-if="submit === 'Valider'">
                            <v-col cols="12" md="12" :lg="duplicate === '0' ? '12' : '6'">
                                <v-select
                                    variant="solo"
                                    label="Nombre de jour à dupliquer"
                                    :items="['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15']"
                                    v-model="duplicate"
                                ></v-select>
                            </v-col>

                            <v-col cols="12" md="12" lg="6" v-if="duplicate !== '0'">
                                <v-checkbox
                                    variant="solo"
                                    label="Créer le samedi"
                                    v-model="duplicateSaturday"
                                ></v-checkbox>
                            </v-col>
                        </v-row>

                        <v-combobox
                            variant="solo"
                            v-model="teamUser"
                            :items="teamUsers"
                            item-title="name"
                            item-value="id"
                            label="Membre de l'équipe"
                            :rules="config.rules.required"
                            :readonly="readOnly"
                        ></v-combobox>

                        <v-combobox
                            variant="solo"
                            label="Type d'événement"
                            :items="eventType"
                            item-title="name"
                            item-value="id"
                            v-model="selectedEventType"
                            :rules="config.rules.required"
                            :readonly="readOnly"
                        ></v-combobox>

                        <v-combobox
                            v-if="isFormation(selectedEventType)"
                            variant="solo"
                            v-model="formation"
                            :items="formations"
                            item-title="name"
                            item-value="id"
                            label="Choix de la formation"
                            :readonly="readOnly"
                        ></v-combobox>

                        <v-combobox
                            variant="solo"
                            v-model="selectedStudentUsers"
                            :items="studentUsers"
                            item-title="name"
                            item-value="id"
                            label="Choix des apprenants"
                            multiple
                            chips
                            :readonly="readOnly"
                        ></v-combobox>

                        <v-select
                            v-if="!readOnly"
                            variant="solo"
                            label="Mail à envoyer"
                            :items="getMails"
                            v-model="mail"
                        ></v-select>
                    </v-window-item>

                    <v-window-item v-if="eventId" value="USR">
                        <EventUser :eventId="eventId"/>
                    </v-window-item>
                </v-window>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
import {config} from "@/config";
import EventUser from "./EventUser.vue";

export default {
    name: "EventForm",
    inject: ["getUser", "createAlert"],
    components: {
        EventUser,
    },
    props: {
        submit: String,
        defaultName: String,
        defaultStartDate: String,
        defaultEndDate: String,
        defaultAllDay: Boolean,
        defaultEventType: Number,
        defaultFormation: Number,
        defaultTeamUser: Number,
        defaultSelectedStudentUsers: Array,
        readOnly: Boolean,
        eventId: String
    },
    data() {
        return {
            config,
            tab: null,
            name: "",
            mail: "Aucun",
            startDate: this.initDate(),
            endDate: this.initDate(),
            allDay: false,
            duplicate: "0",
            duplicateSaturday: false,
            eventType: [],
            selectedEventType: null,
            formation: null,
            teamUser: null,
            selectedStudentUsers: [],
            formations: [],
            teamUsers: [],
            studentUsers: [],
        };
    },
    watch: {
        defaultName: function (newVal) {
            this.name = newVal ? newVal : "";
        },
        defaultStartDate: function (newVal) {
            this.startDate = newVal ? newVal : "";
        },
        defaultEndDate: function (newVal) {
            this.endDate = newVal ? newVal : "";
        },
        defaultAllDay: function (newVal) {
            this.allDay = newVal ? newVal : false;
        },
        defaultEventType: function (newVal) {
            this.selectedEventType = newVal ? newVal : 0;
        },
        defaultFormation: function (newVal) {
            this.formation = newVal ? newVal : 0;
        },
        defaultTeamUser: function (newVal) {
            this.teamUser = newVal ? newVal : 0;
        },
        defaultSelectedStudentUsers: function (newVal) {
            this.selectedStudentUsers = Array.isArray(newVal) ? newVal : [];
        },
    },
    computed: {
        getMails() {
            const items = [
                'Aucun',
            ];

            if (this.selectedEventType && !this.isFormation(this.selectedEventType)) {
                items.push("Incription session de cours : hors formation");
            }

            if (this.isFormation(this.selectedEventType)) {
                items.push("Incription première session de cours");
                items.push("Incription session de cours");
            }

            return items;
        }
    },
    methods: {
        setDate() {
            if (this.allDay) {
                this.startDate = this.startDate.split("T")[0];
                this.endDate = this.endDate.split("T")[0];
            } else {
                this.startDate = this.startDate + "T10:00"
                this.endDate = this.endDate + "T12:00"
            }
        },
        isFormation(eventType) {
            const eventTypeID = eventType && eventType.id !== undefined ? eventType.id : eventType;
            const isFormation = !!this.eventType.find(
                (item) =>
                    (item.id === eventTypeID || item.name === eventTypeID) &&
                    item.isFormation
            );

            if (!isFormation && eventTypeID && this.eventType.length > 0) {
                this.formation = null;
            }
            return isFormation;
        },
        async submitForm() {
            if ((await this.$refs.form.validate()).valid) {
                const eventTypeId = this.selectedEventType && this.selectedEventType.id !== undefined ? this.selectedEventType.id : this.selectedEventType;
                const formationId = this.formation && this.formation.id !== undefined ? this.formation.id : this.formation;
                const teamUserId = this.teamUser && this.teamUser.id !== undefined ? this.teamUser.id : this.teamUser;
                const studentUsersId = this.selectedStudentUsers.every(student => typeof student === 'object') ?
                    this.selectedStudentUsers.map(student => student.id) : this.selectedStudentUsers;
                const end = this.allDay ? this.startDate : this.endDate;

                this.$emit("formSubmitted", {
                    title: this.name,
                    allDay: this.allDay,
                    start: this.startDate,
                    end: end,
                    eventTypeId: eventTypeId,
                    formationId: this.isFormation(eventTypeId) ? formationId : null,
                    teamUser: teamUserId,
                    selectedStudentUsers: studentUsersId,
                    mail: this.mail,
                    duplicate: this.duplicate,
                    duplicateSaturday: this.duplicateSaturday,
                });
            }
        },
        initDate: function () {
            const date = new Date();

            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, "0");
            const day = date.getDate().toString().padStart(2, "0");

            const hours = date.getHours().toString().padStart(2, "0");
            const minutes = date.getMinutes().toString().padStart(2, "0");

            return `${year}-${month}-${day}T${hours}:${minutes}`;
        },
    },
    beforeMount() {
        fetch(`${config.url}/team/event/informations`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.eventType = data.eventType;
                    this.studentUsers = data.students;
                    this.teamUsers = data.teams;
                    this.formations = data.formations;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
