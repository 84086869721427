<template>
    <v-form @submit.prevent="submitForm" ref="form">
        <v-btn
            v-if="!isProfile"
            class="mt-5"
            color="#374840"
            @click="this.$router.go(-1)"
        >Retour
        </v-btn>
        <v-btn
            v-if="
                checkAuthorization('team:user:create') ||
                checkAuthorization('team:user:update') ||
                isProfile
            "
            class="mt-5"
            type="submit"
        >{{ submit }}
        </v-btn>

        <v-checkbox
            v-if="submit === 'Valider'"
            class="mt-5"
            variant="solo"
            label="Envoyer le mail de création de compte"
            v-model="sendMail"
        ></v-checkbox>

        <v-card :class="submit === 'Valider' ? '' : 'mt-5'">
            <v-tabs
                v-model="tab"
                color="#374840"
                show-arrows
            >
                <v-tab value="ETC">Etat civil</v-tab>
                <v-tab value="HAB" v-if="!isProfile">Habilitation</v-tab>
                <v-tab value="CON">Contact</v-tab>
                <v-tab value="DIV">Divers</v-tab>
                <v-tab value="AUT">Autorisation</v-tab>
                <v-tab value="ADR">Adresse</v-tab>
                <v-tab value="ADM" v-if="!isProfile && !isTrainer">Administratif</v-tab>
                <v-tab value="OBS" v-if="!isProfile">Observation</v-tab>
                <v-tab value="PWD" v-if="isProfile">Mot de passe</v-tab>
            </v-tabs>

            <v-card-text>
                <v-window v-model="tab">
                    <v-window-item value="ETC">
                        <v-select
                            variant="solo"
                            label="Civilité"
                            :items="['Monsieur', 'Madame']"
                            v-model="civility"
                        ></v-select>

                        <v-text-field
                            variant="solo"
                            label="Prénom"
                            v-model="firstname"
                            :rules="config.rules.required"
                        ></v-text-field>

                        <v-text-field
                            variant="solo"
                            label="Nom"
                            v-model="lastname"
                            :rules="config.rules.required"
                        ></v-text-field>

                        <v-text-field
                            variant="solo"
                            label="Date de naissance"
                            type="date"
                            v-model="birthday"
                        ></v-text-field>
                    </v-window-item>

                    <v-window-item value="HAB" v-if="!isProfile">
                        <v-select
                            variant="solo"
                            label="Profil"
                            :items="profils"
                            item-title="profil"
                            item-value="id"
                            v-model="profilId"
                            :rules="config.rules.required"
                        ></v-select>

                        <v-select
                            variant="solo"
                            label="Atelier"
                            :items="workshops"
                            item-title="name"
                            item-value="id"
                            v-model="workshopId"
                            :rules="config.rules.required"
                        ></v-select>
                    </v-window-item>

                    <v-window-item value="CON">
                        <v-text-field
                            variant="solo"
                            label="Email"
                            v-model="email"
                            :rules="[
                                ...config.rules.required,
                                ...config.rules.isAEmail,
                            ]"
                        ></v-text-field>

                        <v-text-field
                            variant="solo"
                            label="Numéro de téléphone"
                            v-model="phoneNumber"
                            :rules="[
                                ...config.rules.required,
                                ...config.rules.isAPhoneNumber,
                            ]"
                            maxlength="10"
                        ></v-text-field>
                    </v-window-item>

                    <v-window-item value="DIV">
                        <v-select
                            variant="solo"
                            label="Main dominante"
                            :items="['Droitier', 'Gaucher', 'Ambidextre']"
                            v-model="hand"
                        ></v-select>

                        <v-text-field
                            variant="solo"
                            label="Activité"
                            v-model="activity"
                        ></v-text-field>

                        <v-text-field
                            variant="solo"
                            label="Métier / formation"
                            v-model="job"
                        ></v-text-field>

                        <v-select
                            variant="solo"
                            label="Comment nous avez vous connu ?"
                            :items="[
                                'Bouche à oreille',
                                'Instagram',
                                'Facebook',
                                'Par recherche Google',
                                'Compte CPF',
                                'Client groupe DARGENT',
                                'Salon',
                            ]"
                            v-model="howKnowUs"
                        ></v-select>

                        <v-file-input
                            :rules="config.rules.fileSize"
                            variant="solo"
                            label="Photo de profil"
                            prepend-icon="mdi-account-box-outline"
                            ref="fileInput"
                            accept=".png, .jpeg, .jpg"
                            @change="handleFileUpload"
                        ></v-file-input>

                        <v-img
                            v-if="profilPicture"
                            :src="profilPicture"
                            :width="100"
                            aspect-ratio="1/1"
                            alt="Photo de profil"
                        ></v-img>

                        <v-btn
                            v-if="profilPicture"
                            @click="profilPicture = null"
                        >Supprimer la photo de profil
                        </v-btn>
                    </v-window-item>

                    <v-window-item value="AUT">
                        <v-checkbox
                            variant="solo"
                            label="Droit à l'image"
                            v-model="imageAuthorization"
                        ></v-checkbox>

                        <v-checkbox
                            variant="solo"
                            label="Droit à l'image (main uniquement)"
                            v-model="handAuthorization"
                        ></v-checkbox>
                    </v-window-item>

                    <v-window-item value="ADR">
                        <v-text-field
                            type="number"
                            variant="solo"
                            label="Numéro de rue"
                            v-model="address.streetNumber"
                        ></v-text-field>

                        <v-text-field
                            variant="solo"
                            label="Rue"
                            v-model="address.streetName"
                        ></v-text-field>

                        <v-text-field
                            variant="solo"
                            label="Code postal"
                            v-model="address.postalCode"
                        ></v-text-field>

                        <v-text-field
                            variant="solo"
                            label="Ville"
                            v-model="address.city"
                        ></v-text-field>

                        <v-text-field
                            variant="solo"
                            label="Pays"
                            v-model="address.country"
                        ></v-text-field>
                    </v-window-item>

                    <v-window-item value="ADM" v-if="!isTrainer">
                        <v-select
                            variant="solo"
                            label="Status administratif"
                            :items="[
                                'Complet',
                                'Inscrit',
                                'Non-payé',
                                'Payé',
                                'Infos manquantes',
                                'À rappeler',
                            ]"
                            multiple
                            chips
                            v-model="administratifStatus"
                        ></v-select>
                        <v-select
                            variant="solo"
                            label="Réglement des frais de dossiers"
                            :items="['Chèque', 'Espèce']"
                            v-model="fileFees"
                        ></v-select>
                        <v-select
                            variant="solo"
                            label="Financement"
                            :items="['CPF', 'Dossier', 'Autofinancement']"
                            v-model="financing"
                        ></v-select>
                    </v-window-item>

                    <v-window-item value="OBS" v-if="!isProfile">
                        <v-textarea
                            variant="solo"
                            label="Observation"
                            v-model="comments"
                        ></v-textarea>

                        <v-textarea
                            variant="solo"
                            label="Handicap"
                            v-model="handicap"
                        ></v-textarea>
                    </v-window-item>

                    <v-window-item value="PWD" v-if="isProfile">
                        <v-text-field
                            variant="solo"
                            label="Mot de passe"
                            :type="showPassword ? 'text' : 'password'"
                            v-model="password"
                            :append-icon="
                                showPassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            @click:append="togglePasswordVisibility"
                        ></v-text-field>

                        <v-text-field
                            variant="solo"
                            label="Confirmation du mot de passe"
                            :type="showPassword ? 'text' : 'password'"
                            v-model="repeatPassword"
                        ></v-text-field>
                    </v-window-item>
                </v-window>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
import {config} from "@/config";

export default {
    name: "UserForm",
    inject: ["getUser", "createAlert", "checkAuthorization"],
    props: {
        isTrainer: Boolean,
        isProfile: Boolean,
        submit: String,
        defaultCivility: String,
        defaultFirstname: String,
        defaultLastname: String,
        defaultProfilId: Number,
        defaultEmail: String,
        defaultBirthday: String,
        defaultProfilPicture: String,
        defaultPhoneNumber: String,
        defaultHand: String,
        defaultActivity: String,
        defaultJob: String,
        defaultImageAuthorization: Boolean,
        defaultHandAuthorization: Boolean,
        defaultAddressStreetNumber: String,
        defaultAddressStreetName: String,
        defaultAddressPostalCode: String,
        defaultAddressCity: String,
        defaultAddressCountry: String,
        defaultFileFees: String,
        defaultFinancing: String,
        defaultHowKnowUs: String,
        defaultComments: String,
        defaultHandicap: String,
        defaultWorkshopId: Number,
        defaultAdministratifStatus: String,
    },
    data() {
        return {
            config,
            sendMail: false,
            showPassword: false,
            tab: null,
            civility: "",
            firstname: "",
            lastname: "",
            profilId: null,
            email: "",
            birthday: "",
            profilPicture: null,
            phoneNumber: "",
            hand: "",
            activity: "",
            job: "",
            imageAuthorization: "",
            handAuthorization: "",
            address: {
                streetNumber: "",
                streetName: "",
                postalCode: "",
                city: "",
                country: "",
            },
            password: "",
            repeatPassword: "",
            comments: "",
            handicap: "",
            workshopId: null,
            profils: [],
            workshops: [],
            fileFees: "",
            financing: "",
            howKnowUs: "",
            administratifStatus: null,
        };
    },
    watch: {
        defaultCivility: function (newVal) {
            this.civility = newVal ? newVal : "";
        },
        defaultFirstname: function (newVal) {
            this.firstname = newVal ? newVal : "";
        },
        defaultLastname: function (newVal) {
            this.lastname = newVal ? newVal : "";
        },
        defaultProfilId: function (newVal) {
            this.profilId = newVal ? newVal : 0;
        },
        defaultEmail: function (newVal) {
            this.email = newVal ? newVal : "";
        },
        defaultBirthday: function (newVal) {
            this.birthday = newVal ? this.formatDate(newVal) : "";
        },
        defaultProfilPicture: function (newVal) {
            this.profilPicture = newVal ? newVal : "";
        },
        defaultPhoneNumber: function (newVal) {
            this.phoneNumber = newVal ? newVal : "";
        },
        defaultHand: function (newVal) {
            this.hand = newVal ? newVal : "";
        },
        defaultActivity: function (newVal) {
            this.activity = newVal ? newVal : "";
        },
        defaultJob: function (newVal) {
            this.job = newVal ? newVal : "";
        },
        defaultImageAuthorization: function (newVal) {
            this.imageAuthorization = newVal ? newVal : false;
        },
        defaultHandAuthorization: function (newVal) {
            this.handAuthorization = newVal ? newVal : false;
        },
        defaultAddressStreetNumber: function (newVal) {
            this.address.streetNumber = newVal ? newVal : "";
        },
        defaultAddressStreetName: function (newVal) {
            this.address.streetName = newVal ? newVal : "";
        },
        defaultAddressPostalCode: function (newVal) {
            this.address.postalCode = newVal ? newVal : "";
        },
        defaultAddressCity: function (newVal) {
            this.address.city = newVal ? newVal : "";
        },
        defaultAddressCountry: function (newVal) {
            this.address.country = newVal ? newVal : "";
        },
        defaultFileFees: function (newVal) {
            this.fileFees = newVal ? newVal : "";
        },
        defaultFinancing: function (newVal) {
            this.financing = newVal ? newVal : "";
        },
        defaultHowKnowUs: function (newVal) {
            this.howKnowUs = newVal ? newVal : "";
        },
        defaultComments: function (newVal) {
            this.comments = newVal ? newVal : "";
        },
        defaultHandicap: function (newVal) {
            this.handicap = newVal ? newVal : "";
        },
        defaultWorkshopId: function (newVal) {
            this.workshopId = newVal ? newVal : 0;
        },
        defaultAdministratifStatus: function (newVal) {
            if (newVal) {
                this.administratifStatus = newVal.split(",");
            } else {
                this.administratifStatus = newVal;
            }
        },
    },
    methods: {
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        async handleFileUpload() {
            const fileInput = this.$refs.fileInput;
            const file = fileInput.files[0];

            if (file) {
                const base64Data = await this.readFileAsBase64(file);
                this.profilPicture = `data:${file.type};base64,${base64Data}`;
            }
        },
        async readFileAsBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result.split(",")[1]);
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        },
        formatDate(dateString) {
            const date = new Date(dateString);

            return date.toISOString().split("T")[0];
        },
        async submitForm() {
            if ((await this.$refs.form.validate()).valid) {
                let user = {
                    sendMail: this.sendMail,
                    civility: this.civility,
                    firstname: this.firstname,
                    lastname: this.lastname,
                    email: this.email,
                    birthday: this.birthday,
                    profilPicture: this.profilPicture,
                    phoneNumber: this.phoneNumber,
                    hand: this.hand,
                    activity: this.activity,
                    job: this.job,
                    imageAuthorization: this.imageAuthorization,
                    handAuthorization: this.handAuthorization,
                    address: {
                        streetNumber: this.address.streetNumber,
                        streetName: this.address.streetName,
                        postalCode: this.address.postalCode,
                        city: this.address.city,
                        country: this.address.country,
                    },
                };

                if (this.isProfile) {
                    user = {
                        ...user,
                        password: this.password,
                        repeatPassword: this.repeatPassword,
                    };
                } else {
                    if (this.administratifStatus !== null && this.administratifStatus !== "") {
                        this.administratifStatus = this.administratifStatus.toString();
                    }

                    user = {
                        ...user,
                        profilId: this.profilId,
                        comments: this.comments,
                        handicap: this.handicap,
                        fileFees: this.fileFees,
                        financing: this.financing,
                        howKnowUs: this.howKnowUs,
                        administratifStatus: this.administratifStatus,
                        workshopId: this.workshopId,
                    };
                }

                this.$emit("formSubmitted", user);
            }
        },
    },
    beforeMount() {
        if (this.isProfile) {
            return;
        }

        fetch(`${config.url}/team/user/informations`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.profils = data.profils;
                    this.workshops = data.workshops;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
