<template>
    <v-row>
        <v-col cols="12" md="12" lg="12" class="d-flex justify-end">
            <v-menu>
                <template v-slot:activator="{ props }">
                    <v-btn
                        color="#374840"
                        v-bind="props"
                    >
                        Actions
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item>
                        <v-list-item-title @click="removeOrCreateOvertime()">Sauvegarder les temps</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title @click="downloadSignatures()">Télécharger les présences</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-col>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="sortedStudents"
        :search="search"
        :items-per-page-text="'Éléments par page:'"
        :no-data-text="'Aucune donnée disponible'"
        :items-per-page-options="[10, 25, 50]"
    >
        <template v-slot:item="{ item }">
            <tr>
                <td>
                    <div v-if="!$vuetify.display.mobile" class="d-flex align-center">
                        <v-avatar color="grey-lighten-1">
                            <v-icon color="white">mdi-account</v-icon>
                        </v-avatar>

                        <div class="ml-2">
                            <p>{{ item.name }}</p>
                            <span :class="{'red--text': !item.scoreId}">
                                {{
                                    item.scoreId ? 'Formulaire de début de formation complété' : 'Formulaire de début de formation incomplet'
                                }}
                        </span>
                        </div>
                    </div>
                    <div v-else class="d-flex align-center">
                        <div class="d-flex align-center">
                            <v-avatar color="grey-lighten-1">
                                <v-icon color="white">mdi-account</v-icon>
                            </v-avatar>
                            <p class="ml-2">{{ item.name }}</p>
                        </div>
                        <v-icon :color="!item.scoreId ? 'red' : 'black'" class="ml-2">mdi-file-question-outline</v-icon>
                    </div>
                </td>
                <td>
                    <v-btn v-if="!item.signatureIdStart"
                           :to="'/team/calendar/' + eventId + '/signature/' + item.userId + '/start'"
                           icon="mdi-fountain-pen-tip"
                           variant="text"
                    ></v-btn>
                    <div v-else>
                        <v-btn
                            icon="mdi-delete"
                            variant="text"
                            color="red"
                            @click="removeSignature(item.signatureIdStart, true)"
                        ></v-btn>
                        <v-btn
                            icon="mdi-check-bold"
                            variant="text"
                            color="green"
                        ></v-btn>
                    </div>
                </td>
                <td>
                    <v-text-field
                        :class="!$vuetify.display.mobile ? 'w-50' : 'w-100'"
                        variant="solo"
                        label="Temps supplémentaires"
                        v-model="item.overtime"
                        hide-details
                        :rules="[...config.rules.isANumber]"
                    ></v-text-field>
                </td>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
import {config} from "@/config";

export default {
    name: "EventUser",
    inject: ["getUser", "createAlert"],
    props: {
        eventId: String,
    },
    data() {
        return {
            config,
            search: '',
            headers: [
                {align: 'start', key: 'name', title: 'Apprenant'},
                {key: 'start', title: 'Début', sortable: false},
                {key: 'end', title: 'Temps supplémentaires', sortable: false},
            ],
            students: []
        };
    },
    methods: {
        removeOrCreateOvertime() {
            fetch(`${config.url}/team/overtime/event/${this.eventId}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "POST",
                body: JSON.stringify({datas: this.students}),
            })
                .then(async (res) => {
                    if (res.status === 201) {
                        this.createAlert([
                            {
                                type: "success",
                                title: "Temps enregistrés",
                            },
                        ]);
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
        downloadSignatures() {
            fetch(`${config.url}/team/signature/event/${this.eventId}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "GET",
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        const pdfBlob = await res.blob();
                        const pdfUrl = URL.createObjectURL(pdfBlob);

                        const downloadLink = document.createElement("a");
                        downloadLink.href = pdfUrl;
                        downloadLink.download = "LAD-signatures.pdf";
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);

                        this.createAlert([
                            {
                                type: "success",
                                title: "Le document a été téléchargé.",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
        removeSignature(signatureId) {
            fetch(`${config.url}/team/signature/event/${this.eventId}/signature/${signatureId}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "DELETE",
            })
                .then((res) => {
                    if (res.status === 204) {
                        this.students.forEach(student => {
                            if (student.signatureIdStart === signatureId) {
                                student.signatureIdStart = null;
                            }
                        });

                        this.students = this.sortedStudents;

                        this.createAlert([
                            {
                                type: "success",
                                title: "Signature supprimée.",
                            },
                        ]);
                    } else {
                        this.createAlert([
                            {
                                type: "error",
                                title: "Une erreur est survenue.",
                            },
                        ]);
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        }
    },
    computed: {
        sortedStudents() {
            return this.students.slice().sort((a, b) => {
                const aHasSignature = Boolean(a.signatureIdStart);
                const bHasSignature = Boolean(b.signatureIdStart);

                if (aHasSignature && !bHasSignature) return 1;
                if (!aHasSignature && bHasSignature) return -1;

                return 0;
            });
        }
    },
    beforeMount() {
        fetch(`${config.url}/team/event/${this.eventId}/user-information`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.students = data;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>

<style scoped>
.red--text {
    color: red !important;
}
</style>
