<template>
    <v-dialog v-model="dialog.visible" width="500">
        <template v-slot:default>
            <v-card>
                <v-card-item>
                    <v-card-title>{{ dialog.title }}</v-card-title>

                    <v-card-subtitle>
                        <span>{{ dialog.start }} - {{ dialog.end }}</span>
                    </v-card-subtitle>
                </v-card-item>

                <v-card-text>
                    <div class="text-subtitle-1">
                        {{ dialog.teamUser }}
                    </div>

                    <div>{{ dialog.eventType }} - {{ dialog.formation }}</div>
                </v-card-text>

                <v-divider class="mx-4 mb-1"></v-divider>

                <v-card-title
                    v-if="
                        dialog.selectedStudentUsers &&
                        dialog.selectedStudentUsers.length
                    "
                >Apprenants
                </v-card-title
                >

                <div
                    v-if="
                        dialog.selectedStudentUsers &&
                        dialog.selectedStudentUsers.length
                    "
                    class="px-4"
                >
                    <v-chip-group>
                        <v-chip
                            v-for="(item, key) in dialog.selectedStudentUsers"
                            :key="key"
                            @click="goToUser(item.split(';')[1], dialog.eventTypeId, dialog.formationId)"
                        >{{ item.split(';')[0] }}
                        </v-chip
                        >
                    </v-chip-group>
                </div>

                <v-card-actions class="justify-end">
                    <v-btn
                        v-if="dialog.profil === 'team'"
                        text
                        color="red"
                        @click="deleteEvent"
                    >Supprimer
                    </v-btn>
                    <v-btn
                        v-if="dialog.profil !== 'student'"
                        :to="'/team/calendar/' + dialog.id + '/edit'">
                        {{ dialog.profil === "team" ? "Modifier" : "Afficher" }}
                    </v-btn>
                    <v-btn text @click="closeDialog">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
import {config} from "@/config";

export default {
    name: "CalendarDialog",
    inject: ["createAlert"],
    props: {
        defaultDialog: Object,
    },
    data() {
        return {
            config,
            dialog: this.defaultDialog,
        };
    },
    methods: {
        goToUser(studentId, eventTypeId, formationId) {
            if (!formationId) {
                window.open("/team/users/" + studentId + "/training/event-type/" + eventTypeId);
            } else {
                window.open("/team/users/" + studentId + "/training/formation/" + formationId);
            }
        },
        closeDialog() {
            this.$emit("close", false);
        },
        deleteEvent() {
            this.$emit("destroy", this.dialog.id);
        },
    },
};
</script>
