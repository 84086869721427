<template>
    <v-form @submit.prevent="submitForm" ref="form">
        <v-row>
            <v-col cols="12" md="12" lg="6">
                <v-btn class="mt-5" color="#374840" @click="this.$router.go(-1)">
                    Retour
                </v-btn>
                <v-btn class="mt-5" type="submit">{{ submit }}</v-btn>
            </v-col>
            <v-col cols="12" md="12" lg="6" class="d-flex justify-end">
                <p class="mt-5">Durée de la formation : {{ getDurations }} heure(s)</p>
            </v-col>
        </v-row>

        <v-card class="mt-5">
            <v-tabs
                v-model="tab"
                color="#374840"
                show-arrows
            >
                <v-tab value="MAIN">Informations principales</v-tab>
                <v-tab v-if="selectedSkills.length > 0" value="SKILLS">
                    Compétences
                </v-tab>
            </v-tabs>

            <v-card-text>
                <v-window v-model="tab">
                    <v-window-item value="MAIN">
                        <v-select
                            variant="solo"
                            v-model="selectedCursus"
                            :items="cursus"
                            item-title="name"
                            item-value="id"
                            label="Choix des parcours"
                            multiple
                            chips
                        ></v-select>

                        <v-text-field
                            variant="solo"
                            label="Nom de la formation"
                            v-model="name"
                            :rules="config.rules.required"
                        ></v-text-field>

                        <v-text-field
                            v-model="backgroundColor"
                            variant="solo"
                            label="Couleur"
                            type="color"
                        ></v-text-field>

                        <v-text-field
                            v-model="url"
                            variant="solo"
                            label="Lien vers le site"
                        ></v-text-field>

                        <v-select
                            variant="solo"
                            v-model="selectedSkills"
                            :items="skills"
                            item-title="name"
                            item-value="id"
                            label="Choix des compétences"
                            multiple
                            chips
                            :rules="config.rules.required"
                        ></v-select>
                    </v-window-item>

                    <v-window-item
                        v-if="selectedSkills.length > 0"
                        value="SKILLS"
                    >
                        <v-card class="mt-5">
                            <v-tabs
                                v-model="tabSkill"
                                color="#374840"
                                show-arrows
                            >
                                <v-tab
                                    v-for="(skill, key) in selectedSkills"
                                    :key="key"
                                    :value="key"
                                >{{ getSkillRef(skill) }}
                                </v-tab
                                >
                            </v-tabs>

                            <v-card-text>
                                <v-window v-model="tabSkill">
                                    <v-window-item
                                        v-for="(skill, key) in finalSkills"
                                        :key="key"
                                        :value="key"
                                    >
                                        <h3>
                                            {{ getSkillName(skill.skillId) }}
                                        </h3>

                                        <v-text-field
                                            variant="solo"
                                            label="Description"
                                            v-model="skill.description"
                                            :rules="config.rules.required"
                                        ></v-text-field>
                                        <v-text-field
                                            variant="solo"
                                            label="Durée"
                                            v-model="skill.duration"
                                            :rules="[
                                                ...config.rules.required,
                                                ...config.rules.isANumber,
                                            ]"
                                        ></v-text-field>
                                    </v-window-item>
                                </v-window>
                            </v-card-text>
                        </v-card>
                    </v-window-item>
                </v-window>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
import {config} from "@/config";

export default {
    name: "FormationForm",
    inject: ["getUser", "createAlert"],
    props: {
        submit: String,
        defaultName: String,
        defaultSelectedCursus: Array,
        defaultSelectedSkills: Array,
        defaultFinalSkills: Array,
        defaultBackgroundColor: String,
        defaultUrl: String,
    },
    data() {
        return {
            config,
            tab: null,
            tabSkill: null,
            name: "",
            cursus: [],
            selectedCursus: [],
            skills: [],
            finalSkills: [],
            selectedSkills: [],
            backgroundColor: "",
            url: "",
        };
    },
    watch: {
        defaultName: function (newVal) {
            this.name = newVal ? newVal : "";
        },
        defaultSelectedCursus: function (newVal) {
            this.selectedCursus = Array.isArray(newVal) ? newVal : [];
        },
        defaultSelectedSkills: function (newVal) {
            this.selectedSkills = Array.isArray(newVal) ? newVal : [];
        },
        defaultFinalSkills: function (newVal) {
            this.finalSkills = Array.isArray(newVal) ? newVal : [];
        },
        defaultBackgroundColor: function (newVal) {
            this.backgroundColor = newVal ? newVal : "";
        },
        defaultUrl: function (newVal) {
            this.url = newVal ? newVal : "";
        },
        selectedSkills: function (newSelectedSkills, oldSelectedSkills) {
            const removedSkills = oldSelectedSkills.filter(
                (id) => !newSelectedSkills.includes(id)
            );

            const addedSkills = newSelectedSkills.filter(
                (id) => !oldSelectedSkills.includes(id)
            );

            addedSkills.forEach((id) => {
                if (!this.finalSkills.some((item) => item.skillId === id)) {
                    this.finalSkills.push({
                        skillId: id,
                        duration: null,
                        description: null,
                    });
                }
            });

            removedSkills.forEach((id) => {
                if (this.finalSkills.some((item) => item.skillId === id)) {
                    this.finalSkills = this.finalSkills.filter(
                        (item) => item.skillId !== id
                    );
                }
            });
        },
    },
    computed: {
        getDurations() {
            const skillsCopy = this.finalSkills.map(skill => ({...skill}));

            skillsCopy.forEach((e) => {
                if (typeof e.duration !== "number" && e.duration != null) {
                    e.duration = e.duration.replace(",", ".");
                    e.duration = parseFloat(e.duration);
                }
            });

            const totalDuration = skillsCopy.reduce((total, skill) => total + skill.duration, 0);

            return parseFloat(totalDuration.toFixed(2));
        }
    },
    methods: {
        getSkillRef(skillId) {
            const skill = this.skills.find((f) => f.id === skillId);
            return skill ? skill.ref : `Skill inconnue (${skillId})`;
        },
        getSkillName(skillId) {
            const skill = this.skills.find((f) => f.id === skillId);
            return skill
                ? skill.ref + " " + skill.name
                : `Skill inconnue (${skillId})`;
        },
        async submitForm() {
            if ((await this.$refs.form.validate()).valid) {
                let error = false;
                this.finalSkills.forEach((e) => {
                    if (typeof e.duration !== "number" && e.duration != null) {
                        e.duration = e.duration.replace(",", ".");
                        e.duration = parseFloat(e.duration);
                    }

                    if (isNaN(e.duration)) {
                        error = true;
                    }
                });

                if (error) {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Les durées entrées ne sont pas conformes.",
                        },
                    ]);

                    return;
                }

                this.$emit("formSubmitted", {
                    name: this.name,
                    backgroundColor: this.backgroundColor,
                    url: this.url,
                    cursus: this.selectedCursus,
                    skills: this.finalSkills,
                });
            }
        },
    },
    beforeMount() {
        fetch(`${config.url}/team/cursus`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.cursus = data;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });

        fetch(`${config.url}/team/skill`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.skills = data;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
