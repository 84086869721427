<template>
    <SideBar :profil="'team'"/>

    <v-container fluid>
        <h2>Résultats des questionnaires</h2>

        <v-form @submit.prevent="submit" ref="form">
            <v-row class="mt-5">
                <v-col cols="12" md="12" lg="3">
                    <v-text-field
                        variant="solo"
                        label="Date de début"
                        type="date"
                        v-model="startDate"
                        @change="setDate(true)"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="12" lg="3">
                    <v-text-field
                        variant="solo"
                        label="Date de fin"
                        type="date"
                        v-model="endDate"
                        @change="setDate(false)"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="12" lg="4">
                    <v-combobox
                        variant="solo"
                        v-model="selectedFormation"
                        :items="formations"
                        item-title="name"
                        item-value="id"
                        label="Formation"
                    ></v-combobox>
                </v-col>

                <v-col cols="12" md="12" lg="2">
                    <v-btn class="mt-5" type="submit">Valider</v-btn>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12" md="12" lg="12">
                    <v-card>
                        <v-tabs
                            v-model="tab"
                            color="#374840"
                            show-arrows
                        >
                            <v-tab value="ALL">Général</v-tab>
                            <v-tab value="USR">Individuel</v-tab>
                        </v-tabs>

                        <v-card-text>
                            <v-window v-model="tab">
                                <v-window-item value="ALL">
                                    <v-card class="mt-5" v-if="all">
                                        <v-tabs
                                            v-model="tabGlobal"
                                            color="#374840"
                                            show-arrows
                                        >
                                            <v-tab value="ORG">L'organisation</v-tab>
                                            <v-tab value="FORMATION">La formation</v-tab>
                                            <v-tab value="FORMATEUR">Le formateur</v-tab>
                                            <v-tab value="GLOB">Appréciation générale</v-tab>
                                        </v-tabs>

                                        <v-card-text>
                                            <v-window v-model="tabGlobal">
                                                <v-window-item value="ORG">
                                                    <v-row>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>Mon accès aux informations avant le stage
                                                                (programmes, objectifs, ...)</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="all.informationAccess"
                                                                :min="0"
                                                                :max="5"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>La qualité de l'accueil</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="all.workQuality"
                                                                :min="0"
                                                                :max="5"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                    </v-row>
                                                </v-window-item>

                                                <v-window-item value="FORMATION">
                                                    <v-row>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>Les supports d'apprentissage sont clairs</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="all.learningSupport"
                                                                :min="0"
                                                                :max="5"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>La formation a répondu à mes objectifs</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="all.trainingTarget"
                                                                :min="0"
                                                                :max="5"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>Je vais mettre en application les éléments
                                                                appris</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="all.practiceLearning"
                                                                :min="0"
                                                                :max="5"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                    </v-row>
                                                </v-window-item>

                                                <v-window-item value="FORMATEUR">
                                                    <v-row>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>Sa capacité d'écoute</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="all.learningCapacity"
                                                                :min="0"
                                                                :max="5"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>Sa connaissance du sujet</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="all.subjectKnowledge"
                                                                :min="0"
                                                                :max="5"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>Le respect du programme</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="all.programRespect"
                                                                :min="0"
                                                                :max="5"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>Son approche pédagogique</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="all.pedagogicalApproach"
                                                                :min="0"
                                                                :max="5"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                    </v-row>
                                                </v-window-item>

                                                <v-window-item value="GLOB">
                                                    <v-row>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>L'utilité de la formation</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="all.trainingUsability"
                                                                :min="0"
                                                                :max="5"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>Satisfaction flobale</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="all.satisfactionGlobal"
                                                                :min="0"
                                                                :max="5"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col cols="12" md="12" lg="12">
                                                            <p>{{ all.trainingRecommandation }}</p>
                                                            <label>Sur une échelle de 0 à 10, recommanderiez-vous cette
                                                                formation ?</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="all.trainingRecommandation"
                                                                :min="0"
                                                                :max="10"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                    </v-row>
                                                </v-window-item>
                                            </v-window>
                                        </v-card-text>
                                    </v-card>
                                </v-window-item>

                                <v-window-item value="USR">
                                    <v-select
                                        variant="solo"
                                        v-model="selectedStudent"
                                        :items="students"
                                        item-title="name"
                                        item-value="index"
                                        label="Apprenant"
                                    ></v-select>

                                    <v-card class="mt-2" v-if="selectedStudent">
                                        <p class="text-right">Dernière modification : {{ getStudentQuiz.lastEdit }}</p>

                                        <v-tabs
                                            class="mt-5"
                                            v-model="tabStudent"
                                            color="#374840"
                                            show-arrows
                                        >
                                            <v-tab value="ORG">L'organisation</v-tab>
                                            <v-tab value="FORMATION">La formation</v-tab>
                                            <v-tab value="FORMATEUR">Le formateur</v-tab>
                                            <v-tab value="GLOB">Appréciation générale</v-tab>
                                        </v-tabs>

                                        <v-card-text>
                                            <v-window v-model="tabStudent">
                                                <v-window-item value="ORG">
                                                    <v-row>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>Mon accès aux informations avant le stage
                                                                (programmes, objectifs, ...)</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="getStudentQuiz.informationAccess"
                                                                :min="0"
                                                                :max="5"
                                                                :step="1"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>La qualité de l'accueil</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="getStudentQuiz.workQuality"
                                                                :min="0"
                                                                :max="5"
                                                                :step="1"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col cols="12" md="12" lg="12">
                                                            <v-textarea
                                                                variant="solo"
                                                                label="Vos commentaires"
                                                                counter
                                                                rows="2"
                                                                clearable
                                                                v-model="getStudentQuiz.opinionOrganisation"
                                                                :readonly="true"
                                                            ></v-textarea>
                                                        </v-col>
                                                    </v-row>
                                                </v-window-item>

                                                <v-window-item value="FORMATION">
                                                    <v-row>
                                                        <v-select
                                                            variant="solo"
                                                            v-model="getStudentQuiz.hourQuantity"
                                                            label="Le volume horraire de la formation est "
                                                            :readonly="true"
                                                            :items="['Trop court', 'Adapté', 'Trop long']"
                                                        ></v-select>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>Les supports d'apprentissage sont clairs</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="getStudentQuiz.learningSupport"
                                                                :min="0"
                                                                :max="5"
                                                                :step="1"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>La formation a répondu à mes objectifs</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="getStudentQuiz.trainingTarget"
                                                                :min="0"
                                                                :max="5"
                                                                :step="1"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>Je vais mettre en application les éléments
                                                                appris</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="getStudentQuiz.practiceLearning"
                                                                :min="0"
                                                                :max="5"
                                                                :step="1"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="12" md="12" lg="12">
                                                            <v-textarea
                                                                variant="solo"
                                                                label="Vos commentaires"
                                                                counter
                                                                rows="2"
                                                                clearable
                                                                v-model="getStudentQuiz.opinionTraining"
                                                                :readonly="true"
                                                            ></v-textarea>
                                                        </v-col>
                                                    </v-row>
                                                </v-window-item>

                                                <v-window-item value="FORMATEUR">
                                                    <v-row>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>Sa capacité d'écoute</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="getStudentQuiz.learningCapacity"
                                                                :min="0"
                                                                :max="5"
                                                                :step="1"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>Sa connaissance du sujet</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="getStudentQuiz.subjectKnowledge"
                                                                :min="0"
                                                                :max="5"
                                                                :step="1"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>Le respect du programme</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="getStudentQuiz.programRespect"
                                                                :min="0"
                                                                :max="5"
                                                                :step="1"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>Son approche pédagogique</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="getStudentQuiz.pedagogicalApproach"
                                                                :min="0"
                                                                :max="5"
                                                                :step="1"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col cols="12" md="12" lg="12">
                                                            <v-textarea
                                                                variant="solo"
                                                                label="Vos commentaires"
                                                                counter
                                                                rows="2"
                                                                clearable
                                                                v-model="getStudentQuiz.opinionLearner"
                                                                :readonly="true"
                                                            ></v-textarea>
                                                        </v-col>
                                                    </v-row>
                                                </v-window-item>

                                                <v-window-item value="GLOB">
                                                    <v-row>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>L'utilité de la formation</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="getStudentQuiz.trainingUsability"
                                                                :min="0"
                                                                :max="5"
                                                                :step="1"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                        <v-col cols="12" md="12" lg="6">
                                                            <label>Satisfaction flobale</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="getStudentQuiz.satisfactionGlobal"
                                                                :min="0"
                                                                :max="5"
                                                                :step="1"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col cols="12" md="12" lg="12">
                                                            <label>Sur une échelle de 0 à 10, recommanderiez-vous cette
                                                                formation ?</label>
                                                            <v-slider
                                                                class="pl-1"
                                                                v-model="getStudentQuiz.trainingRecommandation"
                                                                :min="0"
                                                                :max="10"
                                                                :step="1"
                                                                thumb-label
                                                                :ticks="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                                                                show-ticks="always"
                                                                tick-size="4"
                                                                :readonly="true"
                                                            ></v-slider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="12" md="12" lg="12">
                                                            <v-textarea
                                                                variant="solo"
                                                                label="Quelles améliorations pouvons- nous apporter à cette formation ?"
                                                                v-model="getStudentQuiz.opinionGlobal"
                                                                counter
                                                                rows="2"
                                                                clearable
                                                                :readonly="true"
                                                            ></v-textarea>
                                                        </v-col>
                                                    </v-row>
                                                </v-window-item>
                                            </v-window>
                                        </v-card-text>
                                    </v-card>
                                </v-window-item>
                            </v-window>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import {config} from "@/config";
import SideBar from "@/components/SideBar.vue";

export default {
    name: "TeamQuiz",
    inject: ["getUser", "createAlert", "checkAuthorization"],
    components: {
        SideBar,
    },
    data() {
        return {
            config,
            tab: null,
            tabStudent: null,
            tabGlobal: null,
            startDate: null,
            endDate: null,
            studentsQuiz: [],
            all: null,
            formations: [],
            selectedFormation: null,
            students: [],
            selectedStudent: null,
        };
    },
    computed: {
        getStudentQuiz() {
            return this.studentsQuiz[this.selectedStudent - 1];
        }
    },
    methods: {
        setDate(isStart) {
            if (isStart && (!this.endDate || this.endDate < this.startDate)) {
                this.endDate = this.startDate;
            }
        },
        async submit() {
            if ((await this.$refs.form.validate()).valid) {
                this.selectedStudent = null;
                const formation = this.selectedFormation?.id !== undefined ? this.selectedFormation.id : this.selectedFormation;
                this.getQuizzes(`${config.url}/team/quizzes?startDate=${this.startDate}&endDate=${this.endDate}&formationId=${formation}`);
            }
        },
        getQuizzes(search) {
            fetch(search, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "GET",
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        return res.json();
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .then((data) => {
                    if (data) {
                        this.studentsQuiz = data.quizzes;
                        this.students = data.students;
                        this.all = data.global;
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        }
    },
    beforeMount() {
        fetch(`${config.url}/team/quizzes/informations`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.formations = data;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });

        const date = new Date();
        const year = date.getFullYear();

        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        this.endDate = `${year}-${month}-${day}`;

        const startMonth = date.getMonth() + 1;
        const startDay = date.getDate();

        let startYear;
        if (startMonth < 9 || (startMonth === 9 && startDay < 1)) {
            startYear = year - 1;
        } else {
            startYear = year;
        }

        this.startDate = `${startYear}-09-01`;

        this.getQuizzes(`${config.url}/team/quizzes?startDate=${this.startDate}&endDate=${this.endDate}`);
    },
};
</script>
