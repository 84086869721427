import {isAuthenticated, isNotAuthenticated} from "@/router/middlewares";

import adminRoutes from "./admin.routes";
import teamRoutes from "./team.routes";
import studentRoutes from "./student.routes";

import HomePage from "@/views/Home";

import ForgotPassword from "@/views/auth/ForgotPassword";
import ResetPassword from "@/views/auth/ResetPassword";
import Login from "@/views/auth/Login";

import PageNotFound from "@/views/PageNotFound";

import UserProfile from "@/views/Profile";

const baseRoutes = [
    {path: "/", component: HomePage},
    {path: "/auth/login", component: Login, beforeEnter: isNotAuthenticated},
    {
        path: "/auth/forgot-password",
        component: ForgotPassword,
        beforeEnter: isNotAuthenticated,
    },
    {
        path: "/auth/reset-password",
        component: ResetPassword,
        beforeEnter: isNotAuthenticated,
    },
    {
        path: "/profile",
        component: UserProfile,
        beforeEnter: isAuthenticated,
    },
];

const routes = [
    ...baseRoutes,
    ...adminRoutes,
    ...teamRoutes,
    ...studentRoutes,
    {path: "/:pathMatch(.*)*", component: PageNotFound},
];

export default routes;
