<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <h2>Création d'un document</h2>

            <DocumentForm :submit="'Valider'" @formSubmitted="createDocument"/>
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import DocumentForm from "@/components/team/DocumentForm";
import {config} from "@/config";

export default {
    name: "TeamDocAdd",
    inject: ["getUser", "createAlert"],
    components: {
        SideBar,
        DocumentForm,
    },
    methods: {
        createDocument: function (form) {
            let body = {
                name: form.name,
                selectedMail: form.selectedMails,
                existingFile: form.existingFile,
            };

            if (form.file) {
                const fileReader = new FileReader();

                fileReader.onload = (event) => {
                    const fileBuffer = event.target.result;
                    body.file = Array.from(new Uint8Array(fileBuffer));

                    this.sendFetchRequest(body);
                };

                fileReader.readAsArrayBuffer(form.file[0]);
            } else {
                body.content = form.content;
                this.sendFetchRequest(body);
            }
        },
        sendFetchRequest: function (body) {
            fetch(`${config.url}/team/docs`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "POST",
                body: JSON.stringify(body),
            })
                .then(async (res) => {
                    if (res.status === 201) {
                        this.$router.push("/team/docs");

                        this.createAlert([
                            {
                                type: "success",
                                title: "Document créé",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        }
    },
};
</script>
