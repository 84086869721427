<template>
    <div>
        <SideBar :profil="'student'"/>

        <v-container fluid>
            <h2>Formations</h2>

            <!-- Section pour les formations en cours -->
            <v-row align="start" justify="center" v-if="formationsEnCours.length">
                <v-col cols="12" lg="6" md="6" sm="6">
                    <v-card class="mx-auto">
                        <v-card-title>Formations en cours ou terminées</v-card-title>
                        <v-card-text>
                            <v-list>
                                <v-list-item
                                    v-for="(formation, index) in formationsEnCours"
                                    :key="index"
                                    @click="getCursusLink(formation)"
                                >
                                    <template v-slot:append>
                                        <v-icon
                                            color="orange"
                                            icon="mdi-license"
                                        ></v-icon>
                                    </template>
                                    <v-list-item-title v-text="formation.name"></v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <!-- Section pour toutes les formations -->
            <v-row align="start" justify="center">
                <v-col
                    v-for="(item, index) in cursusFormations"
                    :key="index"
                    cols="12"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <v-card class="mx-auto">
                        <v-card-title>{{ item.name }}</v-card-title>

                        <v-card-actions>
                            <v-btn
                                color="#374840"
                                variant="text"
                                @click="showDetails(item)"
                            >Voir les formations
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn
                                :icon="
                                    selectedItem && selectedItem.id === item.id
                                        ? 'mdi-chevron-up'
                                        : 'mdi-chevron-down'
                                "
                                @click="showDetails(item)"
                            ></v-btn>
                        </v-card-actions>

                        <v-expand-transition>
                            <div
                                v-show="
                                    selectedItem && selectedItem.id === item.id
                                "
                            >
                                <v-divider></v-divider>

                                <v-card-text>
                                    <v-list>
                                        <v-list-item
                                            v-for="(formation, formationIndex) in item.formations"
                                            :key="formationIndex"
                                            @click="getCursusLink(formation)"
                                        >
                                            <template v-slot:append>
                                                <v-icon
                                                    v-if="formationIsDone(formation)"
                                                    color="orange"
                                                    icon="mdi-license"
                                                ></v-icon>
                                            </template>
                                            <v-list-item-title
                                                :style="{
                                                    color: formationIsDone(
                                                        formation
                                                    )
                                                        ? 'black'
                                                        : 'grey',
                                                }"
                                                v-text="formation.name"
                                            ></v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-card-text>
                            </div>
                        </v-expand-transition>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import {config} from "@/config";

export default {
    name: "StudentFormations",
    inject: ["getUser", "createAlert"],
    components: {
        SideBar,
    },
    data() {
        return {
            cursusFormations: [],
            cursusFormationsDone: [],
            selectedItem: null,
        };
    },
    computed: {
        formationsEnCours() {
            // Filtrer les formations qui sont en cours
            return this.cursusFormations
                .flatMap(item => item.formations)
                .filter(formation => this.formationIsDone(formation));
        }
    },
    methods: {
        showDetails(item) {
            this.selectedItem = this.selectedItem === item ? null : item;
        },
        formationIsDone(item) {
            return this.cursusFormationsDone.some(cursus => {
                return cursus.formations.some(
                    formation =>
                        formation.id === item.id && formation.name === item.name
                );
            });
        },
        getCursusLink: function (formation) {
            if (this.formationIsDone(formation)) {
                window.open(
                    "/student/training/formation/" +
                    formation.id, "_self"
                );
            } else if (formation.url) {
                window.open(formation.url);
            }
        },
    },
    beforeMount() {
        fetch(`${config.url}/student/training/`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.cursusFormations = data.cursusFormations;
                    this.cursusFormationsDone = data.cursusFormationsDone;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
