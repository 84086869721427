<template>
    <div>
        <SideBar :profil="'student'"/>

        <v-container fluid>
            <h2>Formulaire de contact</h2>

            <v-form @submit.prevent="submitForm" ref="form">
                <v-btn class="mt-5" color="#374840" @click="this.$router.go(-1)"
                >Retour
                </v-btn
                >
                <v-btn class="mt-5" color="white" type="submit">Envoyer</v-btn>

                <v-text-field
                    class="mt-5"
                    v-model="mail.object"
                    label="Objet"
                    variant="solo"
                    maxlength="80"
                    :rules="config.rules.required"
                    required
                ></v-text-field>

                <v-textarea
                    variant="solo"
                    label="Description"
                    v-model="mail.content"
                    :rules="config.rules.required"
                ></v-textarea>

                <v-file-input
                    :rules="[
                        ...config.rules.totalFileSize,
                        ...config.rules.fileCount,
                    ]"
                    variant="solo"
                    show-size
                    counter
                    multiple
                    label="Joindre un fichier"
                    prepend-icon="mdi-file"
                    ref="fileInput"
                    accept=".pdf, .png, .jpeg, .jpg, .doc, .docx, .xls, .xlsx"
                ></v-file-input>
            </v-form>
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import {config} from "@/config";

export default {
    name: "StudentContact",
    inject: ["getUser", "createAlert"],
    components: {
        SideBar,
    },
    props: {
        submit: String,
    },
    data() {
        return {
            config,
            mail: {
                object: "",
                content: "",
                attachements: [null],
            },
        };
    },
    methods: {
        async getAttachementsFile() {
            const fileInput = this.$refs.fileInput;
            this.mail.attachements = await Promise.all(
                Array.from(fileInput.files).map(async (file) => {
                    const base64Data = await this.readFileAsBase64(file);
                    return {
                        fileName: file.name,
                        type: file.type,
                        size: file.size,
                        fileBuffer: base64Data,
                    };
                })
            );
        },

        async submitForm() {
            try {
                const result = await this.$refs.form.validate();

                if (result.valid && this.mail.content) {
                    await this.getAttachementsFile();

                    fetch(`${config.url}/student/contact`, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + this.getUser().token,
                        },
                        method: "POST",
                        body: JSON.stringify({
                            object: this.mail.object,
                            content: this.mail.content,
                            attachements: this.mail.attachements,
                        }),
                    })
                        .then(async (res) => {
                            if (res.status === 201) {
                                this.$router.push("/student/planning");

                                this.createAlert([
                                    {
                                        type: "success",
                                        title: "Le mail a bien été envoyé.",
                                    },
                                ]);
                            } else {
                                const errors = await res.json();
                                if (errors && errors.errors) {
                                    this.createAlert(
                                        errors.errors.map((error) => {
                                            return {
                                                type: "error",
                                                title: error.msg,
                                            };
                                        })
                                    );
                                }
                            }
                        })
                        .catch(() => {
                            this.createAlert([
                                {
                                    type: "error",
                                    title: "Une erreur est survenue.",
                                },
                            ]);
                        });
                }
            } catch (error) {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue pour l'attachement des documents.",
                    },
                ]);
            }
        },

        async readFileAsBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result.split(",")[1]);
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        },
    },
};
</script>
