<template>
    <v-app>
        <v-dialog v-model="openDialog" max-width="500px">
            <v-card>
                <v-card-title class="headline">Confirmer l'action</v-card-title>
                <v-card-text>{{ textDialog }}</v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="confirmAction">Confirmer</v-btn>
                    <v-btn @click="closeDialog">Annuler</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
export default {
    name: "ConfirmDialog",
    props: {
        open: Boolean,
        text: String,
    },
    data() {
        return {
            openDialog: false,
            textDialog: "",
        };
    },
    watch: {
        open: function (newVal) {
            this.openDialog = newVal ? newVal : false;
        },
        text: function (newVal) {
            this.textDialog = newVal ? newVal : "";
        },
    },
    methods: {
        confirmAction() {
            this.$emit("confirmDialog");
        },
        closeDialog() {
            this.$emit("closeDialog");
        },
    },
};
</script>
