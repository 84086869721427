<template>
    <div>
        <SideBar :profil="'admin'"/>

        <v-container fluid>
            <v-row class="mb-4 d-flex justify-end">
                <v-btn v-if="filter" @click="filter = !filter">
                    <v-icon>mdi-close-circle</v-icon>
                </v-btn>
                <v-btn
                    v-if="
                        filter &&
                        ( selectedStatus ||
                          selectedAdmin  ||
                          selectedTeam)
                    "
                    @click="removeFilter()"
                >
                    <v-icon>mdi-eraser</v-icon>
                </v-btn>
                <v-btn @click="toggleFilter">
                    <v-icon>{{ filter ? "mdi-filter-check" : "mdi-filter" }}</v-icon>
                </v-btn>
            </v-row>

            <v-row v-show="filter">
                <v-col cols="12" md="12" lg="4">
                    <v-select
                        variant="solo"
                        label="Status"
                        :items="[
                            'En attente retour interne',
                            'Fermé',
                            'Ouvert',
                            'Demande à compléter',
                            'En cours',
                        ]"
                        v-model="selectedStatus"
                    ></v-select>
                </v-col>

                <v-col cols="12" md="12" lg="4">
                    <v-combobox
                        variant="solo"
                        v-model="selectedAdmin"
                        :items="admins"
                        item-title="name"
                        item-value="id"
                        label="Propriétaire"
                        :filterable="true"
                    ></v-combobox>
                </v-col>

                <v-col cols="12" md="12" lg="4">
                    <v-combobox
                        variant="solo"
                        v-model="selectedTeam"
                        :items="teams"
                        item-title="name"
                        item-value="id"
                        label="Membre de l'équipe"
                        :filterable="true"
                    ></v-combobox>
                </v-col>
            </v-row>

            <v-card
                title="Liste des tickets"
                flat
            >
                <template v-slot:text>
                    <v-text-field
                        v-model="search"
                        label="Recherche"
                        prepend-inner-icon="mdi-magnify"
                        variant="solo"
                        hide-details
                        single-line
                    ></v-text-field>
                </template>

                <v-data-table
                    :headers="headers"
                    :items="tickets"
                    :search="search"
                    :items-per-page-text="'Éléments par page:'"
                    :no-data-text="'Aucune donnée disponible'"
                    multi-sort
                    :items-per-page-options="[10, 25, 50]"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.id }}</td>
                            <td>{{ item.subject }}</td>
                            <td :style="{ color: item.status === 'Ouvert' ? 'red' : item.status === 'Fermé' ? 'green' : '' }">
                                {{ item.status }}
                            </td>
                            <td>{{ item.typeTicket }}</td>
                            <td class="text-left">
                                <v-btn
                                    icon="mdi-pencil" size="large" variant="text"
                                    :to="'/admin/ticket/' + item.id + '/edit'"
                                ></v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-container>
    </div>
</template>
<script>
import SideBar from "@/components/SideBar";
import {config} from "@/config";

export default {
    name: "AdminTicketList",
    inject: ["getUser", "createAlert", "checkAuthorization"],
    components: {
        SideBar,
    },
    data() {
        return {
            confirm: {
                open: false,
                text: "",
                selected: "",
            },
            search: '',
            headers: [
                {
                    align: 'start', key: 'id', title: 'Id',
                },
                {key: 'subject', title: 'Sujet'},
                {key: 'status', title: 'Status'},
                {key: 'typeTicket', title: 'Type'},
                {key: 'actions', title: 'Actions', sortable: false},
            ],
            tickets: [],
            admins: [],
            teams: [],
            selectedAdmin: "",
            selectedTeam: "",
            selectedStatus: "",
            filter: false,
        }
    },
    methods: {
        removeFilter() {
            this.selectedStatus = [];
            this.selectedAdmin = [];
            this.selectedTeam = [];

            this.getTickets(`${config.url}/admin/ticket/`);
        },
        toggleFilter() {
            if (this.filter) {
                const admin = this.selectedAdmin && this.selectedAdmin.id !== undefined ? this.selectedAdmin.id : '';
                const team = this.selectedTeam && this.selectedTeam.id !== undefined ? this.selectedTeam.id : '';

                this.getTickets(`${config.url}/admin/ticket/?selectedStatus=${this.selectedStatus}&selectedAdmin=${admin}&selectedTeam=${team}`);
            }

            this.filter = !this.filter;
        },
        getTickets(search) {
            fetch(search, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "GET",
            })
                .then(async (res) => {
                    if (res.status === 403) {
                        this.$router.push("/404");
                    } else if (res.status === 200) {
                        return res.json();
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .then((data) => {
                    if (data) {
                        this.tickets = data.tickets;
                        this.teams = data.teams;
                        this.admins = data.admins;
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        }
    },
    beforeMount() {
        this.getTickets(`${config.url}/admin/ticket/`);
    },
};
</script>
