<template>
    <div>
        <SideBar :profil="'student'"/>

        <v-container fluid>
            <h2>Liste des tutoriels disponibles</h2>

            <v-row class="mt-2">
                <v-col cols="12" md="12" lg="12">
                    <v-card>
                        <v-list
                            class="d-flex flex-column"
                        >
                            <v-list-item
                                v-for="(tutorial, index) in tutorials"
                                :key="index"
                                :to="tutorial.url"
                                class="mb-2"
                            >
                                <template v-slot:prepend>
                                    <v-avatar color="#374840">
                                        <v-icon color="white">{{ tutorial.icon }}</v-icon>
                                    </v-avatar>
                                </template>
                                <v-list-item-content>
                                    <v-list-item-title>{{ tutorial.title }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ tutorial.subtitle }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <template v-slot:append>
                                    <v-btn
                                        color="grey-lighten-1"
                                        icon="mdi-cursor-default-click"
                                        variant="text"
                                    ></v-btn>
                                </template>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";

export default {
    name: "StudentTutorialHome",
    components: {
        SideBar,
    },
    data() {
        return {
            tutorials: [
                {
                    url: "/student/tutorial/quiz",
                    icon: "mdi-numeric-1",
                    title: "Répondre aux questionnaires de satisfaction",
                    subtitle: "Chaque formation dispose d'un questionnaire qui vous permet de l'évaluer",
                    formation: true
                },
                {
                    url: "/student/tutorial/score",
                    icon: "mdi-numeric-2",
                    title: "Notation par formation",
                    subtitle: "Chaque formation dispose de notation qui vous permette de vous évaluer",
                    formation: true
                },
            ]
        }
    },
};
</script>

<style scoped>
.v-list-item-title {
    white-space: normal !important;
}

.v-list-item-subtitle {
    -webkit-line-clamp: unset !important;
}
</style>
