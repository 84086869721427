<template>
    <SideBar :profil="'team'"/>

    <v-list>
        <v-list-item
            to="/profile"
            lines="two"
            :prepend-avatar="getUser().profilPicture"
            :title="getUser().name"
            :subtitle="getUser().profile"
        ></v-list-item>

        <v-divider></v-divider>

        <v-list-item v-for="link in filteredLinks(links)" :key="link.text" link>
            <v-list-item
                v-if="!link.name"
                :prepend-icon="link.icon"
                :title="link.text"
                :value="link.text"
                :to="link.to"
            ></v-list-item>

            <v-list-group v-else :value="link.name">
                <template v-slot:activator="{ props }">
                    <v-list-item
                        v-bind="props"
                        :prepend-icon="link.icon"
                        :title="link.name"
                    ></v-list-item>
                </template>

                <v-list-item
                    v-for="subLink in subfilteredLinks(link.links)"
                    :key="subLink.text"
                    :prepend-icon="subLink.icon"
                    :title="subLink.text"
                    :value="subLink.text"
                    :to="subLink.to"
                ></v-list-item>
            </v-list-group>
        </v-list-item>
    </v-list>
</template>

<script>
import SideBar from "@/components/SideBar.vue";

export default {
    name: "TeamLink",
    inject: ["getUser", "checkAuthorization"],
    components: {
        SideBar,
    },
    props: {
        profil: String,
    },
    data() {
        return {
            group: null,
            links: [
                {
                    name: "Dashboard",
                    profil: "team",
                    icon: "mdi-view-dashboard",
                    display: true,
                    links: [
                        {
                            text: "Statistiques",
                            to: "/team",
                            icon: "mdi-finance",
                            display: this.checkAuthorization("team:stat:read"),
                        },
                        {
                            text: "Questionnaires",
                            to: "/team/quiz",
                            icon: "mdi-help-box-multiple",
                            display: this.checkAuthorization("team:quiz:read"),
                        }
                    ]
                },
                {
                    profil: "team",
                    text: "Agenda",
                    to: "/team/calendar",
                    icon: "mdi-calendar",
                    display: this.checkAuthorization("team:event:read"),
                },
                {
                    profil: "team",
                    text: "Utilisateurs",
                    to: "/team/users",
                    icon: "mdi-account-group",
                    display: this.checkAuthorization("team:user:read"),
                },
                {
                    profil: "team",
                    text: "Ateliers",
                    to: "/team/workshop",
                    icon: "mdi-office-building",
                    display: this.checkAuthorization("team:workshop:read"),
                },
                {
                    name: "Apprentissage",
                    profil: "team",
                    icon: "mdi-school",
                    display: true,
                    links: [
                        {
                            text: "Parcours",
                            to: "/team/training/cursus",
                            icon: "mdi-school",
                            display:
                                this.checkAuthorization("team:cursus:read"),
                        },
                        {
                            text: "Formations",
                            to: "/team/training/formation",
                            icon: "mdi-school",
                            display: this.checkAuthorization(
                                "team:formation:read"
                            ),
                        },
                        {
                            text: "Compétences",
                            to: "/team/training/skill",
                            icon: "mdi-school",
                            display: this.checkAuthorization("team:skill:read"),
                        },
                    ],
                },
                {
                    name: "Paramètrage",
                    profil: "team",
                    icon: "mdi-cog",
                    display: true,
                    links: [
                        {
                            text: "Mails",
                            to: "/team/mails/",
                            icon: "mdi-mail",
                            display: this.checkAuthorization("team:mail:read"),
                        },
                        {
                            text: "Documents",
                            to: "/team/docs/",
                            icon: "mdi-file",
                            display: this.checkAuthorization("team:doc:read"),
                        },
                        {
                            text: "Permissions",
                            to: "/team/permission",
                            icon: "mdi-lock",
                            display: this.checkAuthorization(
                                "team:permission:read"
                            ),
                        },
                        {
                            text: "Variables",
                            to: "/team/varsys/",
                            icon: "mdi-variable",
                            display:
                                this.checkAuthorization("team:varsys:read"),
                        },
                    ],
                },
            ],
        };
    },
    methods: {
        filteredLinks() {
            return this.links.filter((link) => {
                if (link.links && link.links.length > 0) {
                    return link.links.some((subLink) => subLink.display);
                } else {
                    return link.display;
                }
            });
        },
        subfilteredLinks(links) {
            return links.filter((link) => link.display);
        },
    },
};
</script>

<style scoped>
.right-border {
    border-right: 1px solid #ccc;
}
</style>
