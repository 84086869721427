<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <h2>{{ checkAuthorization("team:user:update") ? "Modification" : "Suivi" }} d'un utilisateur</h2>

            <v-row v-if="checkAuthorization('team:user:update')">
                <v-col cols="12" md="12" lg="6" class="d-flex align-center text-start">
                    <v-btn color="red" v-if="active" @click="openDialog()">
                        Désactiver
                    </v-btn>

                    <v-btn color="green" v-else @click="activeUser(true)">
                        Activer
                    </v-btn>
                </v-col>

                <v-col cols="12" md="12" lg="6" class="d-flex justify-end">
                    <v-btn color="#374840" @click="resetPassword()">
                        Mail modification mot de passe
                    </v-btn>
                </v-col>
            </v-row>

            <UserForm
                :submit="'Enregistrer'"
                :isProfile="false"
                :defaultProfilId="profilId"
                :defaultCivility="civility"
                :defaultFirstname="firstname"
                :defaultLastname="lastname"
                :defaultBirthday="birthday"
                :defaultPhoneNumber="phoneNumber"
                :defaultEmail="email"
                :defaultProfilPicture="profilPicture"
                :defaultHand="hand"
                :defaultActivity="activity"
                :defaultJob="job"
                :defaultImageAuthorization="!!imageAuthorization"
                :defaultHandAuthorization="!!handAuthorization"
                :defaultAddressStreetNumber="address.streetNumber"
                :defaultAddressStreetName="address.streetName"
                :defaultAddressPostalCode="address.postalCode"
                :defaultAddressCity="address.city"
                :defaultAddressCountry="address.country"
                :defaultAdministratifStatus="administratifStatus"
                :defaultFileFees="fileFees"
                :defaultFinancing="financing"
                :defaultHowKnowUs="howKnowUs"
                :defaultComments="comments"
                :defaultHandicap="handicap"
                :defaultWorkshopId="workshopId"
                :isTrainer="isTrainer"
                @formSubmitted="updateUser"
            />

            <Confirm
                v-show="confirm.open"
                :open="confirm.open"
                :text="confirm.text"
                @confirmDialog="activeUser(false)"
                @closeDialog="closeDialog()"
            />
        </v-container>
    </div>
</template>

<script>
import SideBar from "../../../components/SideBar";
import Confirm from "@/components/Confirm";
import UserForm from "@/components/team/user/UserForm";
import {config} from "@/config";

export default {
    name: "TeamEditUser",
    inject: ["getUser", "createAlert", "checkAuthorization"],
    components: {
        SideBar,
        Confirm,
        UserForm,
    },
    data() {
        return {
            confirm: {
                open: false,
                text: "",
            },
            id: "",
            civility: "",
            firstname: "",
            lastname: "",
            profilId: null,
            email: "",
            birthday: "",
            profilPicture: "",
            phoneNumber: "",
            hand: "",
            activity: "",
            job: "",
            imageAuthorization: "",
            handAuthorization: "",
            address: {
                streetNumber: "",
                streetName: "",
                postalCode: "",
                city: "",
                country: "",
            },
            administratifStatus: "",
            fileFees: "",
            financing: "",
            howKnowUs: "",
            comments: "",
            handicap: "",
            workshopId: null,
            active: "",
            isTrainer: false,
        };
    },
    methods: {
        openDialog() {
            this.confirm.text =
                "Êtes-vous sûr de vouloir désactiver cet utilisateur ?";
            this.confirm.open = true;
        },
        closeDialog() {
            this.confirm.open = false;
            this.confirm.text = "";
        },
        resetPassword() {
            fetch(`${config.url}/team/user/${this.id}/reset-password`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "GET",
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        this.createAlert([
                            {
                                type: "success",
                                title: "Mail envoyée.",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
        updateUser: function (form) {
            fetch(`${config.url}/team/user/${this.id}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "PATCH",
                body: JSON.stringify(form),
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        this.createAlert([
                            {
                                type: "success",
                                title: "Utilisateur mis à jour",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
        activeUser: function (active) {
            this.closeDialog();

            fetch(`${config.url}/team/user/${this.id}/active`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "PATCH",
                body: JSON.stringify({active: active}),
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        this.active = active;

                        this.createAlert([
                            {
                                type: "success",
                                text: active
                                    ? "Utilisateur activé."
                                    : "Utilisateur désactivé.",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
    beforeMount() {
        this.id = this.$route.params.id;

        fetch(`${config.url}/team/user/${this.id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 404 || res.status === 403) {
                    this.$router.push("/404");
                } else if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    const simplifiedUser = data.simplifiedUser;

                    this.civility = simplifiedUser.civility;
                    this.firstname = simplifiedUser.firstname;
                    this.lastname = simplifiedUser.lastname;
                    this.profilId = simplifiedUser.profilId;
                    this.email = simplifiedUser.email;
                    this.birthday = simplifiedUser.birthday;
                    this.profilPicture = simplifiedUser.profilPicture;
                    this.phoneNumber = simplifiedUser.phoneNumber;
                    this.hand = simplifiedUser.hand;
                    this.activity = simplifiedUser.activity;
                    this.job = simplifiedUser.job;
                    this.imageAuthorization = simplifiedUser.imageAuthorization;
                    this.handAuthorization = simplifiedUser.handAuthorization;
                    this.address = simplifiedUser.address;
                    this.administratifStatus =
                        simplifiedUser.administratifStatus;
                    this.fileFees = simplifiedUser.fileFees;
                    this.financing = simplifiedUser.financing;
                    this.howKnowUs = simplifiedUser.howKnowUs;
                    this.comments = simplifiedUser.comments;
                    this.handicap = simplifiedUser.handicap;
                    this.workshopId = simplifiedUser.workshopId;
                    this.active = simplifiedUser.active;

                    if (data.isTrainer) {
                        this.isTrainer = data.isTrainer;
                    }
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
