<template>
    <div>
        <Editor
            v-model="editorContent"
            :api-key="config.tinyMCE_api_key"
            :init="{
                toolbar_mode: 'sliding',
                plugins:
                    'autolink charmap codesample emoticons link lists searchreplace table visualblocks wordcount preview code',
                menubar: 'edit insert format table',
                toolbar:
                    'undo redo | blocks fontfamily fontsize | forecolor bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat preview code',
                height: '1100px',
                width: isA4 ? '660px' : '100%',
                resize: false,
                language: 'fr_FR',
            }"
        />
        <span
            v-if="mountedVal && required && !this.editorContent"
            style="color: red"
        >Le champ est requis.</span>
    </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import {config} from "@/config";

export default {
    name: "TextEditor",
    props: {
        defaultContent: String,
        required: {
            type: Boolean,
            default: false,
        },
        isA4: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Editor,
    },
    data() {
        return {
            config,
            mountedVal: false,
            editorContent: "",
            isEditorContentRequired: "false",
        };
    },
    watch: {
        defaultContent: function (newVal) {
            this.editorContent = newVal ? newVal : "";
        },
    },
    mounted() {
        this.mountedVal = true;
    },
};
</script>

<style scoped>
@media (min-width: 1024px) {
    #sample {
        display: flex;
        flex-direction: column;
        place-items: center;
        width: 100%;
    }
}
</style>
