<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <h2>Détails : {{ user }}</h2>

            <div class="d-flex justify-space-between">
                <v-btn color="#374840" @click="this.$router.go(-1)" class="mt-5">Retour</v-btn>

                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn
                            color="#374840"
                            v-bind="props"
                            class="mt-5"
                        >
                            Actions
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item>
                            <v-list-item-title @click="downloadSignatures()">Télécharger les présences
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="checkAuthorization('team:score:student')">
                            <v-list-item-title @click="sendMail('score')">Mail de relance notations
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="checkAuthorization('team:score:student')">
                            <v-list-item-title @click="sendMail('quiz')">Mail de relance questionnaire
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="checkAuthorization('team:score:student') && quizIsCompleted">
                            <v-list-item-title @click="downloadQuiz()">Télécharger le
                                questionnaire
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="checkAuthorization('team:score:student') && scores">
                            <v-list-item-title @click="sendAttestation()">Envoyer l'attestation de
                                réussite
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <v-card class="mt-5">
                <v-card-title>Détails de la formation : {{ formation }}</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="3">
                            <p>Durée de la formation : {{ durations ? durations.formationDuration + 'h' : '' }}</p>
                        </v-col>
                        <v-col cols="12" md="3">
                            <p>Temps effectués : {{ durations ? durations.eventsDuration + 'h' : '' }}</p>
                        </v-col>
                        <v-col cols="12" md="3">
                            <p>Temps supplémentaires : {{ durations ? durations.overtimes + 'h' : '' }}</p>
                        </v-col>
                        <v-col cols="12" md="3">
                            <p>Temps absents : {{ durations ? durations.eventsNotDuration + 'h' : '' }}</p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-5">
                <v-tabs
                    v-model="tab"
                    color="#374840"
                    show-arrows
                >
                    <v-tab value="SES">Sessions</v-tab>
                    <v-tab value="NOT">Notations</v-tab>
                    <v-tab value="QUE">Questionnaire</v-tab>
                </v-tabs>

                <v-card-text>
                    <v-window v-model="tab">
                        <v-window-item value="SES">
                            <v-card
                                :to="'/team/calendar/' + event.id + '/edit'"
                                v-for="event in events"
                                :key="event.id"
                                class="mb-5 mt-2 modern-card"
                            >
                                <v-row>
                                    <v-col cols="12" md="12" :lg="event.signatureStart ? '11' : '12'">
                                        <v-card-title class="card-title">{{ event.title }}</v-card-title>
                                        <v-card-subtitle class="card-subtitle">
                                            {{ formatDate(event.start) }} - {{ formatDate(event.end) }}
                                        </v-card-subtitle>
                                        <v-card-text class="card-text">
                                            {{ event.eventTypeName }} - {{ event.formationName }}
                                        </v-card-text>
                                    </v-col>
                                    <v-col v-if="event.signatureStart" cols="12" md="12" lg="1"
                                           class="d-flex justify-center align-center">
                                        <v-icon color="green">mdi-check-bold</v-icon>
                                    </v-col>
                                </v-row>
                            </v-card>

                        </v-window-item>
                        <v-window-item value="NOT">
                            <v-row>
                                <v-col cols="12" md="12" lg="6">
                                    <v-btn
                                        v-if="checkAuthorization('team:score:update') && scores"
                                        color="#374840"
                                        :to="'/team/users/' + userId + '/training/formation/' + formationId + '/score-intermediate'"
                                    >
                                        Note intermédiaire
                                    </v-btn>
                                    <v-btn
                                        v-if="checkAuthorization('team:score:update') && scores"
                                        color="#374840"
                                        :to="'/team/users/' + userId + '/training/formation/' + formationId + '/score-end'"
                                    >
                                        Note fin
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" md="12" lg="6" class="d-flex justify-end">
                                    <v-btn
                                        v-if="checkAuthorization('team:score:student') && scores"
                                        color="#E57E29"
                                        :to="'/team/users/' + userId + '/training/formation/' + formationId + '/student-score-start'"
                                    >
                                        Note début
                                    </v-btn>
                                    <v-btn
                                        v-if="checkAuthorization('team:score:student') && scores"
                                        color="#E57E29"
                                        :to="'/team/users/' + userId + '/training/formation/' + formationId + '/student-score-end'"
                                    >
                                        Note fin
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-table v-if="scores">
                                <thead>
                                <tr>
                                    <th rowspan="2" class="text-left">
                                        Compétence
                                    </th>
                                    <th colspan="4" class="text-center">
                                        Auto évaluation
                                    </th>
                                    <th colspan="5" class="text-center">
                                        Évaluation formateur
                                    </th>
                                </tr>
                                <tr>
                                    <th>Initiale</th>
                                    <th>Finale</th>
                                    <th>Marge</th>
                                    <th>Autonomie ressentie</th>
                                    <th>Intermédiaire</th>
                                    <th>Finale</th>
                                    <th>Marge</th>
                                    <th>Résultat</th>
                                    <th>Validation des acquis <span style="color:red;">(Acquis si >= 60 %)</span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                    v-for="(skill, key) in scores.skills"
                                    :key="`${key}`"
                                    style="vertical-align: middle"
                                >
                                    <td>
                                        {{ skill.ref }} {{ skill.name }}
                                    </td>
                                    <td class="text-center">
                                        {{ scores.studentStart[key] }}
                                    </td>
                                    <td class="text-center">
                                        {{ scores.studentEnd[key] }}
                                    </td>
                                    <td class="text-center">
                                        {{
                                            scores.studentStart[key] !== ""
                                                ? 5 -
                                                scores.studentStart[key]
                                                : ""
                                        }}
                                    </td>
                                    <td class="text-center">
                                        {{
                                            this.getAutonomy(
                                                scores.studentStart[key],
                                                scores.studentEnd[key]
                                            ) + "%"
                                        }}
                                    </td>
                                    <td class="text-center">
                                        {{ scores.teamIntermediate[key] }}
                                    </td>
                                    <td class="text-center">
                                        {{ scores.teamEnd[key] }}
                                    </td>
                                    <td class="text-center">
                                        {{
                                            scores.studentStart[key] !== ""
                                                ? 4 -
                                                scores.studentStart[key]
                                                : ""
                                        }}
                                    </td>
                                    <td class="text-center">
                                        {{
                                            this.getResult(
                                                scores.studentStart[key],
                                                scores.teamEnd[key]
                                            ) + "%"
                                        }}
                                    </td>
                                    <td class="text-center">
                                        {{
                                            this.getValidation(
                                                scores.studentStart[key],
                                                scores.teamEnd[key]
                                            ) + "%"
                                        }}
                                    </td>
                                </tr>
                                </tbody>
                            </v-table>
                        </v-window-item>
                        <v-window-item value="QUE">
                            <QuizForm
                                :defaultReadOnly="true"
                                :defaultUserView="'team'"
                                @quizFormComplet="quizIsCompleted = $event"
                            />
                        </v-window-item>
                    </v-window>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import QuizForm from "@/components/team/training/QuizForm";
import {config} from "@/config";

export default {
    name: "TeamUserTrainingFormation",
    inject: ["getUser", "createAlert", "checkAuthorization"],
    components: {
        SideBar,
        QuizForm,
    },
    data() {
        return {
            tab: null,
            userId: "",
            user: "",
            formationId: "",
            formation: "",
            events: [],
            durations: null,
            scores: null,
            quizIsCompleted: false,
        };
    },
    methods: {
        getResult(studentStart, teamEnd) {
            if (studentStart === "" || teamEnd === "") {
                return "";
            }

            const teamMarge = 4 - studentStart;

            const result =
                teamMarge > 0 ? (teamEnd - studentStart) / teamMarge : 1;

            return (result * 100).toFixed(2);
        },
        getAutonomy(studentStart, studentEnd) {
            if (studentStart === "" || studentEnd === "") {
                return "";
            }

            const studentMarge = 5 - studentStart;

            const result =
                studentMarge === 0
                    ? 1
                    : (studentEnd - studentStart) / studentMarge;

            return (result * 100).toFixed(0);
        },
        getValidation(studentStart, teamEnd) {
            const result = this.getResult(studentStart, teamEnd);

            return result > 100 ? 100 : result;
        },
        formatDate(date) {
            const newDate = new Date(date);

            const options = {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
            };

            return new Intl.DateTimeFormat("fr-FR", options).format(newDate);
        },
        sendAttestation: function () {
            fetch(
                `${config.url}/team/formation/${this.userId}/${this.formationId}/attestation`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + this.getUser().token,
                    },
                    method: "POST",
                }
            )
                .then(async (res) => {
                    if (res.status === 201) {
                        this.createAlert([
                            {
                                type: "success",
                                title: "Attestation envoyée",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
        downloadQuiz: function () {
            fetch(
                `${config.url}/team/quiz/${this.userId}/${this.formationId}/download`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + this.getUser().token,
                    },
                    method: "POST",
                }
            )
                .then(async (res) => {
                    if (res.status === 200) {
                        const pdfBlob = await res.blob();
                        const pdfUrl = URL.createObjectURL(pdfBlob);

                        const downloadLink = document.createElement("a");
                        downloadLink.href = pdfUrl;
                        downloadLink.download = "Questionnaire apprenant.pdf";
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);

                        this.createAlert([
                            {
                                type: "success",
                                title: "Questionnaire téléchargé",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
        downloadSignatures() {
            fetch(`${config.url}/team/signature/formation/${this.formationId}/student/${this.userId}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "GET",
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        const pdfBlob = await res.blob();
                        const pdfUrl = URL.createObjectURL(pdfBlob);

                        const downloadLink = document.createElement("a");
                        downloadLink.href = pdfUrl;
                        downloadLink.download = "LAD-signatures.pdf";
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);

                        this.createAlert([
                            {
                                type: "success",
                                title: "Le document a été téléchargé.",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
        sendMail(type) {
            fetch(`${config.url}/team/formation/${this.formationId}/student/${this.userId}?type=${type}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "GET",
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        this.createAlert([
                            {
                                type: "success",
                                title: "Mail envoyé.",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
    beforeMount() {
        this.userId = this.$route.params.userId;
        this.formationId = this.$route.params.formationId;

        fetch(
            `${config.url}/team/event/user/${this.userId}/formation/${this.formationId}`,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "GET",
            }
        )
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.events = data.events;
                    this.durations = data.durations;
                    this.user = data.user;
                    this.formation = data.formation;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });

        fetch(
            `${config.url}/team/score/user/${this.userId}/training/formation/${this.formationId}`,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "GET",
            }
        )
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.scores = data;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>

<style scoped>
.modern-card {
    border: none;
    border-radius: 12px;
    background: #F7F1E3;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s, transform 0.3s;
}

.modern-card:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    transform: translateY(-6px);
}
</style>