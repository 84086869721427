<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <v-form @submit.prevent="submitForm">
                <v-btn
                    v-if="checkAuthorization('team:permission:update')"
                    type="submit"
                >Enregistrer
                </v-btn
                >

                <v-table
                    class="mt-1"
                    height="80vh"
                    fixed-header
                >
                    <thead>
                    <tr>
                        <th v-for="header in tableHeaders" :key="header">
                            {{ header }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in tableDatas" :key="item.permissionId">
                        <td>{{ item.permissionName }}</td>
                        <td>
                            <v-checkbox
                                v-model="item.directeur"
                                label=""
                            ></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox
                                v-model="item.responsable"
                                label=""
                            ></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox
                                v-model="item.assistant"
                                label=""
                            ></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox
                                v-model="item.formateur"
                                label=""
                            ></v-checkbox>
                        </td>
                    </tr>
                    </tbody>
                </v-table>
            </v-form>
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import {config} from "@/config";

export default {
    name: "TeamPermissionList",
    inject: ["getUser", "createAlert", "checkAuthorization"],
    components: {
        SideBar,
    },
    data() {
        return {
            tableHeaders: [
                "Permission",
                "Directeur",
                "Responsable",
                "Assistant",
                "Formateur",
            ],
            tableDatas: [],
        };
    },
    methods: {
        submitForm() {
            fetch(`${config.url}/team/permission`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "POST",
                body: JSON.stringify(this.tableDatas),
            })
                .then(async (res) => {
                    if (res.status === 201) {
                        this.createAlert([
                            {
                                type: "success",
                                title: "Permissions mises à jour",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
    beforeMount() {
        fetch(`${config.url}/team/permission`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 403) {
                    this.$router.push("/404");
                } else if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.tableDatas = data;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
