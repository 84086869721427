import {checkAuthorization, isAuthenticated} from "@/router/middlewares";

import TeamStat from "@/views/team/dashboard/Stat.vue";
import TeamQuiz from "@/views/team/dashboard/Quiz.vue";

import TeamLink from "@/views/team/Link";

import TeamListUser from "@/views/team/user/List";
import TeamEditUser from "@/views/team/user/Edit";
import TeamAddUser from "@/views/team/user/Add";

import TeamCursusList from "@/views/team/training/cursus/List";
import TeamCursusAdd from "@/views/team/training/cursus/Add";
import TeamCursusEdit from "@/views/team/training/cursus/Edit";

import TeamFormationList from "@/views/team/training/formation/List";
import TeamFormationAdd from "@/views/team/training/formation/Add";
import TeamFormationEdit from "@/views/team/training/formation/Edit";

import TeamSkillList from "@/views/team/training/skill/List";
import TeamSkillAdd from "@/views/team/training/skill/Add";
import TeamSkillEdit from "@/views/team/training/skill/Edit";

import TeamMailList from "@/views/team/mail/List";
import TeamMailEdit from "@/views/team/mail/Edit";

import TeamShortcodeDetails from "@/views/team/shortcode/Details";

import TeamVarsysEdit from "@/views/team/varsys/Edit";
import TeamVarsysListEdit from "@/views/team/varsys/List";

import TeamDocList from "@/views/team/doc/List";
import TeamDocAdd from "@/views/team/doc/Add";
import TeamDocEdit from "@/views/team/doc/Edit";

import TeamCalendar from "@/views/team/calendar/Index";

import TeamEventAdd from "@/views/team/calendar/Add";
import TeamEventEdit from "@/views/team/calendar/Edit";
import TeamEventSignature from "@/views/team/calendar/Signature";

import TeamEventTypeList from "@/views/team/eventType/List";
import TeamEventTypeAdd from "@/views/team/eventType/Add";
import TeamEventTypeEdit from "@/views/team/eventType/Edit";

import TeamWorkshopList from "@/views/team/workshop/List";
import TeamWorkshopAdd from "@/views/team/workshop/Add";
import TeamWorkshopEdit from "@/views/team/workshop/Edit";

import TeamPermissionList from "@/views/team/permission/List";

import TeamUserTraining from "@/views/team/user/training/Index";
import TeamUserTrainingFormation from "@/views/team/user/training/FormationDetail";
import TeamUserTrainingEventType from "@/views/team/user/training/EventTypeDetail.vue";

import TeamUserScore from "@/views/team/user/score/Index";
import TeamUserScoreStudent from "@/views/team/user/score/Student";

import TeamTicketsAdd from "@/views/team/ticket/Add";
import TeamTicketsList from "@/views/team/ticket/List";
import TeamTicketsEdit from "@/views/team/ticket/Edit";

import TeamNotificationList from "@/views/team/notification/List";
import TeamNotificationAdd from "@/views/team/notification/Add";

const teamRoutes = [
    {
        path: "/team",
        component: TeamStat,
        beforeEnter: [isAuthenticated, checkAuthorization('team:stat:read')],
    },
    {
        path: "/team/notification",
        component: TeamNotificationList,
        beforeEnter: [isAuthenticated, checkAuthorization('team:notification:read')],
    },
    {
        path: "/team/notification/add",
        component: TeamNotificationAdd,
        beforeEnter: [isAuthenticated, checkAuthorization('team:notification:create')],
    },
    {
        path: "/team/quiz",
        component: TeamQuiz,
        beforeEnter: [isAuthenticated, checkAuthorization('team:quiz:read')],
    },
    {
        path: "/team/links",
        component: TeamLink,
        beforeEnter: [isAuthenticated, checkAuthorization("team")],
    },
    {
        path: "/team/users",
        component: TeamListUser,
        beforeEnter: [isAuthenticated, checkAuthorization("team:user:read")],
    },
    {
        path: "/team/users/add",
        component: TeamAddUser,
        beforeEnter: [isAuthenticated, checkAuthorization("team:user:create")],
    },
    {
        path: "/team/users/:id/edit",
        component: TeamEditUser,
        beforeEnter: [isAuthenticated, checkAuthorization("team:user:read")],
    },
    {
        path: "/team/training/cursus",
        component: TeamCursusList,
        beforeEnter: [isAuthenticated, checkAuthorization("team:cursus:read")],
    },
    {
        path: "/team/training/cursus/add",
        component: TeamCursusAdd,
        beforeEnter: [
            isAuthenticated,
            checkAuthorization("team:cursus:create"),
        ],
    },
    {
        path: "/team/training/cursus/:id/edit",
        component: TeamCursusEdit,
        beforeEnter: [isAuthenticated, checkAuthorization("team:cursus:read")],
    },
    {
        path: "/team/training/formation",
        component: TeamFormationList,
        beforeEnter: [
            isAuthenticated,
            checkAuthorization("team:formation:read"),
        ],
    },
    {
        path: "/team/training/formation/add",
        component: TeamFormationAdd,
        beforeEnter: [
            isAuthenticated,
            checkAuthorization("team:formation:create"),
        ],
    },
    {
        path: "/team/training/formation/:id/edit",
        component: TeamFormationEdit,
        beforeEnter: [
            isAuthenticated,
            checkAuthorization("team:formation:read"),
        ],
    },
    {
        path: "/team/training/skill",
        component: TeamSkillList,
        beforeEnter: [isAuthenticated, checkAuthorization("team:skill:read")],
    },
    {
        path: "/team/training/skill/add",
        component: TeamSkillAdd,
        beforeEnter: [isAuthenticated, checkAuthorization("team:skill:create")],
    },
    {
        path: "/team/training/skill/:id/edit",
        component: TeamSkillEdit,
        beforeEnter: [isAuthenticated, checkAuthorization("team:skill:read")],
    },
    {
        path: "/team/mails/",
        component: TeamMailList,
        beforeEnter: [isAuthenticated, checkAuthorization("team:mail:read")],
    },
    {
        path: "/team/shortcode/details",
        component: TeamShortcodeDetails,
        beforeEnter: [isAuthenticated, checkAuthorization("team:mail:read")],
    },
    {
        path: "/team/mails/:id/edit",
        component: TeamMailEdit,
        beforeEnter: [isAuthenticated, checkAuthorization("team:mail:read")],
    },
    {
        path: "/team/docs/",
        component: TeamDocList,
        beforeEnter: [isAuthenticated, checkAuthorization("team:doc:read")],
    },
    {
        path: "/team/docs/add",
        component: TeamDocAdd,
        beforeEnter: [isAuthenticated, checkAuthorization("team:doc:create")],
    },
    {
        path: "/team/docs/:id/edit",
        component: TeamDocEdit,
        beforeEnter: [isAuthenticated, checkAuthorization("team:doc:read")],
    },
    {
        path: "/team/varsys/:id/edit",
        component: TeamVarsysEdit,
        beforeEnter: [isAuthenticated, checkAuthorization("team:varsys:read")],
    },
    {
        path: "/team/varsys",
        component: TeamVarsysListEdit,
        beforeEnter: [isAuthenticated, checkAuthorization("team:varsys:read")],
    },
    {
        path: "/team/calendar",
        component: TeamCalendar,
        beforeEnter: [isAuthenticated, checkAuthorization("team:event:read")],
    },
    {
        path: "/team/calendar/add",
        component: TeamEventAdd,
        beforeEnter: [isAuthenticated, checkAuthorization("team:event:create")],
    },
    {
        path: "/team/calendar/:id/edit",
        component: TeamEventEdit,
        beforeEnter: [isAuthenticated, checkAuthorization("team:event:read")],
    },
    {
        path: "/team/calendar/:eventId/signature/:studentId/start",
        component: TeamEventSignature,
        beforeEnter: [isAuthenticated, checkAuthorization("team:event:read")],
    },
    {
        path: "/team/event-type",
        component: TeamEventTypeList,
        beforeEnter: [
            isAuthenticated,
            checkAuthorization("team:eventType:read"),
        ],
    },
    {
        path: "/team/event-type/add",
        component: TeamEventTypeAdd,
        beforeEnter: [
            isAuthenticated,
            checkAuthorization("team:eventType:create"),
        ],
    },
    {
        path: "/team/event-type/:id/edit",
        component: TeamEventTypeEdit,
        beforeEnter: [
            isAuthenticated,
            checkAuthorization("team:eventType:read"),
        ],
    },
    {
        path: "/team/workshop",
        component: TeamWorkshopList,
        beforeEnter: [
            isAuthenticated,
            checkAuthorization("team:workshop:read"),
        ],
    },
    {
        path: "/team/workshop/add",
        component: TeamWorkshopAdd,
        beforeEnter: [
            isAuthenticated,
            checkAuthorization("team:workshop:create"),
        ],
    },
    {
        path: "/team/workshop/:id/edit",
        component: TeamWorkshopEdit,
        beforeEnter: [
            isAuthenticated,
            checkAuthorization("team:workshop:read"),
        ],
    },
    {
        path: "/team/permission",
        component: TeamPermissionList,
        beforeEnter: [
            isAuthenticated,
            checkAuthorization("team:permission:read"),
        ],
    },
    {
        path: "/team/users/:id/training",
        component: TeamUserTraining,
        beforeEnter: [isAuthenticated, checkAuthorization("team:user:read")],
    },
    {
        path: "/team/users/:userId/training/formation/:formationId",
        component: TeamUserTrainingFormation,
        beforeEnter: [isAuthenticated, checkAuthorization("team:user:read")],
    },
    {
        path: "/team/users/:userId/training/event-type/:eventTypeId",
        component: TeamUserTrainingEventType,
        beforeEnter: [isAuthenticated, checkAuthorization("team:user:read")],
    },
    {
        path: "/team/users/:userId/training/formation/:formationId/score-:scoreName",
        component: TeamUserScore,
        beforeEnter: [isAuthenticated, checkAuthorization("team:score:update")],
    },
    {
        path: "/team/users/:userId/training/formation/:formationId/student-score-:scoreName",
        component: TeamUserScoreStudent,
        beforeEnter: [isAuthenticated, checkAuthorization("team:score:student")],
    },
    {
        path: "/team/ticket/:id/edit",
        component: TeamTicketsEdit,
        beforeEnter: [isAuthenticated, checkAuthorization("team:ticket:read")],
    },
    {
        path: "/team/ticket",
        component: TeamTicketsList,
        beforeEnter: [isAuthenticated, checkAuthorization("team:ticket:read")],
    },
    {
        path: "/team/ticket/add",
        component: TeamTicketsAdd,
        beforeEnter: [
            isAuthenticated,
            checkAuthorization("team:ticket:create"),
        ],
    },
];

export default teamRoutes;
