<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <v-btn
                icon="mdi-plus" size="large"
                v-if="checkAuthorization('team:user:create')"
                to="/team/users/add"
            >
            </v-btn>

            <v-card
                class="mt-1"
                title="Liste des utilisateurs"
                flat
            >
                <template v-slot:text>
                    <v-text-field
                        v-model="search"
                        label="Recherche"
                        prepend-inner-icon="mdi-magnify"
                        variant="solo"
                        hide-details
                        single-line
                    ></v-text-field>
                </template>

                <v-data-table
                    :headers="headers"
                    :items="users"
                    :search="search"
                    :items-per-page-text="'Éléments par page:'"
                    :no-data-text="'Aucune donnée disponible'"
                    multi-sort
                    :items-per-page-options="[10, 25, 50]"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.matric }}</td>
                            <td>{{ item.firstname }}</td>
                            <td>{{ item.lastname }}</td>
                            <td v-if="!isTrainer">
                                <v-chip
                                    v-for="(
                                    status, key
                                ) in item.administratifStatus"
                                    :key="`${key}`"
                                    :color="status !== 'Complet' ? 'red' : 'green'"
                                >
                                    {{ status }}
                                </v-chip>
                            </td>
                            <td>{{ item.email }}</td>
                            <td>{{ item.profil }}</td>
                            <td>{{ item.password ? 'Oui' : 'Non' }}</td>
                            <td class="text-left d-flex">
                                <v-btn
                                    :icon="checkAuthorization('team:user:update') ? 'mdi-pencil' : 'mdi-eye'"
                                    size="large"
                                    variant="text"
                                    :to="'/team/users/' + item.id + '/edit'"
                                ></v-btn>
                                <v-btn
                                    icon="mdi-school" size="large" variant="text"
                                    v-if="item.profil === 'Apprenant'"
                                    :to="'/team/users/' + item.id + '/training'"
                                ></v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-container>
    </div>
</template>
<script>
import SideBar from "@/components/SideBar";
import {config} from "@/config";

export default {
    name: "TeamUserList",
    inject: ["getUser", "createAlert", "checkAuthorization"],
    components: {
        SideBar,
    },
    data() {
        return {
            confirm: {
                open: false,
                text: "",
                selected: "",
            },
            search: '',
            headers: [
                {
                    align: 'start', key: 'matric', title: 'Matricule',
                },
                {key: 'firstname', title: 'Prénom'},
                {key: 'lastname', title: 'Nom'},
                {key: 'email', title: 'Email'},
                {key: 'profil', title: 'Profil'},
                {key: 'password', title: 'Mot de passe'},
                {key: 'actions', title: 'Actions', sortable: false},
            ],
            users: [],
            isTrainer: false,
        }
    },
    beforeMount() {
        fetch(`${config.url}/team/user`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 403) {
                    this.$router.push("/404");
                } else if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    if (data.isTrainer) {
                        this.isTrainer = data.isTrainer;
                    } else {
                        this.headers.splice(3, 0, {key: 'administratifStatus', title: 'Status administratif'});
                    }

                    this.users = data.simplifiedUsers;
                    this.users.forEach((element) => {
                        if (element.administratifStatus) {
                            element.administratifStatus =
                                element.administratifStatus.split(",");
                        }
                    });
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
