<template>
    <div>
        <v-row>
            <v-col cols="12" class="d-flex">
                <v-text-field
                    append-icon="mdi-check-underline"
                    label="Date"
                    variant="solo"
                    type="date"
                    v-model="goToDate"
                    @click:append="goToSelectedDate"
                    hide-details
                    class="mb-5"
                ></v-text-field>
            </v-col>
        </v-row>

        <div :key="forceRerender">
            <FullCalendar
                :options="calendarOptions"
            />
        </div>

        <Dialog
            :defaultDialog="dialog"
            @close="closeDialog"
            @destroy="deteleEvent"
        />
    </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";

import Dialog from "@/components/team/calendar/Dialog";

import {config} from "@/config";

export default {
    name: "CalendarIndex",
    inject: ["getUser", "createAlert"],
    components: {
        FullCalendar,
        Dialog,
    },
    data() {
        return {
            goToDate: null,
            calendarOptions: {
                plugins: [
                    dayGridPlugin,
                    interactionPlugin,
                    listPlugin,
                    timeGridPlugin,
                ],
                initialView: "timeGridWeek",
                eventClick: this.handleEventClick,
                locale: frLocale,
                headerToolbar: {
                    left: "prev,next today",
                    center: "title",
                    right: "timeGridDay,timeGridWeek,dayGridMonth,listWeek",
                },
                expandRows: true,
                navLinks: true,
                nowIndicator: true,
                slotMinTime: "08:00:00",
                slotMaxTime: "21:00:00",
                eventContent: this.customEventContent,
                datesSet: this.filterDate,
                events: [],
                hiddenDays: [0],
            },
            forceRerender: false,
            dialog: {
                visible: false,
            },
        };
    },
    props: {
        defaultEvents: Array,
        type: String,
        teams: Array,
    },
    watch: {
        defaultEvents: function (newVal) {
            this.calendarOptions.events = Array.isArray(newVal) ? newVal : [];
        },
        type: function (newVal) {
            this.profil = newVal ? newVal : "";
        },
        teams: function () {
            this.forceRerender = !this.forceRerender;
        },
    },
    mounted() {
        const mobileStyles = `
            @media (max-width: 600px) {
                .fc-toolbar {
                    flex-direction: column;
                }
                .fc-toolbar-title {
                font-size: 16px!important;
                }
                .fc-header-toolbar .fc-button {
                padding: 5px 10px;
                font-size: 12px;
                }
                .fc-time, .fc-title {
                font-size: 14px;
                }
                .fc-col-header-cell-cushion, .fc-scrollgrid-shrink-cushion {
                    font-size: 12px;
                }
                .fc-view {
                min-height: 500px;
                }
            }
        `;

        const styleTag = document.createElement("style");
        styleTag.type = "text/css";
        styleTag.appendChild(document.createTextNode(mobileStyles));

        document.head.appendChild(styleTag);
    },
    methods: {
        filterDate(dateInfo) {
            let filters = localStorage.getItem("filter_date");

            const date = dateInfo.startStr.split('T')[0];

            this.$emit("selectedDate", date);

            if (filters) {
                filters = JSON.parse(filters);
                filters.initialDate = date;
                localStorage.setItem("filter_date", JSON.stringify(filters));
            } else {
                const newFilters = {initialDate: date};
                localStorage.setItem("filter_date", JSON.stringify(newFilters));
            }
        },
        goToSelectedDate() {
            if (!this.goToDate) {
                localStorage.removeItem("filter_date")
                return;
            }

            this.calendarOptions.initialDate = this.goToDate;
            this.forceRerender = !this.forceRerender;

            let filters = localStorage.getItem("filter_date");

            if (filters) {
                filters = JSON.parse(filters);
                filters.initialDate = this.goToDate;
                localStorage.setItem("filter_date", JSON.stringify(filters));
            } else {
                const newFilters = {initialDate: this.goToDate};
                localStorage.setItem("filter_date", JSON.stringify(newFilters));
            }
        },
        customEventContent: function (arg) {
            const title = arg.event.title;
            const eventType = arg.event.extendedProps.eventType;
            const formation = arg.event.extendedProps.formation;

            const formattedStartTime = this.formatDate(arg.event.start, true);
            const formattedEndTime = this.formatDate(arg.event.end, true);

            const team =
                this.profil !== "team"
                    ? ""
                    : `<br><span>${arg.event.extendedProps.teamUser.split(';')[0]}</span>`;

            const day =
                arg.event.allDay
                    ? ""
                    : `<strong>${formattedStartTime} - ${formattedEndTime}</strong><br>`;

            const content = `
                <div class="custom-event">
                    ${day}
                    <span>${title}</span><br>
                    <span>
                        ${eventType} ${formation ? formation : ""}
                    </span>
                    ${team}
                </div>
            `;

            return {html: content};
        },
        handleEventClick: function (info) {
            const event = info.event;

            const startDate = new Date(event.startStr);

            this.dialog.id = event.extendedProps.id;
            this.dialog.title = event.title;
            this.dialog.start = this.formatDate(startDate);

            if (event.endStr) {
                const endDate = new Date(event.endStr);
                this.dialog.end = this.formatDate(endDate);
            }
            this.dialog.profil = this.type;
            this.dialog.eventType = event.extendedProps.eventType;
            this.dialog.formation = event.extendedProps.formation;
            this.dialog.formationId = event.extendedProps.formationId;
            this.dialog.eventTypeId = event.extendedProps.eventTypeId;
            this.dialog.teamUser = event.extendedProps.teamUser;
            this.dialog.selectedStudentUsers =
                event.extendedProps.selectedStudentUsers;

            this.dialog.visible = true;
        },
        closeDialog() {
            this.dialog.visible = false;
        },
        formatDate(date, hhmm) {
            let options = {
                hour: "numeric",
                minute: "numeric",
            };

            if (!hhmm) {
                options = {
                    ...options,
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                };
            }
            return new Intl.DateTimeFormat("fr-FR", options).format(date);
        },
        deteleEvent: function (id) {
            fetch(`${config.url}/team/event/${id}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "DELETE",
            })
                .then((res) => {
                    if (res.status === 204) {
                        this.calendarOptions.events =
                            this.calendarOptions.events.filter(
                                (item) => item.extendedProps.id !== id
                            );

                        this.closeDialog();

                        this.createAlert([
                            {
                                type: "success",
                                title: "Événement supprimé.",
                            },
                        ]);
                    } else {
                        this.createAlert([
                            {
                                type: "error",
                                title: "Une erreur est survenue.",
                            },
                        ]);
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
    beforeMount() {
        let filterDate = localStorage.getItem("filter_date");
        if (filterDate) {
            filterDate = JSON.parse(filterDate);
            this.goToDate = filterDate.initialDate;
            this.goToSelectedDate();
        }
    }
};
</script>
<style>
.custom-event {
    overflow: auto;
    max-height: 100%;
}
</style>
