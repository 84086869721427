<template>
    <v-app>
        <v-app-bar app>
            <v-btn v-if="!$vuetify.display.mobile" text to="/">
                <v-toolbar-title class="white--text font-weight-bold">
                    Les Ateliers Dargent
                </v-toolbar-title>
            </v-btn>

            <v-btn v-else to="/" icon>
                <v-icon>mdi-home-variant-outline</v-icon>
            </v-btn>

            <v-spacer></v-spacer>

            <v-toolbar-items
                :class="!$vuetify.display.mobile ? 'hidden-sm-and-down' : ''"
            >
                <v-btn
                    class="text-none" stacked
                    to="/team/notification"
                    v-if="user && checkAuthorization('team:notification:read')"
                >
                    <v-badge
                        color="error"
                        :content="user.notifications"
                        v-if="user.notifications > 0"
                    >
                        <v-icon>mdi-bell-outline</v-icon>
                    </v-badge>
                    <v-icon v-else>mdi-bell-outline</v-icon>
                </v-btn>

                <v-btn
                    v-if="!$vuetify.display.mobile && !user"
                    text
                    to="/auth/login"
                >
                    Se connecter
                </v-btn>

                <v-btn v-else-if="!user" to="/auth/login" icon>
                    <v-icon>mdi-login</v-icon>
                </v-btn>
            </v-toolbar-items>

            <div v-if="user" class="text-center">
                <v-menu v-model="menu">
                    <template v-slot:activator="{ props }">
                        <v-btn
                            icon
                            v-bind="props"
                        >
                            <v-icon>mdi-account-circle-outline</v-icon>
                        </v-btn>
                    </template>

                    <v-card min-width="300">
                        <v-list>
                            <v-list-item
                                :prepend-avatar="user.profilPicture"
                                :subtitle="user.profile"
                                :title="user.name"
                            >
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>

                        <v-list>
                            <v-list-subheader>Compte</v-list-subheader>

                            <v-list-item to="/profile">
                                <template v-slot:prepend>
                                    <v-icon icon="mdi-account-outline"></v-icon>
                                </template>

                                <v-list-item-title>Informations du compte</v-list-item-title>
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>

                        <v-list>
                            <v-list-subheader>Espace</v-list-subheader>

                            <v-list-item
                                v-if="checkAuthorization('admin')"
                                to="/admin"
                            >
                                <template v-slot:prepend>
                                    <v-icon icon="mdi-shield-crown"></v-icon>
                                </template>

                                <v-list-item-title>Espace administrateur</v-list-item-title>
                            </v-list-item>

                            <v-list-item
                                v-if="checkAuthorization('team')"
                                to="/team/calendar"
                            >
                                <template v-slot:prepend>
                                    <v-icon icon="mdi-account-supervisor"></v-icon>
                                </template>

                                <v-list-item-title>Espace equipe</v-list-item-title>
                            </v-list-item>

                            <v-list-item
                                v-if="checkAuthorization('student:*')"
                                to="/student/planning"
                            >
                                <template v-slot:prepend>
                                    <v-icon icon="mdi-account-supervisor"></v-icon>
                                </template>

                                <v-list-item-title>Espace apprenant</v-list-item-title>
                            </v-list-item>
                        </v-list>

                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn
                                class="green-btn mt-3 w-100"
                                @click="removeAuth"
                            >
                                Déconnexion
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </div>
        </v-app-bar>

        <v-main style="background-color: #F9F7F2">
            <router-view></router-view>
        </v-main>

        <div class="centered-container">
            <AlertBanner
                v-for="(alert, key) in alerts"
                :key="key"
                v-bind:type="alert.type"
                v-bind:title="alert.title"
                v-bind:text="alert.text"
            />
        </div>
    </v-app>
</template>

<script>
import AlertBanner from "@/components/AlertBanner";

export default {
    name: "App",
    components: {
        AlertBanner,
    },
    data() {
        return {
            user: null,
            alerts: [],
            fav: true,
            menu: false,
            message: false,
            hints: true,
        };
    },
    methods: {
        checkAuthorization(permission) {
            if (!this.user) {
                return false;
            }

            if (!permission.includes(":")) {
                const allowed = this.user.permissions.some((item) =>
                    item.includes(permission)
                );

                if (!allowed) {
                    return false;
                }
            } else if (!this.user.permissions.includes(permission)) {
                return false;
            }

            return true;
        },
        removeAuth: async function () {
            localStorage.removeItem("user");
            localStorage.removeItem("filters");
            localStorage.removeItem("filter_date");

            await this.$router.push("/auth/login");

            this.user = null;

            this.alerts.push({
                type: "success",
                title: "Déconnexion réussie.",
            });
        },
    },
    beforeMount() {
        this.user = JSON.parse(localStorage.getItem("user"));
    },
    provide() {
        return {
            setAuth: (user) => {
                this.user = user;
                localStorage.setItem("user", JSON.stringify(user));

                const isTeam = user.permissions.some((item) =>
                    item.includes("team:")
                );

                if (isTeam) {
                    this.$router.push("/team/calendar");
                } else {
                    this.$router.push("/student/planning");
                }
            },
            updateUser: (name, profilPicture) => {
                this.user.name = name;
                this.user.profilPicture = profilPicture;
                localStorage.setItem("user", JSON.stringify(this.user));
            },
            createAlert: (alerts) => {
                this.alerts = alerts;
                setTimeout(() => {
                    this.alerts = [];
                }, 5000);
            },
            getUser: () => {
                return this.user;
            },
            checkAuthorization: (permission) =>
                this.checkAuthorization(permission),
        };
    },
};
</script>

<style>
.centered-container {
    position: fixed;
    top: 100px;
    right: 25px;
    z-index: 999;
}

@media (max-width: 768px) {
    .centered-container {
        right: auto;
        left: 0;
        transform: none;
    }
}

th {
    font-size: 18px;
    font-weight: bold;
}
</style>
