import router from "@/router/index";

const tokenExpValid = (tokenExp) => {
    const now = new Date();
    const exp = new Date(tokenExp * 1000);
    return exp > now;
};

export const isAuthenticated = (to, from, next) => {
    if (!localStorage.getItem("user")) {
        router.push("/auth/login");
        return;
    }

    const user = JSON.parse(localStorage.getItem("user"));
    if (!tokenExpValid(user.tokenExp)) {
        localStorage.removeItem("user");
        window.location.reload();
        router.push("/auth/login");
    }

    next();
};

export const isNotAuthenticated = (to, from, next) => {
    if (localStorage.getItem("user")) {
        const user = JSON.parse(localStorage.getItem("user"));
        if (tokenExpValid(user.tokenExp)) {
            router.push("/");
            return;
        }
    }

    next();
};

export const checkAuthorization = (permission) => (to, from, next) => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!permission.includes(":")) {
        const allowed = user.permissions.some((item) =>
            item.includes(permission)
        );

        if (!allowed) {
            router.push("/404");
            return;
        }
    } else if (!user.permissions.includes(permission)) {
        router.push("/404");
        return;
    }

    next();
};
