<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <h2>Modification d'une variable</h2>

            <v-btn class="mt-5" color="#374840" @click="this.$router.go(-1)">Retour</v-btn>
            <v-btn class="mt-5" color="white" @click="patchVarsys">Enregistrer</v-btn>

            <v-card class="mt-5">
                <v-tabs
                    v-model="tab"
                    color="#374840"
                    show-arrows
                >
                    <v-tab value="MAIN">Informations principales</v-tab>
                </v-tabs>

                <v-card-text>
                    <v-window v-model="tab">
                        <v-window-item value="MAIN">
                            <v-text-field
                                v-if="varsys.type === 'string'"
                                v-model="this.varsys.value"
                                variant="solo"
                                :rules="config.rules.required"
                            />

                            <div v-else-if="varsys.type === 'picture'">
                                <v-file-input
                                    variant="solo" ref="fileInput"
                                    accept=".png, .jpeg, .jpg"
                                    :rules="[...config.rules.required, ...config.rules.fileSize]"
                                    @change="handleFileUpload()">
                                </v-file-input>

                                <v-img
                                    v-if="this.varsys.value"
                                    :src="this.varsys.value"
                                    :width="100"
                                    aspect-ratio="1/1"
                                    alt="Photo de profil"
                                ></v-img>
                            </div>

                            <TextEditor
                                v-else
                                ref="textEditorComponentRef"
                                :defaultContent="varsys.value"
                                :required="true"
                                :isA4="false"
                            />
                        </v-window-item>
                    </v-window>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import TextEditor from "@/components/TextEditor.vue";
import SideBar from "@/components/SideBar.vue";
import {config} from "@/config";

export default {
    name: "TeamMailEdit",
    inject: ["getUser", "createAlert"],
    components: {
        TextEditor,
        SideBar,
    },
    data() {
        return {
            id: null,
            varsys: "",
            tab: null,
            config,
        };
    },
    methods: {
        async handleFileUpload() {
            const fileInput = this.$refs.fileInput;
            const file = fileInput.files[0];

            if (file) {
                const base64Data = await this.readFileAsBase64(file);
                this.varsys.value = `data:${file.type};base64,${base64Data}`;
            }
        },
        async readFileAsBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result.split(",")[1]);
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        },
        patchVarsys: async function () {
            if (this.varsys.type === "text") {
                const childComponent = this.$refs.textEditorComponentRef;
                this.varsys.value = childComponent.editorContent;
            }

            if (this.varsys.value) {
                fetch(`${config.url}/team/varsys/${this.id}`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + this.getUser().token,
                    },
                    method: "PATCH",
                    body: JSON.stringify({
                        value: this.varsys.value,
                    }),
                })
                    .then(async (res) => {
                        if (res.status === 200) {
                            this.createAlert([
                                {
                                    type: "success",
                                    title: "Le variable a été mise à jour",
                                },
                            ]);
                        } else {
                            const errors = await res.json();
                            if (errors && errors.errors) {
                                this.createAlert(
                                    errors.errors.map((error) => {
                                        return {
                                            type: "error",
                                            title: error.msg,
                                        };
                                    })
                                );
                            }
                        }
                    })
                    .catch(() => {
                        this.createAlert([
                            {
                                type: "error",
                                title: "Une erreur est survenue.",
                            },
                        ]);
                    });
            }
        },
    },
    beforeMount() {
        this.id = this.$route.params.id;

        fetch(`${config.url}/team/varsys/${this.id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 404 || res.status === 403) {
                    this.$router.push("/404");
                } else if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.varsys = data;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
