<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <v-row justify="space-between" class="mb-4">
                <v-btn
                    icon="mdi-plus" size="large"
                    v-if="checkAuthorization('team:event:create')"
                    to="/team/calendar/add"
                ></v-btn>

                <div v-if="checkAuthorization('team:event:create')">
                    <v-btn v-if="filter" @click="filter = !filter">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="
                            filter &&
                            (selectedEventType ||
                                selectedStudentUsers.length > 0 ||
                                selectedFormations.length > 0 ||
                                selectedTeamUsers.length > 0)
                        "
                        @click="removeFilter()"
                    >
                        <v-icon>mdi-eraser</v-icon>
                    </v-btn>
                    <v-btn @click="toggleFilter">
                        <v-icon
                            :color="!filter && (selectedEventType || selectedStudentUsers.length > 0 || selectedFormations.length > 0 || selectedTeamUsers.length > 0) ? 'red' : 'black'"
                        >
                            {{ filter ? "mdi-filter-check" : "mdi-filter" }}
                        </v-icon>
                    </v-btn>
                    <v-btn to="/team/event-type">
                        <v-icon>mdi-cog</v-icon>
                    </v-btn>
                </div>
            </v-row>

            <v-row class="mb-5" v-show="filter">
                <v-col cols="12" md="12" lg="4">
                    <v-combobox
                        variant="solo"
                        v-model="selectedTeamUsers"
                        :items="teamUsers"
                        item-title="name"
                        item-value="id"
                        label="Membre de l'équipe"
                        multiple
                        hide-details
                        chips
                        :filterable="true"
                    ></v-combobox>
                </v-col>

                <v-col cols="12" md="12" lg="4">
                    <v-combobox
                        variant="solo"
                        v-model="selectedStudentUsers"
                        :items="studentUsers"
                        item-title="name"
                        item-value="id"
                        label="Apprenants"
                        multiple
                        hide-details
                        chips
                        :filterable="true"
                    ></v-combobox>
                </v-col>

                <v-col cols="12" md="12" lg="4"
                >
                    <v-combobox
                        variant="solo"
                        v-model="selectedEventType"
                        :items="eventType"
                        item-title="name"
                        item-value="id"
                        label="Type d'événement"
                        :filterable="true"
                        hide-details
                    ></v-combobox>

                    <v-combobox
                        class="mt-3"
                        v-if="isFormation(selectedEventType)"
                        variant="solo"
                        v-model="selectedFormations"
                        :items="formations"
                        item-title="name"
                        item-value="id"
                        label="Choix de la formation"
                        multiple
                        hide-details
                        chips
                        :filterable="true"
                    ></v-combobox>
                </v-col>
            </v-row>

            <Calendar
                :defaultEvents="events"
                :type="profil"
                :teams="teams"
                :resources="resources"
                @selectedDate="setSelectedDate"
            />
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import Calendar from "@/components/Calendar";
import {config} from "@/config";

export default {
    name: "TeamCalendar",
    inject: ["getUser", "createAlert", "checkAuthorization"],
    components: {
        SideBar,
        Calendar,
    },
    data() {
        return {
            filter: false,
            profil: "trainer",
            events: [],
            eventType: [],
            formations: [],
            teamUsers: [],
            studentUsers: [],
            selectedEventType: "",
            selectedFormations: [],
            selectedTeamUsers: [],
            selectedStudentUsers: [],
            teams: [],
            resources: [],
            selectedDate: null
        };
    },
    methods: {
        setSelectedDate(selectedDate) {
            this.selectedDate = selectedDate;

            const selectedTeamUsersId = this.selectedTeamUsers.map(item => item.id);
            const selectedStudentUsersId = this.selectedStudentUsers.map(item => item.id);
            const selectedFormationsId = this.selectedFormations.map(item => item.id);

            this.getEvents(
                `${
                    config.url
                }/team/event/?selectedFormations=${selectedFormationsId.join(
                    ";"
                )}&selectedStudentUsers=${selectedStudentUsersId.join(
                    ";"
                )}&selectedTeamUsers=${selectedTeamUsersId.join(
                    ";"
                )}&selectedEventType=${
                    this.selectedEventType ? this.selectedEventType.id : ''
                }&selectedDate=${this.selectedDate ? this.selectedDate : ''}`
            );
        },
        isFormation(eventType) {
            if (eventType) {
                return !!this.eventType.find(
                    (item) =>
                        (item.id === eventType.id || item.name === eventType.id) &&
                        item.isFormation
                );
            }
        },
        removeFilter() {
            this.selectedEventType = "";
            this.selectedFormations = [];
            this.selectedTeamUsers = [];
            this.selectedStudentUsers = [];

            this.getEvents(`${config.url}/team/event/`);

            localStorage.removeItem("filters");
        },
        toggleFilter() {
            if (this.filter) {
                if (this.selectedEventType && !this.isFormation(this.selectedEventType)) {
                    this.selectedFormations = [];
                }

                const selectedTeamUsersId = this.selectedTeamUsers.map(item => item.id);
                const selectedStudentUsersId = this.selectedStudentUsers.map(item => item.id);
                const selectedFormationsId = this.selectedFormations.map(item => item.id);

                this.getEvents(
                    `${
                        config.url
                    }/team/event/?selectedFormations=${selectedFormationsId.join(
                        ";"
                    )}&selectedStudentUsers=${selectedStudentUsersId.join(
                        ";"
                    )}&selectedTeamUsers=${selectedTeamUsersId.join(
                        ";"
                    )}&selectedEventType=${
                        this.selectedEventType ? this.selectedEventType.id : ''
                    }&selectedDate=${this.selectedDate ? this.selectedDate : ''}`
                );

                this.setFilter(selectedFormationsId.join(";"), selectedStudentUsersId.join(";"), selectedTeamUsersId.join(";"), this.selectedEventType ? this.selectedEventType.id : '');
            }

            this.filter = !this.filter;
        },
        getEvents(search) {
            fetch(search, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "GET",
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }
                })
                .then((data) => {
                    if (data) {
                        this.events = data.events;
                        this.profil = data.profil;
                        this.resources = data.resources;
                        this.teams = this.selectedTeamUsers;
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
        setFilter(selectedFormationsId, selectedStudentUsersId, selectedTeamUsersId, selectedEventType) {
            let filters = localStorage.getItem("filters");

            if (filters) {
                filters = JSON.parse(filters);
                filters.selectedFormationsId = selectedFormationsId;
                filters.selectedStudentUsersId = selectedStudentUsersId;
                filters.selectedTeamUsersId = selectedTeamUsersId;
                filters.selectedEventType = selectedEventType;

                localStorage.setItem("filters", JSON.stringify(filters));
            } else {
                const newFilters = {
                    selectedFormationsId: selectedFormationsId,
                    selectedStudentUsersId: selectedStudentUsersId,
                    selectedTeamUsersId: selectedTeamUsersId,
                    selectedEventType: selectedEventType
                };

                localStorage.setItem("filters", JSON.stringify(newFilters));
            }
        }
    },
    beforeMount() {
        fetch(`${config.url}/team/event/informations`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.eventType = data.eventType;
                    this.studentUsers = data.students;
                    this.teamUsers = data.teams;
                    this.formations = data.formations;

                    let filters = localStorage.getItem("filters");

                    if (filters) {
                        filters = JSON.parse(filters);
                        this.getEvents(`${config.url}/team/event/?selectedFormations=${filters.selectedFormationsId}&selectedStudentUsers=${filters.selectedStudentUsersId}&selectedTeamUsers=${filters.selectedTeamUsersId}&selectedEventType=${filters.selectedEventType}`);

                        let selectedIds = filters.selectedFormationsId.split(';');
                        this.selectedFormations = this.formations.filter(formation => selectedIds.includes(formation.id.toString()));

                        selectedIds = filters.selectedStudentUsersId.split(';');
                        this.selectedStudentUsers = this.studentUsers.filter(user => selectedIds.includes(user.id.toString()));

                        selectedIds = filters.selectedTeamUsersId.split(';');
                        this.selectedTeamUsers = this.teamUsers.filter(user => selectedIds.includes(user.id.toString()));

                        this.selectedEventType = this.eventType.find(objet => objet.id === filters.selectedEventType);
                    } else {
                        const user = this.getUser();
                        const defaultPlanning = user.settings && user.settings.find(item => item.name === "default_planning");

                        const url = defaultPlanning && defaultPlanning.value === "own" ?
                            `${config.url}/team/event/?selectedTeamUsers=${user.userId}` :
                            `${config.url}/team/event/`;

                        this.getEvents(url);

                        if (defaultPlanning && defaultPlanning.value === "own") {
                            this.selectedTeamUsers = [this.teamUsers.find(objet => objet.id === user.userId)];
                        }
                    }
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
