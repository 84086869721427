<template>
    <div>
        <v-container fluid>
            <SideBar :profil="'team'"/>

            <h2>Création d'un type d'événement</h2>

            <EventTypeForm
                :submit="'Valider'"
                @formSubmitted="createEventType"
            />
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import EventTypeForm from "@/components/team/event/EventTypeForm";
import {config} from "@/config";

export default {
    name: "TeamEventTypeAdd",
    inject: ["getUser", "createAlert"],
    components: {
        SideBar,
        EventTypeForm,
    },
    methods: {
        createEventType: function (form) {
            fetch(`${config.url}/team/event-type`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "POST",
                body: JSON.stringify(form),
            })
                .then(async (res) => {
                    if (res.status === 201) {
                        this.$router.push("/team/event-type");

                        this.createAlert([
                            {
                                type: "success",
                                title: "Type d'événement créé",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
};
</script>
