import {checkAuthorization, isAuthenticated} from "@/router/middlewares";
import StudentPlanning from "@/views/student/Planning";
import StudentTutorialHome from "@/views/student/tutorial/Home.vue";
import StudentTutorialQuiz from "@/views/student/tutorial/Quiz.vue";
import StudentTutorialScore from "@/views/student/tutorial/Score.vue";
import StudentFormations from "@/views/student/training/Training";
import StudentTrainingInformation from "@/views/student/training/Detail";
import StudentTrainingScore from "@/views/student/training/score/Index";
import StudentContact from "@/views/student/Contact";

const studentRoutes = [
    // {
    //     path: "/student/",
    //     component: StudentRoute,
    //     beforeEnter: [isAuthenticated, checkAuthorization("student:*")],
    // },
    {
        path: "/student/tutorial",
        component: StudentTutorialHome,
        beforeEnter: [isAuthenticated, checkAuthorization("student:*")],
    },
    {
        path: "/student/tutorial/quiz",
        component: StudentTutorialQuiz,
        beforeEnter: [isAuthenticated, checkAuthorization("student:*")],
    },
    {
        path: "/student/tutorial/score",
        component: StudentTutorialScore,
        beforeEnter: [isAuthenticated, checkAuthorization("student:*")],
    },
    {
        path: "/student/planning",
        component: StudentPlanning,
        beforeEnter: [isAuthenticated, checkAuthorization("student:*")],
    },
    {
        path: "/student/training",
        component: StudentFormations,
        beforeEnter: [isAuthenticated, checkAuthorization("student:*")],
    },
    {
        path: "/student/training/formation/:formationId",
        component: StudentTrainingInformation,
        beforeEnter: [isAuthenticated, checkAuthorization("student:*")],
    },
    {
        path: "/student/training/formation/:formationId/score-:scoreName",
        component: StudentTrainingScore,
        beforeEnter: [isAuthenticated, checkAuthorization("student:*")],
    },
    {
        path: "/student/contact",
        component: StudentContact,
        beforeEnter: [isAuthenticated, checkAuthorization("student:*")],
    },
];

export default studentRoutes;
