<template>
    <v-form @submit.prevent="submitForm" ref="form">
        <v-btn
            class="mt-5"
            color="#374840"
            @click="this.$router.go(-1)"
        >Retour
        </v-btn>
        <v-btn
            class="mt-5"
            type="submit"
        >{{ submit }}
        </v-btn>

        <v-card class="mt-5">
            <v-tabs
                v-model="tab"
                color="#374840"
                show-arrows
            >
                <v-tab value="MAIN">Informations principales</v-tab>
                <v-tab v-if="submit !== 'Valider'" value="COMMENTS">Commentaires</v-tab>
                <v-tab value="PJ">Pièces jointes</v-tab>
            </v-tabs>

            <v-card-text>
                <v-window v-model="tab">
                    <v-window-item value="MAIN">
                        <v-text-field
                            variant="solo"
                            label="Sujet"
                            v-model="sujet"
                            :rules="config.rules.required"
                            :readonly="readOnly"
                        ></v-text-field>

                        <v-row>
                            <v-col cols="12" md="12" lg="6">
                                <v-select
                                    variant="solo"
                                    label="Status"
                                    :items="[
                                        'En attente retour interne',
                                        'Fermé',
                                        'Ouvert',
                                        'Demande à compléter',
                                        'En cours',
                                    ]"
                                    v-model="status"
                                    :rules="config.rules.required"
                                    :readonly="readOnly || submit === 'Valider'"
                                ></v-select>
                            </v-col>

                            <v-col cols="12" md="12" lg="6">
                                <v-select
                                    variant="solo"
                                    label="Type"
                                    :items="[
                                        'Anomalie',
                                        'Évolution',
                                        'Question',
                                    ]"
                                    v-model="type"
                                    :rules="config.rules.required"
                                    :readonly="readOnly"
                                ></v-select>
                            </v-col>
                        </v-row>

                        <v-row v-if="admin">
                            <v-col cols="12" md="12" lg="6">
                                <v-combobox
                                    variant="solo"
                                    v-model="selectedAdmin"
                                    :items="admins"
                                    item-title="name"
                                    item-value="id"
                                    label="Propriétaire"
                                    :filterable="true"
                                ></v-combobox>
                            </v-col>

                            <v-col cols="12" md="12" lg="6">
                                <v-combobox
                                    variant="solo"
                                    v-model="selectedTeam"
                                    :items="teams"
                                    item-title="name"
                                    item-value="id"
                                    label="Membre de l'équipe"
                                    :filterable="true"
                                ></v-combobox>
                            </v-col>
                        </v-row>

                        <v-textarea
                            variant="solo"
                            label="Description"
                            v-model="description"
                            :rules="config.rules.required"
                            :readonly="readOnly"
                        ></v-textarea>
                    </v-window-item>

                    <v-window-item v-if="submit !== 'Valider'" value="COMMENTS">
                        <v-textarea
                            variant="solo"
                            label="Nouveau commentaire"
                            v-model="comment"
                        ></v-textarea>

                        <h3 class="mt-2 mb-3">Anciens commentaires</h3>

                        <div class="d-flex flex-column" v-for="(co, key) in comments"
                             :key="`${key}`">
                            <div class="d-flex w-100 justify-space-between">
                                <span>{{ co.userName }}</span>
                                <span>{{ co.createdAt }}</span>
                            </div>

                            <p class="mt-3" style="color:grey;">{{ co.description }}</p>

                            <hr class="divider mt-5 mb-5">
                        </div>
                    </v-window-item>

                    <v-window-item value="PJ">
                        <v-file-input
                            :rules="config.rules.fileSize"
                            variant="solo"
                            label="Pièce jointe"
                            prepend-icon="mdi-file"
                            ref="fileInput"
                            accept=".png, .jpeg, .jpg"
                            @change="handleFileUpload"
                        ></v-file-input>

                        <div v-if="submit !== 'Valider'">
                            <h3 class="mt-2 mb-3">Anciennes pièces jointes</h3>

                            <div class="d-flex flex-column" v-for="(pj, key) in pjs"
                                 :key="`${key}`">
                                <div class="d-flex w-100 justify-space-between">
                                    <span>{{ pj.userName }}</span>
                                    <span>{{ pj.createdAt }}</span>
                                </div>

                                <div class="mt-2 w-100 d-flex justify-space-between">
                                    <p class="mt-2" style="color:grey;">{{ pj.name }}</p>

                                    <p class="mt-2 lad-pj-download" @click="download(pj.data)">
                                        Télécharger</p>
                                </div>

                                <img :src="pj.data" alt="" height="400px" width="400px"/>

                                <hr class="divider mt-5 mb-5">
                            </div>
                        </div>
                    </v-window-item>
                </v-window>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
import {config} from "@/config";

export default {
    name: "TicketForm",
    inject: ["getUser", "createAlert", "checkAuthorization"],
    props: {
        submit: String,
        defaultSujet: String,
        defaultDescription: String,
        defaultStatus: String,
        defaultType: String,
        defaultSelectedAdmin: Number,
        defaultSelectedTeam: Number,
        defaultComments: Array,
        defaultPjs: Array,
        readOnly: Boolean,
        admin: Boolean,
    },
    data() {
        return {
            config,
            showPassword: false,
            tab: null,
            sujet: "",
            description: "",
            status: "Ouvert",
            type: "",
            comment: "",
            comments: [],
            pj: {name: "", data: ""},
            pjs: [],
            selectedAdmin: null,
            selectedTeam: null,
            admins: [],
            teams: [],
        };
    },
    watch: {
        defaultSujet: function (newVal) {
            this.sujet = newVal ? newVal : "";
        },
        defaultStatus: function (newVal) {
            this.status = newVal ? newVal : "";
        },
        defaultType: function (newVal) {
            this.type = newVal ? newVal : 0;
        },
        defaultSelectedAdmin: function (newVal) {
            this.selectedAdmin = newVal ? newVal : 0;
        },
        defaultSelectedTeam: function (newVal) {
            this.selectedTeam = newVal ? newVal : 0;
        },
        defaultDescription: function (newVal) {
            this.description = newVal ? newVal : "";
        },
        defaultComments: function (newVal) {
            this.comments = Array.isArray(newVal) ? newVal : [];
        },
        defaultPjs: function (newVal) {
            this.pjs = Array.isArray(newVal) ? newVal : [];
        },
    },
    methods: {
        download(data) {
            const link = document.createElement('a');
            link.href = data;
            link.setAttribute('download', 'image.png');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        async handleFileUpload() {
            const fileInput = this.$refs.fileInput;
            const file = fileInput.files[0];

            if (file) {
                const base64Data = await this.readFileAsBase64(file);
                this.pj.data = `data:${file.type};base64,${base64Data}`;
                this.pj.name = file.name;
            }
        },
        async readFileAsBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result.split(",")[1]);
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        },
        async submitForm() {
            if ((await this.$refs.form.validate()).valid) {
                let ticket = {
                    subject: this.sujet,
                    description: this.description,
                    status: this.status,
                    typeTicket: this.type,
                };

                if (this.comment) {
                    ticket = {
                        ...ticket,
                        comment: this.comment,
                    }
                }

                if (this.pj.name) {
                    ticket = {
                        ...ticket,
                        pj: this.pj,
                    }
                }

                if (this.selectedAdmin) {
                    ticket = {
                        ...ticket,
                        assignId: typeof this.selectedAdmin === 'object' && true && 'id' in this.selectedAdmin ? this.selectedAdmin.id : this.selectedAdmin
                    }
                }

                if (this.selectedTeam) {
                    ticket = {
                        ...ticket,
                        creatorId: typeof this.selectedTeam === 'object' && true && 'id' in this.selectedTeam ? this.selectedTeam.id : this.selectedTeam
                    }
                }

                this.$emit("formSubmitted", ticket);

                if (this.pj.name) {
                    this.$refs.fileInput.reset();
                    this.pj = {name: "", data: ""};
                }
                this.comment = "";
            }
        },
    },
    beforeMount() {
        if (!this.admin) {
            return;
        }

        fetch(`${config.url}/admin/ticket/informations`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.teams = data.teams;
                    this.admins = data.admins;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>

<style scoped>
.divider {
    width: 85%;
    height: 1px;
    background-color: black;
    margin: 5px auto;
}

.lad-pj-download {
    color: #374840;
    cursor: pointer;
}
</style>
