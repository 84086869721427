<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <h2>Modification d'une formation</h2>

            <FormationForm
                :submit="'Enregistrer'"
                :defaultName="name"
                :defaultSelectedCursus="selectedCursus"
                :defaultSelectedSkills="selectedSkills"
                :defaultFinalSkills="finalSkills"
                :defaultBackgroundColor="backgroundColor"
                :defaultUrl="url"
                @formSubmitted="updateFormation"
            />
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import FormationForm from "@/components/team/training/FormationForm";
import {config} from "@/config";

export default {
    name: "TeamFormationEdit",
    inject: ["getUser", "createAlert"],
    components: {
        SideBar,
        FormationForm,
    },
    data() {
        return {
            id: "",
            name: "",
            selectedCursus: [],
            selectedSkills: [],
            finalSkills: [],
            backgroundColor: "",
            url: "",
        };
    },
    methods: {
        updateFormation: function (form) {
            fetch(`${config.url}/team/formation/${this.id}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "PATCH",
                body: JSON.stringify({
                    name: form.name,
                    backgroundColor: form.backgroundColor,
                    url: form.url,
                    cursus: form.cursus,
                    skills: form.skills,
                }),
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        this.createAlert([
                            {
                                type: "success",
                                title: "Formation mis à jour",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
    beforeMount() {
        this.id = this.$route.params.id;

        fetch(`${config.url}/team/formation/${this.id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 404 || res.status === 403) {
                    this.$router.push("/404");
                } else if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.name = data.name;
                    this.selectedCursus = data.cursus;
                    this.selectedSkills = data.skills;
                    this.finalSkills = data.finalSkills;
                    this.backgroundColor = data.backgroundColor;
                    this.url = data.url;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
