<template>
    <v-alert
        density="compact"
        :type="type"
        :title="title"
        :text="text"
        class="mb"
        closable
    ></v-alert>
</template>

<script>
export default {
    name: "AlertBanner",
    props: {
        type: String,
        title: String,
        text: String,
    },
};
</script>

<style scoped>
.mb {
    margin-bottom: 10px;
}
</style>
