<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <v-btn
                icon="mdi-plus" size="large"
                v-if="checkAuthorization('team:skill:create')"
                to="/team/training/skill/add"
            ></v-btn>

            <v-card
                class="mt-1"
                title="Liste des compétences"
                flat
            >
                <template v-slot:text>
                    <v-text-field
                        v-model="search"
                        label="Recherche"
                        prepend-inner-icon="mdi-magnify"
                        variant="solo"
                        hide-details
                        single-line
                    ></v-text-field>
                </template>

                <v-data-table
                    :headers="headers"
                    :items="allSkill"
                    :search="search"
                    :items-per-page-text="'Éléments par page:'"
                    :no-data-text="'Aucune donnée disponible'"
                    multi-sort
                    :items-per-page-options="[10, 25, 50]"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.id }}</td>
                            <td>{{ item.ref }}</td>
                            <td>{{ item.name }}</td>
                            <td class="text-left">
                                <v-btn
                                    icon="mdi-pencil" size="large" variant="text"
                                    v-if="checkAuthorization('team:skill:update')"
                                    :to="'/team/training/skill/' + item.id + '/edit'"
                                ></v-btn>
                                <v-btn
                                    icon="mdi-delete" size="large" variant="text"
                                    v-if="checkAuthorization('team:skill:delete')"
                                    color="red"
                                    @click="openDialog(item.id)"
                                ></v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>

            <Confirm
                v-show="confirm.open"
                :open="confirm.open"
                :text="confirm.text"
                @confirmDialog="deleteSkill"
                @closeDialog="closeDialog"
            />
        </v-container>
    </div>
</template>
<script>
import SideBar from "@/components/SideBar";
import Confirm from "@/components/Confirm";
import {config} from "@/config";

export default {
    name: "TeamSkillList",
    inject: ["getUser", "createAlert", "checkAuthorization"],
    components: {
        SideBar,
        Confirm,
    },
    data() {
        return {
            confirm: {
                open: false,
                text: "",
                selected: "",
            },
            search: '',
            headers: [
                {
                    align: 'start', key: 'id', title: 'Id',
                },
                {key: 'name', title: 'Nom'},
                {key: 'ref', title: 'Référentiel'},
                {key: 'actions', title: 'Actions', sortable: false},
            ],
            allSkill: [],
        }
    },
    methods: {
        openDialog(id) {
            this.confirm.selected = id;
            this.confirm.text =
                "Êtes-vous sûr de vouloir supprimer cette compétence ?";
            this.confirm.open = true;
        },
        closeDialog() {
            this.confirm.open = false;
            this.confirm.selected = "";
            this.confirm.text = "";
        },
        deleteSkill: function () {
            const key = this.allSkill.findIndex(
                (skill) => skill.id === this.confirm.selected
            );

            const id = this.confirm.selected;

            this.closeDialog();

            fetch(`${config.url}/team/skill/${id}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "DELETE",
            })
                .then((res) => {
                    if (res.status === 204) {
                        this.allSkill.splice(key, 1);

                        this.createAlert([
                            {
                                type: "success",
                                title: "Skill supprimé.",
                            },
                        ]);
                    } else {
                        this.createAlert([
                            {
                                type: "error",
                                title: "Une erreur est survenue.",
                            },
                        ]);
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
    beforeMount() {
        fetch(`${config.url}/team/skill`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 403) {
                    this.$router.push("/404");
                } else if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.allSkill = data;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
