<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <h2>Création d'un ticket</h2>

            <TicketForm :submit="'Valider'" :readOnly="false" :admin="false" @formSubmitted="createTicket"/>
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import TicketForm from "@/components/team/ticket/TicketForm";
import {config} from "@/config";

export default {
    name: "TeamAddTicket",
    inject: ["getUser", "createAlert"],
    components: {
        SideBar,
        TicketForm,
    },
    methods: {
        createTicket: function (form) {
            fetch(`${config.url}/team/ticket`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "POST",
                body: JSON.stringify(form),
            })
                .then(async (res) => {
                    if (res.status === 201) {
                        this.$router.push("/team/ticket");

                        this.createAlert([
                            {
                                type: "success",
                                title: "Ticket créé",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
};
</script>
