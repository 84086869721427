<template>
    <div>
        <SideBar :profil="'student'"/>

        <v-container fluid>
            <v-btn class="mt-2" color="#374840" @click="this.$router.go(-1)">Retour</v-btn>

            <h2 class="mt-2">Répondre aux questionnaires de satisfaction</h2>

            <p class="mt-2">Ce questionnaire nous permettra de recueillir des retours d'expérience sur nos formations et
                sera précieux pour nous aider à améliorer notre programme.</p>
            <p>Les réponses à ce questionnaire seront utilisées à des fins d'analyse interne et pour fournir des données
                précieuses aux organismes financeurs.</p>
            <p>Vos réponses resteront confidentielles.</p>

            <v-row class="mt-2">
                <v-col cols="12" md="12" lg="12">
                    <v-card>
                        <v-list
                            class="d-flex flex-column"
                        >
                            <v-list-item>
                                <template v-slot:prepend>
                                    <v-avatar color="red">
                                        <v-icon color="white">mdi-numeric-1</v-icon>
                                    </v-avatar>
                                </template>

                                <v-list-item-content>
                                    <v-list-item-title>Rendez-vous dans l'onglet Formation</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="mt-2">
                                <template v-slot:prepend>
                                    <v-avatar color="red">
                                        <v-icon color="white">mdi-numeric-2</v-icon>
                                    </v-avatar>
                                </template>

                                <v-list-item-content>
                                    <v-list-item-title>Choisissez le parcours de formation</v-list-item-title>
                                    <v-list-item-subtitle>Exemple : Sac & bagage, Petite maroquinerie et
                                        accessoires...
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="mt-2">
                                <template v-slot:prepend>
                                    <v-avatar color="red">
                                        <v-icon color="white">mdi-numeric-3</v-icon>
                                    </v-avatar>
                                </template>

                                <v-list-item-content>
                                    <v-list-item-title>Choisissez la formation que vous souhaitez évaluer
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Exemple : Ceinture enchapée, Savoir faire 1
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <img v-if="$vuetify.display.mobile"
                                 class="mt-2 w-75 align-self-center"
                                 src="../../../assets/tutorials/mobile/quiz-1.png"
                                 alt="tutorial-quiz-1"/>
                            <img v-else
                                 class="mt-2 w-75 align-self-center"
                                 src="../../../assets/tutorials/dekstop/quiz-1.png"
                                 alt="tutorial-quiz-1"/>

                            <v-list-item class="mt-5">
                                <template v-slot:prepend>
                                    <v-avatar color="red">
                                        <v-icon color="white">mdi-numeric-4</v-icon>
                                    </v-avatar>
                                </template>

                                <v-list-item-content>
                                    <v-list-item-title>Sélectionnez le sous-onglet Questionnaire</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="mt-2">
                                <template v-slot:prepend>
                                    <v-avatar color="red">
                                        <v-icon color="white">mdi-numeric-5</v-icon>
                                    </v-avatar>
                                </template>

                                <v-list-item-content>
                                    <v-list-item-title>Naviguez dans les différentes thématiques</v-list-item-title>
                                    <v-list-item-subtitle>Exemple : L'organisation, La formation, Le formateur,
                                        Appréciation générale
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="mt-2">
                                <template v-slot:prepend>
                                    <v-avatar color="red">
                                        <v-icon color="white">mdi-numeric-6</v-icon>
                                    </v-avatar>
                                </template>

                                <v-list-item-content>
                                    <v-list-item-title>Enregistrer votre saisie</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="mt-2">
                                <template v-slot:prepend>
                                    <v-avatar color="red">
                                        <v-icon color="white">mdi-numeric-7</v-icon>
                                    </v-avatar>
                                </template>

                                <v-list-item-content>
                                    <v-list-item-title>Une alerte verte apparaîtra pour vous confirmer l'enregistrement
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Si l'alerte n'apparaît pas, vérifiez les différentes
                                        thématiques, une erreur doit s'y cacher
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <img v-if="$vuetify.display.mobile"
                                 class="mt-2 w-75 align-self-center"
                                 src="../../../assets/tutorials/mobile/quiz-2.png"
                                 alt="tutorial-quiz-2"/>
                            <img v-else
                                 class="mt-2 w-75 align-self-center"
                                 src="../../../assets/tutorials/dekstop/quiz-2.png"
                                 alt="tutorial-quiz-2"/>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar.vue";

export default {
    name: "StudentTutorialQuiz",
    components: {
        SideBar,
    },
};
</script>

<style scoped>
.v-list-item-title {
    white-space: normal !important;
}

.v-list-item-subtitle {
    -webkit-line-clamp: unset !important;
}
</style>
