<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <h2>Mail : {{ mail.name }}</h2>

            <div class="d-flex justify-space-between">
                <div>
                    <v-btn class="mt-5" color="#374840" @click="this.$router.go(-1)">Retour</v-btn>
                    <v-btn class="mt-5" color="white" @click="patchMail">Enregistrer</v-btn>
                </div>

                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn
                            color="#374840"
                            v-bind="props"
                            class="mt-5"
                        >
                            Actions
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item to="/team/shortcode/details" target="_blank">
                            <v-list-item-title>Informations sur les shortcodes
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title @click="openDialog()">Envoyer le mail</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <div class="mt-3">
                Champs variables obligatoires :
                <span
                    v-for="(shortcode, index) in this.shortcodes"
                    :key="index"
                >
                        <span v-if="shortcode.required"
                        >{{ shortcode.name }} ,
                        </span>
                    </span>
            </div>

            <v-card class="mt-5">
                <v-tabs
                    v-model="tab"
                    color="#374840"
                    show-arrows
                >
                    <v-tab value="MAIN">Informations principales</v-tab>
                </v-tabs>

                <v-card-text>
                    <v-window v-model="tab">
                        <v-window-item value="MAIN">
                            <v-form ref="form">
                                <v-text-field
                                    v-model="this.mail.object"
                                    label="Objet"
                                    variant="solo"
                                    maxlength="80"
                                    :rules="config.rules.required"
                                ></v-text-field>

                                <v-checkbox
                                    v-model="this.mail.archive"
                                    :label="`Archiver ce mail`"
                                ></v-checkbox>

                                <v-select
                                    variant="solo"
                                    v-model="selectedDocuments"
                                    :items="documents"
                                    item-title="name"
                                    item-value="id"
                                    label="Associer aux documents"
                                    multiple
                                    chips
                                ></v-select>

                                <TextEditor
                                    ref="textEditorComponentRef"
                                    :defaultContent="mail.content"
                                    :required="true"
                                    :isA4="false"
                                />

                                <Confirm
                                    v-show="confirm.open"
                                    :open="confirm.open"
                                    :text="confirm.text"
                                    @confirmDialog="sendMail()"
                                    @closeDialog="closeDialog()"
                                />
                            </v-form>
                        </v-window-item>
                    </v-window>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import TextEditor from "@/components/TextEditor.vue";
import Confirm from "@/components/Confirm";
import SideBar from "@/components/SideBar.vue";
import {config} from "@/config";

export default {
    name: "TeamMailEdit",
    inject: ["getUser", "createAlert"],
    components: {
        Confirm,
        TextEditor,
        SideBar,
    },
    data() {
        return {
            tab: null,
            confirm: {
                open: false,
                text: "",
            },
            config,
            id: null,
            mail: [],
            shortcodes: [],
            selectedDocuments: [],
            documents: [],
        };
    },
    methods: {
        openDialog() {
            this.confirm.text = "Assurez vous d'avoir sauvegardé le mail avant de l'envoyer";
            this.confirm.open = true;
        },
        closeDialog() {
            this.confirm.open = false;
            this.confirm.text = "";
        },
        sendMail: async function () {
            if ((await this.$refs.form.validate()).valid) {
                fetch(
                    `${config.url}/team/mail/${this.mail.name}/send-mail-test`,
                    {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + this.getUser().token,
                        },
                        method: "GET",
                    }
                )
                    .then(async (res) => {
                        if (res.status === 201) {
                            this.closeDialog();
                            this.createAlert([
                                {
                                    type: "success",
                                    title: "Le mail a été envoyé à votre adresse mail.",
                                },
                            ]);
                        } else {
                            const errors = await res.json();
                            if (errors && errors.errors) {
                                this.createAlert(
                                    errors.errors.map((error) => {
                                        return {
                                            type: "error",
                                            title: error.msg,
                                        };
                                    })
                                );
                            }
                        }
                    })
                    .catch(() => {
                        this.createAlert([
                            {
                                type: "error",
                                title: "Une erreur est survenue.",
                            },
                        ]);
                    });
            }
        },

        patchMail: async function () {
            if ((await this.$refs.form.validate()).valid) {
                const childComponent = this.$refs.textEditorComponentRef;
                this.mail.content = childComponent.editorContent;

                fetch(`${config.url}/team/mail/${this.id}`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + this.getUser().token,
                    },
                    method: "PATCH",
                    body: JSON.stringify({
                        id: this.mail.id,
                        object: this.mail.object,
                        content: this.mail.content,
                        selectedDocuments: this.selectedDocuments,
                        archive: this.mail.archive,
                    }),
                })
                    .then(async (res) => {
                        if (res.status === 201) {
                            this.createAlert([
                                {
                                    type: "success",
                                    title: "Le mail a été mis à jour",
                                },
                            ]);
                        } else {
                            const errors = await res.json();
                            if (errors && errors.errors) {
                                this.createAlert(
                                    errors.errors.map((error) => {
                                        return {
                                            type: "error",
                                            title: error.msg,
                                        };
                                    })
                                );
                            }
                        }
                    })
                    .catch(() => {
                        this.createAlert([
                            {
                                type: "error",
                                title: "Une erreur est survenue.",
                            },
                        ]);
                    });
            }
        },
    },
    beforeMount() {
        this.id = this.$route.params.id;

        fetch(`${config.url}/team/mail/${this.id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 404 || res.status === 403) {
                    this.$router.push("/404");
                } else if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.mail = data.mail;
                    this.shortcodes = data.shortcodes;
                    this.selectedDocuments = data.selectedDoc;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });

        fetch(`${config.url}/team/docs`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
            })
            .then((data) => {
                if (data) {
                    this.documents = data;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
