export const config = {
    url: "https://intranet.ateliersdargent.com:3000",
    tinyMCE_api_key: "9s7bb5gzs4o95vlvmekvp2acp19hf9p0zfcp4441fkft2tiw",
    rules: {
        required: [
            (value) => {
                if (
                    (value !== 0 && !value) ||
                    value === null ||
                    value === undefined ||
                    (Array.isArray(value) && value.length === 0)
                ) {
                    return "Le champ est obligatoire";
                }
                return true;
            },
        ],
        numberGreaterThanOne: [
            (value) => {
                if (value < 1) {
                    return "Note minimum : 1";
                }
                return true;
            },
        ],
        isANumber: [
            (value) => {
                const numberRegex = /^-?\d+(\.\d{1,})?(,\d{1,})?$/;

                if (!value || !numberRegex.test(value)) {
                    return "Veuillez saisir un nombre valide";
                }

                return true;
            },
        ],
        isAEmail: [
            (value) => {
                const emailRegex = /.+@.+\..+/;
                if (!value || !emailRegex.test(value)) {
                    return "Veuillez saisir une adresse e-mail valide";
                }
                return true;
            },
        ],
        isAPhoneNumber: [
            (value) => {
                const phoneNumberRegex = /^(?:(?:(?:\+|00)33\s?|0)[1-9]\d{8})$/;
                if (!value || !phoneNumberRegex.test(value)) {
                    return "Veuillez saisir un numéro de téléphone français valide";
                }
                return true;
            },
        ],
        limitSizeTo256: [
            (value) => {
                if (value.length < 256) return true;
                return "Le texte ne doit pas dépasser 256 caractères.";
            },
        ],
        fileSize: [
            (value) => {
                if (!value || !value.length || value[0].size < 700000)
                    return true;
                return "La taille du fichier ne doit pas dépasser 7 Kb.";
            },
        ],
        totalFileSize: [
            (value) => {
                if (
                    !value ||
                    !value.length ||
                    value.reduce(
                        (acc, file) =>
                            acc + (file && file.size ? file.size : 0),
                        0
                    ) <= 2000000
                ) {
                    return true;
                }
                return "La taille totale des fichiers ne doit pas dépasser 2 MB.";
            },
        ],
        fileCount: [
            (value) => {
                return (
                    (value && value.length <= 3) ||
                    "Vous ne pouvez pas télécharger plus de 3 fichiers."
                );
            },
        ],
    },
};
