<template>
    <div>
        <SideBar :profil="getProfil"/>

        <v-container fluid>
            <h2>Informations personnelles</h2>

            <UserForm
                :submit="'Enregistrer'"
                :isProfile="true"
                :defaultCivility="civility"
                :defaultFirstname="firstname"
                :defaultLastname="lastname"
                :defaultEmail="email"
                :defaultBirthday="birthday"
                :defaultProfilPicture="profilPicture"
                :defaultPhoneNumber="phoneNumber"
                :defaultHand="hand"
                :defaultActivity="activity"
                :defaultJob="job"
                :defaultImageAuthorization="!!imageAuthorization"
                :defaultHandAuthorization="!!handAuthorization"
                :defaultAddressStreetNumber="address.streetNumber"
                :defaultAddressStreetName="address.streetName"
                :defaultAddressPostalCode="address.postalCode"
                :defaultAddressCity="address.city"
                :defaultAddressCountry="address.country"
                @formSubmitted="updateProfile"
            />
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import UserForm from "@/components/team/user/UserForm";
import {config} from "@/config";

export default {
    name: "UserProfile",
    inject: ["getUser", "createAlert", "updateUser"],
    components: {
        SideBar,
        UserForm,
    },
    data: () => ({
        civility: "",
        firstname: "",
        lastname: "",
        email: "",
        birthday: "",
        profilPicture: "",
        phoneNumber: "",
        hand: "",
        activity: "",
        job: "",
        imageAuthorization: "",
        handAuthorization: "",
        address: {
            streetNumber: "",
            streetName: "",
            postalCode: "",
            city: "",
            country: "",
        },
    }),
    beforeMount() {
        fetch(`${config.url}/user/profile/me`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
            })
            .then((data) => {
                if (data) {
                    this.civility = data.civility;
                    this.firstname = data.firstname;
                    this.lastname = data.lastname;
                    this.email = data.email;
                    this.birthday = data.birthday;
                    this.profilPicture = data.profilPicture;
                    this.phoneNumber = data.phoneNumber;
                    this.hand = data.hand;
                    this.activity = data.activity;
                    this.job = data.job;
                    this.imageAuthorization = data.imageAuthorization;
                    this.handAuthorization = data.handAuthorization;
                    this.address = data.address;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
    methods: {
        updateProfile: function (form) {
            fetch(`${config.url}/user/profile/me`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "PATCH",
                body: JSON.stringify(form),
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        this.updateUser(
                            `${form.firstname} ${form.lastname}`,
                            this.profilPicture
                        );
                        this.createAlert([
                            {
                                type: "success",
                                title: "Profil mis à jour",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
    computed: {
        getProfil() {
            if (this.getUser().profile === 'Apprenant') {
                return "student";
            } else if (this.getUser().profile === 'Administrateur') {
                return "admin";
            }

            return "team";
        }
    },
};
</script>
