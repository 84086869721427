<template>
    <v-container fluid>
        <v-row class="mt-5">
            <v-col cols="12" md="12" lg="5" class="d-flex flex-column justify-center desc">
                <h2 class="mb-5">Les Ateliers Dargent</h2>

                <p class="mb-3">
                    Nous vous accueillons au sein de nos Ateliers Parisiens et Lyonnais pour réaliser vos projets en
                    cuir.
                </p>
                <p class="mb-3">Suivez votre parcours de formation sur notre site.</p>
                <p class="mb-5">
                    Pour tout renseignement rendez-vous à l'adresse suivante :
                    <a
                        style="color:#374840"
                        href="https://www.ateliersdargent.com/"
                        target="_blank"
                    >
                        https://www.ateliersdargent.com/
                    </a>
                </p>

                <v-btn v-if="!getUser()" class="green-btn w-50 mt-5" to="/auth/login">Se connecter</v-btn>
            </v-col>
            <v-col cols="12" md="12" lg="7">
                <v-row>
                    <v-col cols="12" md="12" lg="6" class="img--left">
                        <img src="@/assets/image1.jpg" alt="Logo">
                    </v-col>
                    <v-col cols="12" md="12" lg="6" class="img--right">
                        <img src="@/assets/image2.jpg" alt="Logo">
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "HomePage",
    inject: ["getUser"],
};
</script>

<style scoped>
img {
    width: 75%;
}

h2 {
    font-size: 30px;
}

p {
    font-size: 20px;
    text-align: left;
}

.img--left {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.img--right {
    display: flex;
    align-items: flex-end;
    margin-top: 150px;
}

.desc {
    padding-left: 50px;
}

@media (max-width: 768px) {
    .img--left {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .img--right {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0;
    }

    img {
        width: 100%;
    }

    .desc {
        padding-left: 0;
        align-items: center;
    }

    p {
        padding-left: 10px;
    }
}
</style>
