<template>
    <div>
        <SideBar :profil="'student'"/>

        <v-container fluid>
            <h2>Planning</h2>
            <Calendar :defaultEvents="events" :type="profil" @selectedDate="setSelectedDate"/>
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import Calendar from "@/components/Calendar";

import {config} from "@/config";

export default {
    name: "StudentPlanning",
    inject: ["getUser", "createAlert"],
    data() {
        return {
            events: [],
            profil: 'student',
        };
    },
    components: {
        SideBar,
        Calendar,
    },
    methods: {
        setSelectedDate(selectedDate) {
            this.selectedDate = selectedDate;
            this.getEvents(`${config.url}/student/planning?selectedDate=${this.selectedDate ? this.selectedDate : ''}`);
        },
        getEvents(search) {
            fetch(search, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "GET",
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }
                })
                .then((data) => {
                    if (data) {
                        this.events = data;
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        }
    },
    beforeMount() {
        this.getEvents(`${config.url}/student/planning`);
    },
};
</script>
