<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <h2>Modification d'un document</h2>

            <v-menu>
                <template v-slot:activator="{ props }">
                    <v-btn
                        color="#374840"
                        v-bind="props"
                        class="mt-5"
                    >
                        Actions
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item to="/team/shortcode/details" target="_blank">
                        <v-list-item-title>Informations sur les shortcodes
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title @click="openDialog()">Télécharger le document</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <DocumentForm
                :submit="'Enregistrer'"
                :defaultName="name"
                :defaultContent="content"
                :defaultSelectedMail="selectedMail"
                :defaultExistingFile="existingFile"
                @formSubmitted="updateDocument"
            />

            <Confirm
                v-show="confirm.open"
                :open="confirm.open"
                :text="confirm.text"
                @confirmDialog="downloadDocument()"
                @closeDialog="closeDialog()"
            />
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar.vue";
import Confirm from "@/components/Confirm";
import DocumentForm from "@/components/team/DocumentForm";
import {config} from "@/config";

export default {
    name: "TeamDocEdit",
    inject: ["getUser", "createAlert"],
    components: {
        SideBar,
        Confirm,
        DocumentForm,
    },
    data() {
        return {
            confirm: {
                open: false,
                text: "",
            },
            id: "",
            name: "",
            content: "",
            selectedMail: [],
            existingFile: false,
        };
    },
    methods: {
        openDialog() {
            this.confirm.text =
                "Assurez vous d'avoir sauvegardé le document avant de le télécharger";
            this.confirm.open = true;
        },
        closeDialog() {
            this.confirm.open = false;
            this.confirm.text = "";
        },
        downloadDocument: function () {
            this.closeDialog();

            fetch(`${config.url}/team/docs/${this.id}/download-document`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "GET",
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        const pdfBlob = await res.blob();
                        const pdfUrl = URL.createObjectURL(pdfBlob);

                        const downloadLink = document.createElement("a");
                        downloadLink.href = pdfUrl;
                        downloadLink.download = "document.pdf";
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);

                        this.createAlert([
                            {
                                type: "success",
                                title: "Le document a été téléchargé.",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
        updateDocument: function (form) {
            let body = {
                name: form.name,
                selectedMail: form.selectedMails,
                existingFile: form.existingFile,
            };

            if (form.file) {
                const fileReader = new FileReader();

                fileReader.onload = (event) => {
                    const fileBuffer = event.target.result;
                    body.file = Array.from(new Uint8Array(fileBuffer));

                    this.sendFetchRequest(body);
                };

                fileReader.readAsArrayBuffer(form.file[0]);
            } else {
                body.content = form.content;
                this.sendFetchRequest(body);
            }
        },
        sendFetchRequest: function (body) {
            fetch(`${config.url}/team/docs/${this.id}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "PATCH",
                body: JSON.stringify(body),
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        this.createAlert([
                            {
                                type: "success",
                                title: "Le document est mis à jour",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        }
    },
    beforeMount() {
        this.id = this.$route.params.id;

        fetch(`${config.url}/team/docs/${this.id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 404 || res.status === 403) {
                    this.$router.push("/404");
                } else if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.id = data.id;
                    this.name = data.name;
                    this.content = data.content;
                    this.selectedMail = data.selectedMail;
                    this.existingFile = data.existingFile;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
