<template>
    <v-form @submit.prevent="submitForm" ref="form">
        <v-btn class="mt-5" color="#374840" @click="this.$router.go(-1)">Retour</v-btn>
        <v-btn class="mt-5" type="submit">{{ submit }}</v-btn>

        <v-card class="mt-5">
            <v-tabs
                v-model="tab"
                color="#374840"
                show-arrows
            >
                <v-tab value="MAIN">Informations principales</v-tab>
            </v-tabs>

            <v-card-text>
                <v-window v-model="tab">
                    <v-window-item value="MAIN">
                        <v-text-field
                            variant="solo"
                            label="Nom du type d'événement"
                            v-model="name"
                            :rules="config.rules.required"
                        ></v-text-field>

                        <v-text-field
                            v-model="backgroundColor"
                            variant="solo"
                            label="Couleur"
                            type="color"
                            :rules="config.rules.required"
                        ></v-text-field>

                        <v-checkbox
                            variant="solo"
                            label="Lien avec les formations"
                            v-model="isFormation"
                        ></v-checkbox>
                    </v-window-item>
                </v-window>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
import {config} from "@/config";

export default {
    name: "EventTypeForm",
    inject: ["getUser", "createAlert"],
    props: {
        submit: String,
        defaultName: String,
        defaultBackgroundColor: String,
        defaultIsFormation: Boolean,
    },
    data() {
        return {
            config,
            name: "",
            backgroundColor: "",
            isFormation: "",
            tab: null,
        };
    },
    watch: {
        defaultName: function (newVal) {
            this.name = newVal ? newVal : "";
        },
        defaultBackgroundColor: function (newVal) {
            this.backgroundColor = newVal ? newVal : "";
        },
        defaultIsFormation: function (newVal) {
            this.isFormation = newVal ? newVal : false;
        },
    },
    methods: {
        async submitForm() {
            if ((await this.$refs.form.validate()).valid) {
                this.$emit("formSubmitted", {
                    name: this.name,
                    backgroundColor: this.backgroundColor,
                    isFormation: this.isFormation,
                });
            }
        },
    },
};
</script>
