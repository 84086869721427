<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <h2>Modification d'une compétence</h2>

            <SkillForm
                :submit="'Enregistrer'"
                :defaultRef="ref"
                :defaultName="name"
                :defaultSelectedFormations="selectedFormations"
                :defaultFinalFormations="finalFormations"
                @formSubmitted="updateSkill"
            />
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import SkillForm from "@/components/team/training/SkillForm";
import {config} from "@/config";

export default {
    name: "TeamSkillEdit",
    inject: ["getUser", "createAlert"],
    components: {
        SideBar,
        SkillForm,
    },
    data() {
        return {
            id: "",
            ref: "",
            name: "",
            selectedFormations: [],
            finalFormations: [],
        };
    },
    methods: {
        updateSkill: function (form) {
            fetch(`${config.url}/team/skill/${this.id}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "PATCH",
                body: JSON.stringify({
                    ref: form.ref,
                    name: form.name,
                    formations: form.formations,
                }),
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        this.createAlert([
                            {
                                type: "success",
                                title: "Skill mis à jour",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
    beforeMount() {
        this.id = this.$route.params.id;

        fetch(`${config.url}/team/skill/${this.id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 404 || res.status === 403) {
                    this.$router.push("/404");
                } else if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.ref = data.ref;
                    this.name = data.name;
                    this.selectedFormations = data.formations;
                    this.finalFormations = data.finalFormations;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
