<template>
    <div>
        <SideBar :profil="'team'"/>

        <v-container fluid>
            <h2>Note</h2>

            <div class="d-flex justify-space-between">
                <div>
                    <v-btn
                        color="#374840"
                        @click="this.$router.go(-1)"
                        class="mt-5"
                    >Retour
                    </v-btn
                    >
                    <v-btn color="#374840" class="mt-5" @click="submit"
                    >Valider
                    </v-btn
                    >
                </div>

                <v-btn color="#374840" class="mt-5" @click="setMaxScore"
                >Note maximum
                </v-btn
                >
            </div>

            <div v-if="scores">
                <v-row
                    class="mt-5 justify-center"
                    v-for="(skill, key) in scores.skills"
                    :key="key"
                >
                    <v-col cols="12" md="12" lg="4">
                        <span>{{ skill.name }}</span>
                    </v-col>

                    <v-col cols="12" md="12" lg="4">
                        <v-slider
                            v-model="scores.score[key]"
                            :min="0"
                            :max="5"
                            :step="1"
                            thumb-label
                            :ticks="[0, 1, 2, 3, 4, 5]"
                            show-ticks="always"
                            tick-size="4"
                        ></v-slider>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import {config} from "@/config";

export default {
    name: "TeamUserScoreStudent",
    inject: ["getUser", "createAlert"],
    components: {
        SideBar,
    },
    data() {
        return {
            userId: "",
            formationId: "",
            scoreName: "",
            scores: null,
        };
    },
    methods: {
        setMaxScore() {
            this.scores.score = Array(this.scores.score.length).fill(5);
        },
        submit() {
            fetch(
                `${config.url}/team/score/user/${this.userId}/training/formation/${this.formationId}/student/${this.scoreName}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + this.getUser().token,
                    },
                    method: "PATCH",
                    body: JSON.stringify(this.scores),
                }
            )
                .then(async (res) => {
                    if (res.status === 200) {
                        this.$router.push(
                            `/team/users/${this.userId}/training/formation/${this.formationId}`
                        );

                        this.createAlert([
                            {
                                type: "success",
                                title: "Note mise à jour",
                            },
                        ]);
                    } else {
                        const errors = await res.json();
                        if (errors && errors.errors) {
                            this.createAlert(
                                errors.errors.map((error) => {
                                    return {
                                        type: "error",
                                        title: error.msg,
                                    };
                                })
                            );
                        }
                    }
                })
                .catch(() => {
                    this.createAlert([
                        {
                            type: "error",
                            title: "Une erreur est survenue.",
                        },
                    ]);
                });
        },
    },
    beforeMount() {
        this.userId = this.$route.params.userId;
        this.formationId = this.$route.params.formationId;
        this.scoreName = this.$route.params.scoreName;

        fetch(
            `${config.url}/team/score/user/${this.userId}/training/formation/${this.formationId}/student/${this.scoreName}`,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getUser().token,
                },
                method: "GET",
            }
        )
            .then(async (res) => {
                if (res.status === 404 || res.status === 403) {
                    this.$router.push("/404");
                } else if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.scores = data;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
