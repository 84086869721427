<template>
    <v-form @submit.prevent="submitForm" ref="form">
        <v-btn class="mt-5" color="#374840" @click="this.$router.go(-1)">Retour</v-btn>
        <v-btn class="mt-5" color="white" type="submit">{{ submit }}</v-btn>

        <v-card class="mt-5">
            <v-tabs
                v-model="tab"
                color="#374840"
                show-arrows
            >
                <v-tab value="MAIN">Informations principales</v-tab>
            </v-tabs>

            <v-card-text>
                <v-window v-model="tab">
                    <v-window-item value="MAIN">
                        <v-text-field
                            variant="solo"
                            label="Nom du document"
                            v-model="name"
                            :rules="config.rules.required"
                        ></v-text-field>

                        <v-select
                            variant="solo"
                            v-model="selectedMails"
                            :items="mails"
                            item-title="name"
                            item-value="id"
                            label="Associer aux mails"
                            multiple
                            chips
                        ></v-select>

                        <v-switch
                            v-model="existingFile"
                            label="Document existant (shortcodes incompatibles)"
                        ></v-switch>

                        <v-file-input
                            v-if="existingFile"
                            variant="solo"
                            label="Déposer un pdf"
                            v-model="file"
                            accept=".pdf"
                        ></v-file-input>

                        <TextEditor
                            v-else
                            align="center"
                            justify="center"
                            ref="textEditorComponentRef"
                            :defaultContent="content"
                            :isA4="true"
                        />
                    </v-window-item>
                </v-window>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
import TextEditor from "@/components/TextEditor.vue";
import {config} from "@/config";

export default {
    name: "DocumentForm",
    inject: ["getUser", "createAlert"],
    components: {
        TextEditor,
    },
    props: {
        submit: String,
        defaultName: String,
        defaultContent: String,
        defaultSelectedMail: Array,
        defaultExistingFile: Boolean,
    },
    data() {
        return {
            config,
            existingFile: false,
            name: "",
            selectedMails: [],
            mails: [],
            content: "",
            file: null,
            tab: null,
        };
    },
    watch: {
        defaultName: function (newVal) {
            this.name = newVal ? newVal : "";
        },
        defaultContent: function (newVal) {
            this.content = newVal ? newVal : "";
        },
        defaultExistingFile: function (newVal) {
            this.existingFile = newVal ? newVal : false;
        },
        defaultSelectedMail: function (newVal) {
            this.selectedMails = Array.isArray(newVal) ? newVal : [];
        },
    },
    methods: {
        async submitForm() {
            if ((await this.$refs.form.validate()).valid) {
                if (this.existingFile) {
                    this.content = "";
                } else {
                    this.file = null;
                    const childComponent = this.$refs.textEditorComponentRef;
                    this.content = childComponent.editorContent;
                }

                this.$emit("formSubmitted", {
                    name: this.name,
                    selectedMails: this.selectedMails,
                    content: this.content,
                    file: this.file,
                    existingFile: this.existingFile,
                });
            }
        },
    },
    beforeMount() {
        fetch(`${config.url}/team/mail`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getUser().token,
            },
            method: "GET",
        })
            .then(async (res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    const errors = await res.json();
                    if (errors && errors.errors) {
                        this.createAlert(
                            errors.errors.map((error) => {
                                return {
                                    type: "error",
                                    title: error.msg,
                                };
                            })
                        );
                    }
                }
            })
            .then((data) => {
                if (data) {
                    this.mails = data;
                }
            })
            .catch(() => {
                this.createAlert([
                    {
                        type: "error",
                        title: "Une erreur est survenue.",
                    },
                ]);
            });
    },
};
</script>
